import React from 'react'
import {useStores} from '../../hooks/useStores';
import {ICallToActionTabella} from './types';

const CallToActionRiga: React.FC<ICallToActionTabella> = ({
    icona,
    testo,
    azione,
    id,
    dati,
    coloreSfondo,
    coloreTesto,
    useCustomStyle
}) => {

    const {ui} = useStores()

    return (
        <div
            id={id}
            className={`callToActionRiga
            ${coloreSfondo ? coloreSfondo : "bg-accent dark:bg-dark-accent"}
            ${coloreTesto ? coloreTesto : "text-white"}
            `}
            // style aggiunto per rappresentare i bottoni dello status del caricamento massivo
            style={useCustomStyle
                ?
                {
                    padding: 8,
                    borderRadius: 4,
                    width: 28,
                    top: 10,
                    left: 10,
                    position: "absolute"
                }
                :
                {}
            }
            onClick={() => azione && azione(dati)}
            onMouseEnter={() => ui.mountFollowMouse(<>{testo}</>)}
            onMouseLeave={() => ui.unmountFollowMouse()}
        >
            {icona && <span>{icona}</span>}
        </div>
    )
}

export default CallToActionRiga;
