import React, {useEffect, useState} from 'react'

//Data
import {CgAdd} from 'react-icons/cg'
import {FaPen, FaTrash} from 'react-icons/fa'
import {useStores} from '../../../../../../hooks/useStores'
import {elimina, get} from '../../../../../../rest/crud_generica'
import {IFattoreUpstreamTransport} from '../../../../types'

//Components
import Dialogue from '../../../../../../components/common/Dialogue'
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'

import {observer} from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'
import {ICallToActionTabella} from '../../../../../../components/tabellaInterattiva/types'
import {useTabellaInterattivaContext} from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'

import {t} from 'i18next'
import UpstreamTransportForm from './UpstreamTransportForm'

const UpstreamTransport = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()

    const {ui} = useStores()

    const [upstreamTransport, setUpstreamTransport] = useState<IFattoreUpstreamTransport[]>([])

    useEffect(() => {
        listaUpstreamTransport()
    }, [ui.aggiornamentoGenerico])

    const listaUpstreamTransport = () => get('carbon_footprint/upstream_transport').then((res) => {
        setUpstreamTransport(res.data.data)
    })

    //Crea fattore emissivo
    const creaUpstreamTransport = () => ui.mountAsideModal(<UpstreamTransportForm/>)

    //Modifica fattore emissivo
    const modificaUpstreamTransport = (upstreamtransport: IFattoreUpstreamTransport) => {
        ui.mountAsideModal(<UpstreamTransportForm upstreamtransport={upstreamtransport}/>)
    }

    //Elimina fattore emissivo
    const eliminaUpstreamTransport = (upstreamtransport: IFattoreUpstreamTransport) => {
        elimina(`carbon_footprint/upstream_transport/${upstreamtransport.id}`)
            .then(() => {
                    ui.mountDialogue(<Dialogue titolo="Upstream Transport eliminato con successo"/>)
                    listaUpstreamTransport()
            })
            .catch(() => {
                ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione del fattore emissivo"/>)
            })
    }

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const {tabella} = useTabellaInterattivaContext()
    useEffect(() => {}, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    testo: 'Crea fattore',
                    azione: creaUpstreamTransport,
                    icona: <CgAdd size={18}/>
                }])
            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaUpstreamTransport,
                    testo: 'Modifica',
                    icona: <FaPen size={12}/>,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaUpstreamTransport,
                    testo: 'Elimina',
                    icona: <FaTrash size={12}/>,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={upstreamTransport}
                titolo="Fattori di Upstream Transport" // trasporto di superficie
                paragrafo="Inserisci i fattori di Upstream Transport."
                callToActions={ctaTabella}
                colonne={
                    [
                        {id: 'anno', label: t('common.anno')},
                        {id: 'tipo_trasporto', label: t('common.transportMethod')},
                        {id: 'unita_misura', label: t('common.unitaMisura')},
                        {id: 'kg_co2e', label: 'Kg co2e'},
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default UpstreamTransport;