import React from 'react'

//Data
import {IRigaTabellaInterattiva} from './types';
import {useTabellaInterattivaContext} from './context/useTabellaInterattivaContext';
import {observer} from 'mobx-react-lite';

//Components
import CallToActionRiga from './CallToActionRiga';
import CellaTabella from './CellaTabella';

const RigaTabella: React.FC<IRigaTabellaInterattiva> = observer(({dati, rowId}) => {

    const {tabella} = useTabellaInterattivaContext()

    return (
        <tr className="rigaTabellaInterattiva">
            {tabella.colonne?.map((colonna: any, i: number) => {
                let valore: any = dati[colonna.id];
                let tipo = typeof dati[colonna.id];

                return (
                    <CellaTabella
                        dati={dati}
                        colonna={Object.keys(dati)[i]}
                        key={rowId as string + '_' + i.toString()}
                        valoreDefault={valore}
                    />
                )

            })}

            {tabella.callToStatusRiga && tabella.callToStatusRiga.length > 0 &&
                <td>
                    {
                        tabella.callToStatusRiga?.map((cts) => (

                            Object.keys(dati).includes('progress_status') && dati['progress_status'].includes(cts.id)
                                    ?
                                    <CallToActionRiga
                                        icona={cts.icona}
                                        testo={cts.testo + "\nNumero di righe errate: " + dati['progress_error_count']}
                                        id={cts.id}
                                        azione={cts.azione}
                                        dati={dati}
                                        coloreSfondo={cts.coloreSfondo}
                                        coloreTesto={cts.coloreTesto}
                                        useCustomStyle={true} // i bottoni dello status non possono essere rappresentati insieme o usare le stesse classi delle call to actions
                                    />
                                    :
                                    null
                        ))
                    }
                </td>
            }

            {tabella.callToActionsRiga && tabella.callToActionsRiga.length > 0 &&
                <td className="cellaAzioniRiga">
                    {
                        tabella.callToActionsRiga?.map((cta) => (

                            Object.keys(dati).includes('call_to_action_allowed') && dati['call_to_action_allowed'].includes(cta.id)
                                    ?
                                    <CallToActionRiga
                                        icona={cta.icona}
                                        testo={cta.testo}
                                        id={cta.id}
                                        azione={cta.azione}
                                        dati={dati}
                                        coloreSfondo={cta.coloreSfondo}
                                        coloreTesto={cta.coloreTesto}
                                    />
                                    : null
                        ))
                    }
                </td>
            }

        </tr>
    )
})

export default RigaTabella;
