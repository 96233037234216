import './App.css';

//Data
import { observer } from 'mobx-react-lite';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CambioPagina from './hooks/CambioPagina';
import { useStores } from './hooks/useStores';

//Hooks
import useIntercettaUtenteNonAutenticato from './hooks/useIntercettaUtenteNonAutenticato';
import useSettaAxios from './hooks/useSettaAxios';
import useSettaDebug from './hooks/useSettaDebug';
import useSettaLingua from './hooks/useSettaLingua';
import useSettaTema from './hooks/useSettaTema';
import useSettaUtente from './hooks/useSettaUtente';
import useUiResize from './hooks/useUiResize';

//Templates
import { Logged } from './pages/templates/Logged';
import { Unlogged } from './pages/templates/Unlogged';

//Autenticazione
import CompletaAccount from './pages/CompletaAccount';
import ImpostaNuovaPassword from './pages/ImpostaNuovaPassword';
import Login from './pages/Login';
import Notizia from './pages/Notizia';
import RichiediResetPassword from './pages/RichiediResetPassword';
import Signup from './pages/Signup';


//Misc
import DebugModal from './components/common/DebugModal';
import FollowMouse from './components/common/followMouse/FollowMouse';
import FollowMouseModal from './components/common/followMouse/FollowMouseModal';
import AzureAutenticazione from './pages/AzureAutenticazione';
import Contatti from './pages/Contatti';
import Pagina404 from './pages/Pagina404';
import TerminiCondizioni from './pages/TerminiCondizioni';

//App
import Attivita from './pages/Attivita';
import Profilo from './pages/Profilo';


//Moduli
import Modulo from './pages/Modulo';

import ArchivioAttivitaPanello from './moduli/amministrazioneUtentiAccessi/attivita/ArchivioAttivitaPanello';

import Dashboard from './moduli/UiModuloDashboard/Dashboard';
import ListaModuli from './moduli/UiModuloDashboard/ListaModuli';
import ArchivioNotizie from './moduli/notizie/ArchivioNotizie';
import NotiziaForm from './moduli/notizie/NotiziaForm';

import ArchivioBusinessUnits from './moduli/UiModuloBusinessUnit/ArchivioBusinessUnits';
import ArchivioBusinessUnitCategorie from './moduli/UiModuloBusinessUnit/UiModuloBusinessUnitCategorie/ArchivioBusinessUnitCategorie';
import ArchivioBusinessUnitRuoli from './moduli/UiModuloBusinessUnit/UiModuloBusinessUnitRuoli/ArchivioBusinessUnitRuoli';
import SchedaBusinessUnit from './moduli/UiModuloBusinessUnit/components/SchedaBusinessUnit';

import GestioneLingue from './moduli/gestioneLingue/GestioneLingue';
import GestioneModuli from './moduli/gestioneModuli/GestioneModuli';
import GestionePrivilegi from './moduli/gestionePrivilegi/GestionePrivilegi';
import GestioneUnitaMisura from './moduli/gestioneUnitaMisura/GestioneUnitaMisura';

//Modulo fattori ESG
import ArchivioStandardESG from './moduli/UiModuloFattoriESG/pagineFattoriESG/ArchivioStandardESG';
import SchedaFattoreESG from './moduli/UiModuloFattoriESG/pagineFattoriESG/SchedaFattoreESG';

//Modulo bilanci
import ArchivioBilanci from './moduli/UiModuloFattoriESG/pagineBilanci/ArchivioBilanci';

//Sezioni bilancio
import SchedaBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/SchedaBilancio';
import SchedaBilancioBusinessUnits from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioBusinessUnits';
import SchedaBilancioDashboard from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioDashboard';
import SchedaBilancioFattoriESG from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioFattoriESG';
import SchedaBilancioGruppi from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioGruppi';
import SchedaBilancioObiettiviAgenda2030 from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioObiettiviAgenda2030';
import SchedaBilancioTemi from './moduli/UiModuloFattoriESG/pagineBilanci/SezioniSchedaBilancio/SchedaBilancioTemi';

//Schede Dettagli items nel bilancio
import DettagliBusinessUnitNelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliBusinessUnitNelBilancio';
import DettagliFattoreESGNelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliFattoreESGNelBilancio';
import DettagliGruppoNelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliGruppoNelBilancio';
import DettagliObiettivoAgenda2030NelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliObiettivoAgenda2030NelBilancio';
import DettagliTemaMaterialitaNelBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/DettagliTemaMaterialitaNelBilancio';

//Archivi bilanci
import ArchivioBilanciFattori from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloBilanciFattori/ArchivioBilanciFattori';
import ArchivioBilanciFattoriBusinessUnits from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloBilanciFattoriBusinessUnits/ArchivioBilanciFattoriBusinessUnits';
import ArchivioRendicontazioneBilanci from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloRendicontazioneBilanci/pagine/ArchivioRendicontazioneBilancio';

//Rendicontazione
import ArchivioBilanciTemiMaterialita from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloBilanciTemiMaterialita/ArchivioBilanciTemiMaterialita';
import SchedaRendicontazioneBilancio from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloRendicontazioneBilanci/pagine/SchedaRendicontazioneBilancio';
import SchedaRendicontazioneBilancioBusinessUnit from './moduli/UiModuloFattoriESG/pagineBilanci/UiModuloRendicontazioneBilanci/pagine/SchedaRendicontazioneBilancioBusinessUnit';
import SchedaBilancioTemaMaterialita from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloTemaMaterialita/SchedaBilancioTemaMaterialita';

//Modulo temi di materialità
import ArchivioMaterialita from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloTemaMaterialita/ArchivioMaterialita';
import SchedaTemaMaterialita from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloTemaMaterialita/SchedaTemaMaterialita';

//Modulo agenda 2030
import Toast from './components/common/Toast';
import ArchivioObiettiviAgenda2030 from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloAgenda2030/ArchivioObiettiviAgenda2030';
import SchedaObiettivoAgenda2030 from './moduli/UiModuloFattoriESG/pagineFattoriESG/UiModuloAgenda2030/SchedaObiettivoAgenda2030';

//Modulo Carbon Footprint
import CarbonFootprint from './moduli/UiModuloCarbonFootprint/CarbonFootprint';

//Configurazione
import ConfigurazioneCarbonFootprint from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/ConfigurazioneCarbonFootprint';

import ConfigurazioneScope1 from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope1/ConfigurazioneScope1';
import ConfigurazioneCarburanti from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope1/configurazioneCarburanti/ConfigurazioneCarburanti';
import ConfigurazioneGasRefrigeranti from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope1/configurazioneGasRefrigeranti/ConfigurazioneGasRefrigeranti';
import ConfigurazioneVeicoli from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope1/configurazioneVeicoli/ConfigurazioneVeicoli';

import ConfigurazioneScope2 from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope2/ConfigurazioneScope2';
import ConfigurazioneElettricita from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope2/configurazioneElettricita/ConfigurazioneElettricita';

import ConfigurazioneScope3 from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope3/ConfigurazioneScope3';
import ConfigurazioneUpstreamTransport from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope3/configurazioneUpstreamTransport/ConfigurazioneUpstreamTransport';
import ConfigurazioneBusinessTravel from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope3/configurazioneBusinessTravel/ConfigurazioneBusinessTravel';
import ConfigurazioneUseOfSoldProducts from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope3/configurazioneUseOfSoldProducts/ConfigurazioneUseOfSoldProducts';
import ConfigurazioneWaste from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope3/configurazzioneWaste/ConfigurazioneWaste';


//Rendicontazione
import RendicontazioneCarbonFootprint from './moduli/UiModuloCarbonFootprint/rendicontazioneCarbonFootprint/RendicontazioneCarbonFootprint';

import RendicontazioneScope1 from './moduli/UiModuloCarbonFootprint/rendicontazioneCarbonFootprint/rendicontazioneScope1/RendicontazioneScope1';
import RendicontazioneCarburanti from './moduli/UiModuloCarbonFootprint/rendicontazioneCarbonFootprint/rendicontazioneScope1/rendicontazioneCarburanti/RendicontazioneCarburanti';
import RendicontazioneGasRefrigeranti from './moduli/UiModuloCarbonFootprint/rendicontazioneCarbonFootprint/rendicontazioneScope1/rendicontazioneGasRefrigeranti/RendicontazioneGasRefrigeranti';
import RendicontazioneVeicoli from './moduli/UiModuloCarbonFootprint/rendicontazioneCarbonFootprint/rendicontazioneScope1/rendicontazioneVeicoli/RendicontazioneVeicoli';

import RendicontazioneScope2 from './moduli/UiModuloCarbonFootprint/rendicontazioneCarbonFootprint/rendicontazioneScope2/RendicontazioneScope2';
import RendicontazioneElettricita from './moduli/UiModuloCarbonFootprint/rendicontazioneCarbonFootprint/rendicontazioneScope2/rendicontazioneElettricita/RendicontazioneElettricita';

import RendicontazioneScope3 from './moduli/UiModuloCarbonFootprint/rendicontazioneCarbonFootprint/rendicontazioneScope3/RendicontazioneScope3';

//Analisi
import AnalisiCarbonFootprint from './moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/AnalisiCarbonFootprint';
import AnalisiCarbonFootprintBusinessUnit from './moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiBusinessUnit/AnalisiCarbonFootprintBusinessUnit';

import AnalisiScope1 from './moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope1/AnalisiScope1';
import AnalisiCarburanti from './moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope1/analisiCarburanti/AnalisiCarburanti';
import AnalisiGasRefrigeranti from './moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope1/analisiGasRefrigeranti/AnalisiGasRefrigeranti';
import AnalisiVeicoli from './moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope1/analisiVeicoli/AnalisiVeicoli';

import AnalisiScope2 from './moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope2/AnalisiScope2';
import AnalisiElettricita from './moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope2/analisiElettricita/AnalisiElettricita';

import AnalisiScope3 from './moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope3/AnalisiScope3';

import { PannelloGruppi } from './moduli/gestioneUtenti/PannelloGruppi';
import PannelloPanoramicaUtenti from './moduli/gestioneUtenti/PannelloPanoramicaUtenti';
import PannelloRichieste from './moduli/gestioneUtenti/PannelloRichieste';
import PannelloUtenti from './moduli/gestioneUtenti/PannelloUtenti';
import AggiornamentoContatti from './pages/AggiornamentoContatti';
import RendicontazioneCaricamentoMassivo
  from "./moduli/UiModuloCarbonFootprint/rendicontazioneCarbonFootprint/rendicontazioneScope3/rendicontazioneCaricamentoMassivo/RendicontazioneCaricamentoMassivo";
import ConfigurazioneEmployeeCommuting from './moduli/UiModuloCarbonFootprint/configurazioneCarbonFootprint/configurazioneScope3/configurazioneEmployeeCommuting/ConfigurazioneEmployeeCommuting';
import AnalisiBusinessTravel
  from "./moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope3/businessTravel/AnalisiBusinessTravel";
import AnalisiUpstreamTransport
  from "./moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope3/upstreamTransport/AnalisiUpstreamTransport";
import AnalisiWasteWastewater
  from "./moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope3/wasteWastewater/AnalisiWasteWastewater";
import AnalisiEmployeeCommuting
  from "./moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope3/employeeCommuting/AnalisiEmployeeCommuting";
import AnalisiUseOfSoldProducts
  from "./moduli/UiModuloCarbonFootprint/analisiCarbonFootprint/analisiScope3/useOfSoldProducts/AnalisiUseOfSoldProducts";

export const App = observer(() => {

  //Setta comportamento di default di axios
  useSettaAxios();

  //Se è presente un utente nello store popola lo stato
  useSettaUtente();

  //Setta la lingua di default
  useSettaLingua();

  //Setta tema se presente nel localstorage
  useSettaTema();

  //Setta debug mode se presente nel localstorage
  useSettaDebug();

  //Ascolta sul resizing e resetta l'ui su breakpoint
  useUiResize();

  //Effettura il logout quando le api ritornano 401
  useIntercettaUtenteNonAutenticato();

  const { theme, ui, moduli } = useStores();

  return (
    <div className={theme.theme}>
      <div className="bg-lightgray dark:bg-darkgray text-text dark:text-dark-text font-sans w-screen overflow-hidden">
        <BrowserRouter>
          <CambioPagina>
            <Routes>
              {/* unlogged user routes */}
              <Route path="/" element={<Unlogged />}>

                {/* autenticazione */}
                <Route index element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="richiedi-reset-password" element={<RichiediResetPassword />} />
                <Route path="reimposta-password" element={<ImpostaNuovaPassword />} />
                <Route path="completa-account" element={<CompletaAccount />} />
                <Route path="aggiorna-contatti" element={<AggiornamentoContatti />} />

                {/* misc */}
                <Route path="contatti" element={<Contatti />} />
                <Route path="informativa-dati-termini-uso" element={<TerminiCondizioni />} />
              </Route>

              {/* Notizie visibili dalla pagina di login */}
              <Route path="notizia/login/:notizia_id" element={<Notizia />} />

              {/* logged user routes */}
              <Route path="app" element={<Logged />}>
                <Route path="modulo" element={<Modulo titolo={moduli.moduloAttivo?.nome} descrizione={moduli.moduloAttivo?.descrizione} />}>

                  <Route path="dashboard">
                    <Route index element={<Dashboard />} />
                    <Route path="lista-moduli" element={<ListaModuli />} />
                  </Route>

                  <Route path="notizie">
                    <Route index element={<ArchivioNotizie />} />
                    <Route path="bozze" element={<ArchivioNotizie />} />
                    <Route path="archivate" element={<ArchivioNotizie />} />
                    <Route path="crea">
                      <Route index element={<NotiziaForm />} />
                      <Route path=":notizia_id" element={<NotiziaForm />} />
                    </Route>
                  </Route>

                  <Route path="business_unit">
                    <Route index element={<ArchivioBusinessUnits />} />
                    <Route path=":business_unit_nome" element={<SchedaBusinessUnit />} />
                  </Route>

                  <Route path="business_unit_ruoli" element={<ArchivioBusinessUnitRuoli />} />

                  <Route path="business_unit_categorie" element={<ArchivioBusinessUnitCategorie />} />

                  <Route path="amministrazione_utenti_e_accessi" element={<PannelloPanoramicaUtenti />} />
                  <Route path="/app/modulo/gestione_utenti" element={<PannelloUtenti/>}/>
                  <Route path="/app/modulo/gestione_gruppi" element={<PannelloGruppi/>}/>
                  <Route path="/app/modulo/controllo_accessi" element={<PannelloRichieste/>}/>
                  <Route path="/app/modulo/monitoraggio_attivita" element={<ArchivioAttivitaPanello/>}/>

                  <Route path="gestione_privilegi" element={<GestionePrivilegi />} />
                  <Route path="gestione_moduli" element={<GestioneModuli />} />
                  <Route path="gestione_unita_misura" element={<GestioneUnitaMisura />} />
                  <Route path="gestione_lingue" element={<GestioneLingue />} />

                  <Route path="bilanci">
                    <Route index element={<ArchivioBilanci />} />
                    <Route path=":bilancio_anno" element={<SchedaBilancio />}>
                      <Route path="dashboard" element={<SchedaBilancioDashboard />} />
                      <Route path="fattori_esg" element={<SchedaBilancioFattoriESG />} />
                      <Route path="temi_materialita" element={<SchedaBilancioTemi />} />
                      <Route path="obiettivi_agenda2030" element={<SchedaBilancioObiettiviAgenda2030 />} />
                      <Route path="business_units" element={<SchedaBilancioBusinessUnits />} />
                      <Route path="gruppi" element={<SchedaBilancioGruppi />} />
                    </Route>

                    <Route path=":bilancio_anno/fattori_esg/:fattore_codice" element={<DettagliFattoreESGNelBilancio />} />
                    <Route path=":bilancio_anno/temi_materialita/:tema_codice" element={<DettagliTemaMaterialitaNelBilancio />} />
                    <Route path=":bilancio_anno/obiettivi_agenda2030/:obiettivo_id" element={<DettagliObiettivoAgenda2030NelBilancio />} />
                    <Route path=":bilancio_anno/business_units/:business_unit_nome" element={<DettagliBusinessUnitNelBilancio />} />
                    <Route path=":bilancio_anno/gruppi/:gruppo_codice" element={<DettagliGruppoNelBilancio />} />
                  </Route>

                  <Route path="bilanci_fattori">
                    <Route index element={<ArchivioBilanciFattori />} />
                    <Route path=":bilancio_anno" element={<SchedaBilancio />}>
                      <Route path="fattori_esg" element={<SchedaBilancioFattoriESG />} />
                      <Route path="temi_materialita" element={<SchedaBilancioTemi />} />
                      <Route path="obiettivi_agenda2030" element={<SchedaBilancioObiettiviAgenda2030 />} />
                      <Route path="business_units" element={<SchedaBilancioBusinessUnits />} />
                      <Route path="gruppi" element={<SchedaBilancioGruppi />} />
                    </Route>

                    <Route path=":bilancio_anno/fattori_esg/:fattore_codice" element={<DettagliFattoreESGNelBilancio />} />
                    <Route path=":bilancio_anno/temi_materialita/:tema_codice" element={<DettagliTemaMaterialitaNelBilancio />} />
                    <Route path=":bilancio_anno/obiettivi_agenda2030/:obiettivo_id" element={<DettagliObiettivoAgenda2030NelBilancio />} />
                    <Route path=":bilancio_anno/business_units/:business_unit_nome" element={<DettagliBusinessUnitNelBilancio />} />
                    <Route path=":bilancio_anno/gruppi/:gruppo_codice" element={<DettagliGruppoNelBilancio />} />
                  </Route>

                  <Route path="bilanci_fattori_business_units">
                    <Route index element={<ArchivioBilanciFattoriBusinessUnits />} />
                    <Route path=":bilancio_anno" element={<SchedaBilancio />}>
                      <Route path="fattori_esg" element={<SchedaBilancioFattoriESG />} />
                      <Route path="temi_materialita" element={<SchedaBilancioTemi />} />
                      <Route path="obiettivi_agenda2030" element={<SchedaBilancioObiettiviAgenda2030 />} />
                      <Route path="business_units" element={<SchedaBilancioBusinessUnits />} />
                      <Route path="gruppi" element={<SchedaBilancioGruppi />} />
                    </Route>

                    <Route path=":bilancio_anno/fattori_esg/:fattore_codice" element={<DettagliFattoreESGNelBilancio />} />
                    <Route path=":bilancio_anno/temi_materialita/:tema_codice" element={<DettagliTemaMaterialitaNelBilancio />} />
                    <Route path=":bilancio_anno/obiettivi_agenda2030/:obiettivo_id" element={<DettagliObiettivoAgenda2030NelBilancio />} />
                    <Route path=":bilancio_anno/business_units/:business_unit_nome" element={<DettagliBusinessUnitNelBilancio />} />
                    <Route path=":bilancio_anno/gruppi/:gruppo_codice" element={<DettagliGruppoNelBilancio />} />

                  </Route>

                  <Route path="bilanci_materialita">
                    <Route index element={<ArchivioBilanciTemiMaterialita />} />
                    <Route path=":bilancio_anno" element={<SchedaBilancio />}>
                      <Route path="fattori_esg" element={<SchedaBilancioFattoriESG />} />
                      <Route path="temi_materialita" element={<SchedaBilancioTemi />} />
                      <Route path="obiettivi_agenda2030" element={<SchedaBilancioObiettiviAgenda2030 />} />
                      <Route path="business_units" element={<SchedaBilancioBusinessUnits />} />
                      <Route path="gruppi" element={<SchedaBilancioGruppi />} />
                    </Route>

                    <Route path=":bilancio_anno/fattori_esg/:fattore_codice" element={<DettagliFattoreESGNelBilancio />} />
                    <Route path=":bilancio_anno/temi_materialita/:tema_codice" element={<DettagliTemaMaterialitaNelBilancio />} />
                    <Route path=":bilancio_anno/obiettivi_agenda2030/:obiettivo_id" element={<DettagliObiettivoAgenda2030NelBilancio />} />
                    <Route path=":bilancio_anno/business_units/:business_unit_nome" element={<DettagliBusinessUnitNelBilancio />} />
                    <Route path=":bilancio_anno/gruppi/:gruppo_codice" element={<DettagliGruppoNelBilancio />} />

                  </Route>

                  <Route path="rendicontazione_bilanci">
                    <Route index element={<ArchivioRendicontazioneBilanci />} />
                    <Route path=":bilancio_anno" element={<SchedaRendicontazioneBilancio />} >
                      <Route path=":business_unit_nome" element={<SchedaRendicontazioneBilancioBusinessUnit />} />
                    </Route>
                  </Route>

                  <Route path="fattori_esg">
                    <Route index element={<ArchivioStandardESG />} />
                    <Route path=":fattore_codice" element={<SchedaFattoreESG />} />
                  </Route>

                  <Route path="fattori_esg_agenda_2030">
                    <Route index element={<ArchivioStandardESG />} />
                    <Route path=":fattore_codice" element={<SchedaFattoreESG />} />
                  </Route>

                  <Route path="materialita">
                    <Route index element={<ArchivioMaterialita />} />
                    <Route path=":tema_materialita_codice" element={<SchedaTemaMaterialita />} />
                    <Route path=":tema_materialita_codice/bilancio/:bilancio_anno" element={<SchedaBilancioTemaMaterialita />} />
                  </Route>

                  <Route path="agenda_2030">
                    <Route index element={<ArchivioObiettiviAgenda2030 />} />
                    <Route path=":obiettivo_id" element={<SchedaObiettivoAgenda2030 />} />
                  </Route>


                  <Route path="carbon_footprint" element={<CarbonFootprint />} />

                  {/* Configurazione carbon */}
                  <Route path="configurazione_carbon_footprint" element={<ConfigurazioneCarbonFootprint />} />

                  <Route path="configurazione_scope_1" element={<ConfigurazioneScope1 />} />
                  <Route path="configurazione_carburanti" element={<ConfigurazioneCarburanti />} />
                  <Route path="configurazione_gas_refrigeranti" element={<ConfigurazioneGasRefrigeranti />} />
                  <Route path="configurazione_veicoli" element={<ConfigurazioneVeicoli />} />

                  <Route path="configurazione_scope_2" element={<ConfigurazioneScope2 />} />
                  <Route path="configurazione_elettricita" element={<ConfigurazioneElettricita />} />

                  <Route path="configurazione_scope_3" element={<ConfigurazioneScope3 />} />
                  <Route path="configurazione_upstream_transport" element={<ConfigurazioneUpstreamTransport/>} />
                  <Route path="configurazione_business_travel" element={<ConfigurazioneBusinessTravel/>} />
                  <Route path="configurazione_employee_commuting" element={<ConfigurazioneEmployeeCommuting/>} />
                  <Route path="configurazione_use_of_sold_products" element={<ConfigurazioneUseOfSoldProducts/>} />
                  <Route path="configurazione_waste" element={<ConfigurazioneWaste/>} />

       
                  {/* Rendicontazione carbon */}
                  <Route path="rendicontazione_carbon_footprint" element={<RendicontazioneCarbonFootprint />} />

                  <Route path="rendicontazione_scope_1" element={<RendicontazioneScope1 />} />
                  <Route path="rendicontazione_carburanti" element={<RendicontazioneCarburanti />} />
                  <Route path="rendicontazione_gas_refrigeranti" element={<RendicontazioneGasRefrigeranti />} />
                  <Route path="rendicontazione_veicoli" element={<RendicontazioneVeicoli />} />

                  <Route path="rendicontazione_scope_2" element={<RendicontazioneScope2 />} />
                  <Route path="rendicontazione_elettricita" element={<RendicontazioneElettricita />} />

                  <Route path="rendicontazione_scope_3" element={<RendicontazioneScope3 />} />
                  <Route path="rendicontazione_scope_3_caricamento_massivo" element={<RendicontazioneCaricamentoMassivo />} />
     
                  {/* Analisi carbon */}
                  <Route path="analisi_carbon_footprint">
                    < Route index element={<AnalisiCarbonFootprint />} />
                    <Route path=":business_unit" element={<AnalisiCarbonFootprintBusinessUnit />} />
                  </Route>
                  <Route path="analisi_scope_1" element={<AnalisiScope1 />} />
                  <Route path="analisi_carburanti" element={<AnalisiCarburanti />} />
                  <Route path="analisi_gas_refrigeranti" element={<AnalisiGasRefrigeranti />} />
                  <Route path="analisi_veicoli" element={<AnalisiVeicoli />} />

                  <Route path="analisi_scope_2" element={<AnalisiScope2 />} />
                  <Route path="analisi_elettricita" element={<AnalisiElettricita />} />

                  <Route path="analisi_scope_3" element={<AnalisiScope3 />} />
                  <Route path="analisi_upstream_transport" element={<AnalisiUpstreamTransport />} />
                  <Route path="analisi_waste" element={<AnalisiWasteWastewater />} />
                  <Route path="analisi_business_travel" element={<AnalisiBusinessTravel />} />
                  <Route path="analisi_employee_commuting" element={<AnalisiEmployeeCommuting />} />
                  <Route path="analisi_use_of_sold_products" element={<AnalisiUseOfSoldProducts />} />

                </Route>

                {/* Static pages */}
                <Route path="attivita" element={<Attivita />} />
                <Route path="profilo" element={<Profilo />} />
                <Route path="notizia/:notizia_id" element={<Notizia />} />
              </Route>

              <Route path="*" element={<Pagina404 />} />
              <Route path="oauth_authentication" element={<AzureAutenticazione />} />

            </Routes>
          </CambioPagina>
        </BrowserRouter>
      </div>

      {ui.dialogue && ui.dialogueComponent}
      {ui.followMouse && <FollowMouse />}
      {ui.followMouseModal && <FollowMouseModal />}

      {ui.debug && <DebugModal />}

      {ui.toast && <Toast data={ui.toast} />}

    </div>
  );
})

export default App;
