import React from 'react'
import ReactEcharts from 'echarts-for-react'
import {IAnalisiScope3_tipologia} from '../../types';

const GraficoScope3Bar: React.FC<{
    emissioniFlat: IAnalisiScope3_tipologia[],
    anno: number
}> = ({emissioniFlat, anno}) => {

    // Lista emissioni valide
    emissioniFlat.filter(item => item.emissione_t_co2e > 0) // do not reassign to 'emissioniFlat'

    let setBu: Set<string> = new Set()
    emissioniFlat.forEach((item) => setBu.add(item.business_unit))
    const listaBu = Array.from(setBu)

    // Lista delle tipologie
    let listaTipologie: Set<string> = new Set()
    emissioniFlat.forEach(item => listaTipologie.add(item.tipologia))

    let listaAnni: Set<number> = new Set()
    emissioniFlat.forEach((item) => listaAnni.add(item.anno))

    // Serie emissioni per anno e tipologia
    type SerieDictionary = {
        type: string
        name: string
        colorBy: string
        label: {}
        data: number[]
        stack: string
        emphasis: { focus: string }
    }
    const serie: SerieDictionary[] = []

    listaAnni.forEach((anno) => {
        listaTipologie.forEach((tipologia) => {

            let data: number[] = []

            listaBu.forEach(business_unit => {
                let record = emissioniFlat.find(item => (
                    item.anno === anno &&
                    item.tipologia === tipologia &&
                    item.business_unit === business_unit
                ))
                data.push(record?.emissione_t_co2e??0)
            })

            serie.push({
                type: 'bar',
                name: anno + ' ' + tipologia,
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: data,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            })
        })
    })

    // See also https://echarts.apache.org/en/option.html#series-bar
    let option = {
        title: {
            show: false,
            text: 'Title',
        },
        legend: {},
        grid: {
            containLabel: true,
        },
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'category',
            data: listaBu,
            axisLabel: {
                width: 150,
                overflow: 'truncate',
                ellipsis: '...',
            },
        },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        toolbox: {
            show: true,
            feature: {
                mark: {show: true},
                dataView: {show: true, readOnly: false},
                restore: {show: true},
                saveAsImage: {show: true}
            }
        },
        series: serie
    };

    return (
        <div className="p-4" style={{height: listaBu.length * 80}}>
            <ReactEcharts style={{height: '100%'}} option={option}/>
        </div>
    )
}

export default GraficoScope3Bar;
