import React, { useState, useEffect } from 'react'
import { IModulo } from '../../../../types'
import { getAllModuli } from '../../../gestioneModuli/rest/gestioneModuli'
import ModuloTile from '../../../gestioneModuli/ModuloTile'

const ConfigurazioneScope3 = () => {
    const [moduli, setModuli] = useState<IModulo[]>([])
    const [moduliDisattivi, setModuliDisattivi] = useState<IModulo[]>([])


useEffect(() => {

    getAllModuli().then((res) => setModuli(res.data.data.filter((modulo: IModulo) => (
        (
        modulo.codice === 'configurazione_upstream_transport'  ||
        modulo.codice === 'configurazione_business_travel'  ||
        modulo.codice === 'configurazione_employee_commuting' ||
        modulo.codice === 'configurazione_use_of_sold_products' ||
        modulo.codice === 'configurazione_waste'
        )
        && modulo.attivo

    ))))

    getAllModuli().then((res) => setModuliDisattivi(res.data.data.filter((modulo: IModulo) => (
        (
            modulo.codice === 'configurazione_upstream_transport'  ||
            modulo.codice === 'configurazione_business_travel'  ||
            modulo.codice === 'configurazione_employee_commuting' ||
            modulo.codice === 'configurazione_use_of_sold_products' ||
            modulo.codice === 'configurazione_waste'
            
           
        ) 
        && !modulo.attivo
    ))))
}, [])

return (
    <div>
        {moduli && moduli.map((modulo) => (
            <ModuloTile modulo={modulo} modifica={false} />
        ))}

        {moduliDisattivi.length > 0 &&
            <div className="pointer-events-none opacity-60">
                {moduliDisattivi.map((modulo) => (
                    <ModuloTile modulo={modulo} modifica={false} />
                ))}
            </div>
        }
    </div>
    )
}

export default ConfigurazioneScope3;
