import React, { useEffect, useState } from 'react'

//Data
import { CgAdd } from 'react-icons/cg'
import { FaPen, FaTrash } from 'react-icons/fa'
import { useStores } from '../../../../../hooks/useStores'
import { elimina, get } from '../../../../../rest/crud_generica'
import { IFattoreUseOfSoldProducts } from '../../../types'

//Components
import Dialogue from '../../../../../components/common/Dialogue'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'

import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo'
import { ICallToActionTabella } from '../../../../../components/tabellaInterattiva/types'
import { useTabellaInterattivaContext } from '../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'
import UseOfSoldProductsForm from './components/UseOfSoldProductsForm'
import { t } from 'i18next'

const ConfigurazioneUseOfSoldProducts = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()

    const { ui } = useStores()

    const [useOfSoldProducts, setUseOfSoldProducts] = useState<IFattoreUseOfSoldProducts[]>([])

    useEffect(() => {
        listaUseOfSoldProducts()
    }, [ui.aggiornamentoGenerico])

    const listaUseOfSoldProducts = () => get('carbon_footprint/use_of_sold_products').then((res) => {
        setUseOfSoldProducts(res.data.data)
    })

    //Crea fattore emissivo
    const creaUseOfSoldProducts = () => ui.mountAsideModal(<UseOfSoldProductsForm />)

    //Modifica fattore emissivo
    const modificaUseOfSoldProducts = (useofsoldproducts: IFattoreUseOfSoldProducts) => {
        ui.mountAsideModal(<UseOfSoldProductsForm useofsoldproducts={useofsoldproducts} />)
    }

    //Elimina fattore emissivo
    const eliminaUseOfSoldProducts = (useofsoldproducts: IFattoreUseOfSoldProducts) => {
        elimina(`carbon_footprint/use_of_sold_products/${useofsoldproducts.id}`)
            .then(() => {
                ui.mountDialogue(<Dialogue titolo="Use sold of products eliminato con successo"/>)
                listaUseOfSoldProducts()
            })
            .catch(() => {
                ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione del fattore emissivo"/>)
            })
    }

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const { tabella } = useTabellaInterattivaContext()
    useEffect(() => {}, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    testo: 'Crea fattore',
                    azione: creaUseOfSoldProducts,
                    icona: <CgAdd size={18} />
                }])
            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaUseOfSoldProducts,
                    testo: 'Modifica',
                    icona: <FaPen size={12} />,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaUseOfSoldProducts,
                    testo: 'Elimina',
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={useOfSoldProducts}
                titolo="Fattori di Use of Sold Products"
                paragrafo="Inserisci i fattori di Use of Sold Products."
                callToActions={ctaTabella}
                colonne={
                    [
                        { id: 'anno', label: 'Anno' },
                        { id: 'paese', label: 'Paese' },                    
                        { id: 'unita_misura', label: t('common.unitaMisura') },
                        { id: 'kg_co2e', label: 'Kg co2e' },
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default ConfigurazioneUseOfSoldProducts;