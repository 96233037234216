
import React, { useEffect, useState } from 'react'

//Data
import { Controller, useForm } from 'react-hook-form'
import { useStores } from '../../../../../../hooks/useStores'
import { crea, get, modifica } from '../../../../../../rest/crud_generica'
import { getUnitaMisuraLista } from '../../../../../gestioneUnitaMisura/rest/gestioneUnitaMisura'
import { ICostanteVeicoloTipologiaCarburante } from '../../../../types'

//Components
import ErrorBox from '../../../../../../components/common/ErrorBox'
import InputField from '../../../../../../components/common/form/InputField'
import InfoBox from '../../../../../../components/common/InfoBox'
import SpinnerButton from '../../../../../../components/common/SpinnerButton'
import Select from 'react-select'

const CostantiTipologiaVeicoliCarburantiForm: React.FC<{ tipologieVeicoloCarburante?: ICostanteVeicoloTipologiaCarburante }> = ({ tipologieVeicoloCarburante }) => {

  const { ui } = useStores()
  const [attesa, setAttesa] = useState<boolean>(false)
  const [successo, setSuccesso] = useState<string>('')
  const [errore, setErrore] = useState<string>('')

  const { register, handleSubmit, watch, control, setValue, formState: { errors, isSubmitSuccessful } } = useForm({
    defaultValues: {
      anno: tipologieVeicoloCarburante ? tipologieVeicoloCarburante.anno : '',
      tipologia: tipologieVeicoloCarburante ? tipologieVeicoloCarburante.tipologia : '',
      carburante: tipologieVeicoloCarburante ? tipologieVeicoloCarburante.carburante : '',
      unita_misura: tipologieVeicoloCarburante ? tipologieVeicoloCarburante.unita_misura : '',
    }
  });

  const [listaTipologiaVeicoli, setListaTipologia,] = useState<any[]>([])

  useEffect(() => {
    listaTipoVeicoli()
  }, [])

  //Ottieni lista gas
  const listaTipoVeicoli = () => get('carbon_footprint/veicoli_tipologie').then((res) => {
    setListaTipologia(res.data.data)
  })

  //Ristruttura dati per label MultiSelect
  listaTipologiaVeicoli.filter((um) => !um.label).map((listaTipoVeicoli: any) => {
    listaTipoVeicoli.value = listaTipoVeicoli.nome
    listaTipoVeicoli.label = listaTipoVeicoli.nome
  })

  const [listaCarburanti, setListaCarburanti,] = useState<any[]>([])

  useEffect(() => {
    ottieniListaCarburanti()
  }, [])

  //Ottieni lista gas
  const ottieniListaCarburanti = () => get('carbon_footprint/carburanti_fattori_emissione').then((res) => {
    setListaCarburanti(res.data.data)
  })

  //Ristruttura dati per label MultiSelect
  listaCarburanti.filter((um) => !um.label).map((listaCarburanti: any) => {
    listaCarburanti.value = `${listaCarburanti.carburante}|${listaCarburanti.anno}|${listaCarburanti.unita_misura}`
    listaCarburanti.label = `${listaCarburanti.carburante} | ${listaCarburanti.anno} (in ${listaCarburanti.unita_misura})`
  })


  const onSubmit = () => {
    setAttesa(true)
    tipologieVeicoloCarburante
      ?
      modifica(`carbon_footprint/veicoli_tipologie_carburanti/${tipologieVeicoloCarburante.tipologia}/${tipologieVeicoloCarburante.carburante}/${tipologieVeicoloCarburante.anno}/${tipologieVeicoloCarburante.unita_misura}`, {
        anno: watch('anno'),
        tipologia: watch('tipologia'),
        carburante: watch('carburante'),
        unita_misura: watch('unita_misura'),

      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Costante modificata con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
      :
      crea(`carbon_footprint/veicoli_tipologie_carburanti`, {
        anno: watch('anno'),
        tipologia: watch('tipologia'),
        carburante: watch('carburante'),
        unita_misura: watch('unita_misura'),
      })
        .then((res) => {
          setErrore('')
          setSuccesso(res.data.message ? res.data.message : 'Costante creata con successo')
          ui.triggerAggiornamentoGenerico()
        })
        .catch((err) => {
          setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
          setSuccesso('')
        })
        .finally(() => setAttesa(false))
  }

  const triggerSettaCarburante = ((carburante: any) => {

    if (typeof carburante === 'object') {
      setValue('carburante', carburante.carburante)
      setValue('anno', carburante.anno)
      setValue('unita_misura', carburante.unita_misura)
    }

    if (typeof carburante === 'string') {

      let arrayCarb = carburante.split('|')

      let carb = arrayCarb[0]
      let anno = arrayCarb[1]
      let unita_misura = arrayCarb[2]

      setValue('carburante', carb)
      setValue('anno', anno)
      setValue('unita_misura', unita_misura)

    }

  })

  useEffect(() => {
    if (tipologieVeicoloCarburante) {
      triggerSettaCarburante(tipologieVeicoloCarburante)
    }
  }, [])

  return (
    <div>
      <div>
        {tipologieVeicoloCarburante ?
          <h2 className="text-2xl mb-2 capitalize">Modifica la costante {tipologieVeicoloCarburante.tipologia}, {tipologieVeicoloCarburante.carburante}</h2>
          :
          <h2 className="text-2xl mb-2 capitalize">Crea una nuova costante</h2>
        }
      </div>

      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form w-full">
          <label htmlFor="">Tipologia veicolo</label>
          <Controller
            name="tipologia"
            control={control}
            render={({ field: { onChange } }) => (
              <Select
                options={listaTipologiaVeicoli}
                onChange={(e: any) => onChange(e.value)}
                defaultValue={tipologieVeicoloCarburante?.tipologia && {
                  value: tipologieVeicoloCarburante.tipologia,
                  label: tipologieVeicoloCarburante.tipologia,
                }}
              />
            )}
          />
        </div>

        <div className="form w-full">
          <label htmlFor="">Carburante</label>
          <Select
            options={listaCarburanti}
            onChange={(e: any) => triggerSettaCarburante(e)}
            defaultValue={tipologieVeicoloCarburante?.carburante && tipologieVeicoloCarburante?.anno && tipologieVeicoloCarburante?.unita_misura && {
              value: tipologieVeicoloCarburante.carburante,
              label: `${tipologieVeicoloCarburante.carburante} | ${tipologieVeicoloCarburante.anno} (in ${tipologieVeicoloCarburante.unita_misura})`
            }}
          />
        </div>

        {
          !attesa ?
            <input type="submit" value={tipologieVeicoloCarburante ? 'Salva modifiche' : 'Crea costante veicolo'} />
            : <SpinnerButton />
        }

        {(errore || successo) &&
          <div className="mt-2">
            {errore && <ErrorBox errore={errore} />}
            {successo && <InfoBox messaggio={successo} />}
          </div>
        }

      </form>
    </div>
  )
}

export default CostantiTipologiaVeicoliCarburantiForm;