import React from 'react'
import { Link } from 'react-router-dom'

const CellaTabellaLinkWrapper: React.FC<{ children: React.ReactNode, link?: string, valore?: string | number | Array<Object> }> = ({ children, link, valore }) => {

    return (
        <>
            {
                link ? <Link to={`/${valore ? link.replace('{{valore}}', valore.toString()) : link}`}>{children}</Link> : children
            }
        </>
    )
}

export default CellaTabellaLinkWrapper;