import React, { useEffect, useState } from 'react'

//Data
import { CgAdd } from 'react-icons/cg'
import { FaPen, FaTrash } from 'react-icons/fa'
import { useStores } from '../../../../../hooks/useStores'
import { elimina, get } from '../../../../../rest/crud_generica'
import { IFattoreWaste } from '../../../types'

//Components
import Dialogue from '../../../../../components/common/Dialogue'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'

import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo'
import { ICallToActionTabella } from '../../../../../components/tabellaInterattiva/types'
import { useTabellaInterattivaContext } from '../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'
import WasteForm from './components/WasteForm'

const ConfigurazioneWaste = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()

    const { ui } = useStores()

    const [useWaste, setWaste] = useState<IFattoreWaste[]>([])

    useEffect(() => {
        listaWaste()
    }, [ui.aggiornamentoGenerico])

    const listaWaste = () => get('carbon_footprint/waste').then((res) => {
        setWaste(res.data.data)
    })

    //Crea fattore emissivo
    const creaWaste = () => ui.mountAsideModal(<WasteForm />)

    //Modifica fattore emissivo
    const modificaWaste = (waste: IFattoreWaste) => ui.mountAsideModal(<WasteForm waste={waste} />)

    //Elimina fattore emissivo
    const eliminaWaste = (waste: IFattoreWaste) => {
        elimina(`carbon_footprint/waste/${waste.id}`)
            .then(() => {
                ui.mountDialogue(<Dialogue titolo="Waste eliminato con successo"/>)
                listaWaste()
            })
            .catch(() => {
                ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione del fattore emissivo"/>)
            })
    }

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const { tabella } = useTabellaInterattivaContext()
    useEffect(() => {}, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    azione: creaWaste,
                    testo: 'Crea fattore',
                    icona: <CgAdd size={18} />
                }])
            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaWaste,
                    testo: 'Modifica',
                    icona: <FaPen size={12} />,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaWaste,
                    testo: 'Elimina',
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={useWaste}
                synergical={true}
                titolo="Fattori di Waste"
                paragrafo="Inserisci i fattori di Waste."
                callToActions={ctaTabella}
                colonne={
                    [
                        { id: 'anno', label: 'Anno' },
                        { id: 'codice_waste', label: 'Waste Code' },
                        { id: 'waste_category', label: 'Waste Category' },
                        { id: 'posizione_geografica', label: 'Posizione Geografica' },
                        { id: 'incineration_with_energy', label: 'Incenerimento recupero energetico' },
                        { id: 'incineration_without_energy', label: 'Incenerimento senza recupero energetico' },
                        { id: 'delivery_to_landfill', label: 'Discarica' },
                        { id: 'other_disposal', label: 'Altro' },
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default ConfigurazioneWaste;