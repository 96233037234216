import React, {useEffect, useState} from 'react'

//Data
import {useStores} from '../../../../../../hooks/useStores'
import {ITravelMode} from '../../../../types'
import {elimina, get} from '../../../../../../rest/crud_generica'
import {observer} from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'

//Components
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'
import Dialogue from '../../../../../../components/common/Dialogue'
import {CgAdd} from 'react-icons/cg'
import {FaPen, FaTrash} from 'react-icons/fa'
import {ICallToActionTabella} from '../../../../../../components/tabellaInterattiva/types'
import {useTabellaInterattivaContext} from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'
import TravelModeForm from './TravelModeForm'

const TravelMode = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()
    const {ui} = useStores()

    const [travelMode, setTravelMode] = useState<ITravelMode[]>([])

    useEffect(() => {
        listaTravelMode()
    }, [ui.aggiornamentoGenerico])

    //Ottieni lista gas
    const listaTravelMode = () => get('carbon_footprint/travel_mode_employee_commuting').then((res) => {
        setTravelMode(res.data.data)
    })

    //Crea gas
    const creaTipologiaVeicolo = () => ui.mountAsideModal(<TravelModeForm/>)

    //Modifica gas
    const modificaTipologiaVeicolo = (travelmode: ITravelMode) => ui.mountAsideModal(<TravelModeForm travelmode={travelmode}/>)

    //Elimina gas
    const eliminaTipologiaVeicolo = (travelmode: ITravelMode) => elimina(`carbon_footprint/travel_mode_employee_commuting/${travelmode.nome}`)
        .then(() => {
            ui.mountDialogue(<Dialogue titolo="Modalità eliminata con successo"/>)
            listaTravelMode()
        })
        .catch(() => {
            ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione della modalità"/>)
        })

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const {tabella} = useTabellaInterattivaContext()
    useEffect(() => {}, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    azione: creaTipologiaVeicolo,
                    testo: 'Crea modalità',
                    icona: <CgAdd size={18}/>
                }])
            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaTipologiaVeicolo,
                    testo: 'Modifica',
                    icona: <FaPen size={12}/>,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaTipologiaVeicolo,
                    testo: 'Elimina',
                    icona: <FaTrash size={12}/>,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }
    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={travelMode}
                titolo="Modalità di Viaggio"
                paragrafo="Inserisci la modalità di viaggio"
                endpoint="carbon_footprint/travel_mode_employee_commuting"
                pk={['nome']}
                callToActions={ctaTabella}
                colonne={
                    [
                        {id: 'nome', label: 'Nome'},
                        {id: 'descrizione', label: 'Descrizione'},
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default TravelMode;