import React, {useEffect, useState} from 'react'

import {IModulo} from "../../../../types";
import {getAllModuli} from "../../../gestioneModuli/rest/gestioneModuli";
import ModuloTile from "../../../gestioneModuli/ModuloTile";

export default function AnalisiScope3() {

    const [moduli, setModuli] = useState<IModulo[]>([])
    const [moduliDisattivi, setModuliDisattivi] = useState<IModulo[]>([])

    useEffect(() => {

        getAllModuli().then((res) => setModuli(res.data.data.filter((modulo: IModulo) => (
            ['analisi_upstream_transport', 'analisi_waste', 'analisi_business_travel', 'analisi_employee_commuting', 'analisi_use_of_sold_products'].includes(modulo.codice) && modulo.attivo
        ))))

        getAllModuli().then((res) => setModuliDisattivi(res.data.data.filter((modulo: IModulo) => (
            ['analisi_upstream_transport', 'analisi_waste', 'analisi_business_travel', 'analisi_employee_commuting', 'analisi_use_of_sold_products'].includes(modulo.codice) && !modulo.attivo
        ))))
    }, [])

    return (
        <div>
            {moduli && moduli.map((modulo) => (
                <ModuloTile modulo={modulo} modifica={false}/>
            ))}

            {moduliDisattivi.length > 0 &&
                <div className="pointer-events-none opacity-60">
                    {moduliDisattivi.map((modulo) => (
                        <ModuloTile modulo={modulo} modifica={false}/>
                    ))}
                </div>
            }
        </div>
    )
}
