import React from 'react'

//Data
import {observer} from 'mobx-react-lite';
import {useTabellaInterattivaContext} from './context/useTabellaInterattivaContext';
import {IColonnaTabellaInterattiva} from './types';

//Components
import {FaArrowDown, FaArrowUp} from 'react-icons/fa';

const RigaHeadTabella: React.FC<{ riga: object, colonne?: IColonnaTabellaInterattiva[] }> = observer(({riga}) => {

    const {tabella} = useTabellaInterattivaContext()

    const trasformaNomeColonna = (valore: string) => {

        let nuovaColonna = tabella.colonne?.find((colonna) => colonna.id === valore)

        if (nuovaColonna) {
            return nuovaColonna.label;
        }

        return valore;
    }

    return (
        <tr>
            {tabella.colonne?.map(colonna => {
                let valore = colonna.id;
                return (
                    <th key={valore}>
                        <div className="flex gap-1 items-center">
                            {trasformaNomeColonna(valore)}
                            <span className="flex gap-[2px]">
                                <FaArrowUp
                                    onClick={() => tabella.cambiaOrdinamento(valore, 'ASC')}
                                    className={
                                        `cursor-pointer duration-200 hover:opacity-70
                                        ${tabella.colonnaOrdinamento === valore && tabella.direzioneOrdinamento === 'ASC' && 'text-dark-orange'}`
                                    }
                                    size={10}
                                />
                                <FaArrowDown
                                    onClick={() => tabella.cambiaOrdinamento(valore, 'DESC')}
                                    className={
                                        `cursor-pointer duration-200 hover:opacity-70
                                        ${tabella.colonnaOrdinamento === valore && tabella.direzioneOrdinamento === 'DESC' && 'text-dark-orange'}`
                                    }
                                    size={10}
                                />
                            </span>
                        </div>
                    </th>
                )
            })}
            {tabella.callToStatusRiga && tabella.callToStatusRiga.length > 0 && <th style={{position: "relative"}}></th>}
            {tabella.callToActionsRiga && tabella.callToActionsRiga.length > 0 && <th className="cellaAzioniRiga"></th>}
        </tr>
    )
})

export default RigaHeadTabella;
