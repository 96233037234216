import React from 'react'

import {observer} from 'mobx-react-lite'
import {useTranslation} from 'react-i18next'

import StatisticheBilancio from '../../components/bilanci/StatisticheBilancio'
import StatisticheTema from '../../components/bilanci/StatisticheTema'
import {useModuloESGStores} from "../../context/useModuloESGStores";
import {useParams} from 'react-router-dom';

const SchedaBilancioDashboard = observer(() => {

    const {t} = useTranslation()

    const {bilanci} = useModuloESGStores()
    const {bilancio_anno} = useParams()

    return (
        <div>
            <p className="font-bold text-lg mb-4 my-4">{t('common.statistiche')}:</p>
            <StatisticheBilancio/>
            <p className="font-bold text-lg mb-4 my-4">{t('common.analisi')}:</p>
            <StatisticheTema anno_bilancio={bilanci.bilancioAttivo?.anno??(bilancio_anno&&parseInt(bilancio_anno)) as number}/>
        </div>
    )
})

export default SchedaBilancioDashboard;