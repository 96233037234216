import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { useModuloRendicontazioneBilanciStores } from '../context/useModuloRendicontazioneBilanciStores'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../../../hooks/useStores'
import { useForm, Controller } from 'react-hook-form'
import { IFattoreRendicontabile, IIndicatore } from '../../../types'
import {
    collegaIndicatore,
    eliminaIndicatore,
    modificaIndicatore,
    validaIndicatore,
    invalidaIndicatore
} from '../rest/rendicontazione_indicatori'
import { IUnitaMisura } from '../../../../../types'

//Components
import Select from 'react-select'
import InfoBox from '../../../../../components/common/InfoBox'
import ErrorBox from '../../../../../components/common/ErrorBox'
import SpinnerButton from '../../../../../components/common/SpinnerButton'
import InputField from '../../../../../components/common/form/InputField'
import { FaCheck, FaTrash } from 'react-icons/fa'

const RendicontazioneIndicatoreForm: React.FC<{
    indicatore?: IIndicatore,
    bilancio_anno: number,
    business_unit?: string,
    fattore1?: IFattoreRendicontabile,
    fattore2?: IFattoreRendicontabile,
    disabilitato?: boolean,
    ruolo: number
    fattore_tipo_indicatore?: string,
    unita_misura_predefinita?: string,
    risposte_multiple_predefinite?: [],
    numero_risposte_multiple_possibili?: number,
}> = observer(({
    indicatore,
    bilancio_anno,
    fattore1,
    fattore2,
    business_unit,
    disabilitato = false,
    ruolo,
    fattore_tipo_indicatore,
    unita_misura_predefinita,
    risposte_multiple_predefinite,
    numero_risposte_multiple_possibili = 1
}) => {

    const { rendicontazione_bilanci } = useModuloRendicontazioneBilanciStores()
    const { unitaMisura, ui } = useStores()
    const { t } = useTranslation();

    //Lista delle unità di misura
    const [listaUnitaMisura, setListaUnitaMisura] = useState<IUnitaMisura[]>(unitaMisura.listaUnitaMisura)

    //Tipologia di indicatore richiesta dal fattore
    const [tipoIndicatore, setTipoIndicatore] = useState<string>(fattore_tipo_indicatore ? fattore_tipo_indicatore : 'numerico')

    //Gestione dell'indicatore attivo
    const [datiIndicatore, setdatiIndicatore] = useState<object>()

    //Stato di modifica dell'indicatore
    const [editingIndicatore, setEditingIndicatore] = useState<boolean>()
    const [editingIndicatoreError, setEditingIndicatoreError] = useState<string>('')

    //Stato di creazione dell'indicatore
    const [creatingIndicatore, setCreatingIndicatore] = useState<boolean>()
    const [creatingIndicatoreError, setCreatingIndicatoreError] = useState<string>('')

    //Stato delle risposte multiple selezionate
    const [risposteMultipleSelezionate, setRisposteMultipleSelezionate] = useState<string[]>([])

    //Istanzia Hook Form
    const { watch, setValue, register, handleSubmit, control, formState: { errors, isSubmitSuccessful } } = useForm({
        defaultValues: {
            valore_testuale: indicatore?.valore_testuale,
            valore_numerico: indicatore?.valore_numerico as number * 1,
            valore_booleano: indicatore?.valore_booleano,
            valore_risposta_multipla: indicatore?.valore_risposta_multipla,
            unita_misura: indicatore?.unita_misura,
            note: indicatore?.note,
        }
    })

    //Gestisce le risposte selezionate attive
    const handleRisposteSelezionate = (risposta: string) => {

        if (risposteMultipleSelezionate.includes(risposta)) {
            setRisposteMultipleSelezionate(prev => prev.filter(r => r !== risposta))
        } else {
            setRisposteMultipleSelezionate(prev => [...prev, risposta])
        }

    }


    //Preimposta risposte se l'indicatore esiste
    useEffect(() => {

        if (indicatore && indicatore.valore_risposta_multipla) {
            setRisposteMultipleSelezionate(indicatore.valore_risposta_multipla)
        }

    }, [])

    //Setta valore risposte preselezionate
    useEffect(() => {

        setValue('valore_risposta_multipla', risposteMultipleSelezionate)

    }, [risposteMultipleSelezionate])

    //Preimposta valore booleano se esiste
    useEffect(() => {

        if (indicatore && indicatore.valore_booleano) {
            setValue('valore_booleano', indicatore.valore_booleano)
        } else {
            setValue('valore_booleano', false)
        }

    }, [])


    //Crea o modifica l'indicatore
    const onSubmit = () => {

        if (indicatore) {

            setEditingIndicatore(true)

            modificaIndicatore(datiIndicatore as IIndicatore, bilancio_anno, business_unit as string, fattore1?.codice as string, fattore2?.codice).then((res) => {

                ui.mountToast({
                    messaggio: 'Indicatore salvato con successo',
                    colore: 'green'
                })

                if (res.data.fattore_codice_2) {
                    rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1, res.data.fattore_codice_2], res.data.indicatore, res.data.tabella_codice)
                } else {
                    rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1], res.data.indicatore)
                }

                setEditingIndicatoreError('')

            }).catch((err) => {

                err.response.data.message ? setEditingIndicatoreError(err.response.data.message) : setEditingIndicatoreError('Errore generico')

            }).finally(() => setEditingIndicatore(false))

        } else {

            setCreatingIndicatore(true)

            collegaIndicatore(datiIndicatore as IIndicatore, bilancio_anno, fattore1?.codice as string, business_unit as string, fattore2?.codice).then((res) => {

                ui.mountToast({
                    messaggio: 'Indicatore attribuito con successo',
                    colore: 'green'
                })


                if (res.data.fattore_codice_2) {
                    rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1, res.data.fattore_codice_2], res.data.indicatore, res.data.tabella_codice)
                } else {
                    rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1], res.data.indicatore)
                }


                setCreatingIndicatoreError('')

            }).catch((err) => {

                err.response.data.message ? setCreatingIndicatoreError(err.response.data.message) : setCreatingIndicatoreError('Errore generico')

            }).finally(() => setCreatingIndicatore(false))

        }
    }

    //Elimina l'indicatore
    const handleEliminaIndicatore = () => {

        setEditingIndicatore(true)

        eliminaIndicatore(bilancio_anno, business_unit as string, fattore1?.codice as string).then((res) => {

            ui.mountToast({
                messaggio: 'Indicatore eliminato con successo',
                colore: 'green'
            })

            if (res.data.fattore_codice_2) {
                rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1, res.data.fattore_codice_2], indicatore, res.data.tabella_codice, true)
            } else {
                rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1], indicatore, undefined, true)
            }

        }).finally(() => {
            setEditingIndicatore(false)
        })
    }

    //Valida l'indicatore
    const handleValidaIndicatore = () => {

        setEditingIndicatore(true)

        validaIndicatore(bilancio_anno, business_unit as string, fattore1?.codice as string).then((res) => {

            ui.mountToast({
                messaggio: 'Indicatore validato con successo',
                colore: 'green'
            })


            if (res.data.fattore_codice_2) {
                rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1, res.data.fattore_codice_2], res.data.indicatore, res.data.tabella_codice)
            } else {
                rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1], res.data.indicatore)
            }

        }).finally(() => {
            setEditingIndicatore(false)
        })
    }

    //Rimuove validazione dall'indicatore
    const handleInvalidaIndicatore = () => {

        setEditingIndicatore(true)

        invalidaIndicatore(bilancio_anno, business_unit as string, fattore1?.codice as string).then((res) => {

            ui.mountToast({
                messaggio: 'Validazione rimossa con successo dall\'indicatore',
                colore: 'green'
            })

            if (res.data.fattore_codice_2) {
                rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1, res.data.fattore_codice_2], res.data.indicatore, res.data.tabella_codice)
            } else {
                rendicontazione_bilanci.applicaAggiornamentoIndicatore([res.data.fattore_codice_1], res.data.indicatore)
            }

        }).finally(() => {
            setEditingIndicatore(false)
        })
    }

    //Cambia l'oggetto del payload in base al tipo di indicatore che l'utente sta creando
    useEffect(() => {

        if (tipoIndicatore === 'numerico') {
            setdatiIndicatore({
                valore_numerico: watch('valore_numerico'),
                unita_misura: unita_misura_predefinita ? unita_misura_predefinita : watch('unita_misura'),
                note: watch('note'),
            })
        }

        if (tipoIndicatore === 'testuale') {
            setdatiIndicatore({
                valore_testuale: watch('valore_testuale'),
                note: watch('note'),
            })
        }

        if (tipoIndicatore === 'booleano') {
            setdatiIndicatore({
                valore_booleano: watch('valore_booleano'),
                note: watch('note'),
            })
        }

        if (tipoIndicatore === 'risposta_multipla') {
            setdatiIndicatore({
                valore_risposta_multipla: watch('valore_risposta_multipla'),
                note: watch('note'),
            })
        }

    }, [
        tipoIndicatore,
        watch('valore_numerico'),
        watch('unita_misura'),
        watch('valore_testuale'),
        watch('valore_booleano'),
        watch('valore_risposta_multipla'),
        watch('note')
    ])

    //Setta le unità di misura
    useEffect(() => {

        setTimeout(() => {
            if (unitaMisura.listaUnitaMisura.length === 0) {
                unitaMisura.settaListaUnitaMisura().then(() => {
                    setListaUnitaMisura(unitaMisura.listaUnitaMisura)
                })
            }
        }, 300)

    }, [])

    //Ristruttura dati per label MultiSelect
    listaUnitaMisura.map((unitaMisura: any) => {
        unitaMisura.value = unitaMisura.codice
        unitaMisura.label = unitaMisura.codice
    })

    //Presetta se l'indicatore è numerico, testuale o v/f
    useEffect(() => {

        if (indicatore && indicatore.valore_numerico) {
            setTipoIndicatore('numerico')
        }

        if (indicatore && indicatore.valore_testuale) {
            setTipoIndicatore('testuale')
        }

        if (indicatore && indicatore.valore_booleano) {
            setTipoIndicatore('booleano')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    //Stili
    const styles = {
        bottoneTipo: 'p-3 w-1/2 text-center bg-lightgray dark:bg-darkgray-d duration-200 cursor-pointer text-[rgba(0,0,0,0.6)] dark:text-[rgba(255,255,255,0.4)] flex items-center justify-center',
        bottoneTipoAttivo: 'bg-accent dark:bg-dark-accent !text-white'
    }

    return (
        <div className={`${disabilitato && 'opacity-80 pointer-events-none'}`}>
            {indicatore && indicatore.validato ?
                <>
                    {indicatore.validato &&
                        <div className="mb-4">
                            <InfoBox messaggio={t('ModuloFattoriEsg.FormRendicontazioneIndicatotre.giaValidato')} />
                        </div>
                    }

                    <div className="pointer-events opacity-70">
                        <div>
                            {tipoIndicatore === 'numerico' &&
                                <div>
                                    <div className="flex gap-1 items-end">
                                        <InputField
                                            label={`${t('common.valore')} ${unita_misura_predefinita && `(in  ${unita_misura_predefinita})`}`}
                                            classi="valore"
                                            placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderValore')}
                                            type="number"
                                            form={register("valore_numerico")}
                                        />

                                        {!unita_misura_predefinita &&
                                            <div className="form max-w-[90px] items-center">
                                                <Controller
                                                    name="unita_misura"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <Select
                                                            options={listaUnitaMisura}
                                                            defaultInputValue={indicatore?.unita_misura}
                                                            onChange={(e: any) => onChange(e.value)}
                                                            placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderUm')}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            {
                                tipoIndicatore === 'testuale' &&
                                <>
                                    <InputField
                                        label={t('ModuloFattoriEsg.FormIndicatore.Testo')}
                                        classi="testo"
                                        placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderTesto')}
                                        type="textarea"
                                        error={errors.valore_testuale}
                                        form={register("valore_testuale")}
                                    />
                                </>
                            }

                            {
                                tipoIndicatore === 'risposta_multipla' &&
                                <>
                                    <label htmlFor="">Risposta multipla {numero_risposte_multiple_possibili && `(max ${numero_risposte_multiple_possibili} risposte)`}</label>
                                    <div className="flex flex-col gap-1 mt-2">
                                        {
                                            risposte_multiple_predefinite &&
                                            risposte_multiple_predefinite.map((risposta: string) => (
                                                <div
                                                    className={
                                                        `p-2 cursor-pointer duration-100 flex items-center gap-1
                                                            ${risposteMultipleSelezionate.includes(risposta) ? 'bg-accent dark:bg-dark-accent text-white' : 'bg-lightgray dark:bg-darkgray'}
                                                            ${risposteMultipleSelezionate.length >= numero_risposte_multiple_possibili && !risposteMultipleSelezionate.includes(risposta) && 'pointer-events-none opacity-70'}
                                                        `}
                                                    onClick={() => handleRisposteSelezionate(risposta)}
                                                >
                                                    {risposteMultipleSelezionate.includes(risposta) && <FaCheck />}
                                                    <span>{risposta}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            }

                            <div className="mt-4">
                                <InputField
                                    label={t('ModuloFattoriEsg.FormIndicatore.note')}
                                    classi="note"
                                    placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderNote')}
                                    type="textarea"
                                    error={errors.note}
                                    form={register("note")}
                                />
                            </div>
                        </div>


                    </div>

                    {
                        ruolo >= 3 &&
                        <div className="mt-3" onClick={() => handleInvalidaIndicatore()}>
                            <button className="p-2 bg-red dark:bg-dark-red w-full text-white">
                                {t('ModuloFattoriEsg.FormIndicatore.rimuviValidizioneIndicatori')}
                            </button>
                        </div>
                    }
                </>
                :
                <>
                    {disabilitato &&
                        <div className="mb-4">
                            <InfoBox messaggio={t('ModuloFattoriEsg.FormIndicatore.nonHaiIlPrivellagio')} />
                        </div>
                    }
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            {!fattore_tipo_indicatore &&
                                <div className="flex items-stretch rounded-sm overflow-hidden">

                                    <div
                                        className={`${styles.bottoneTipo} ${tipoIndicatore === 'numerico' && styles.bottoneTipoAttivo}`}
                                        onClick={() => setTipoIndicatore('numerico')}>
                                        <span>{t('ModuloFattoriEsg.FormIndicatore.valoreNumerico')}</span>
                                    </div>

                                    <div
                                        className={`${styles.bottoneTipo} ${tipoIndicatore === 'testuale' && styles.bottoneTipoAttivo}`}
                                        onClick={() => setTipoIndicatore('testuale')}>
                                        <span>{t('ModuloFattoriEsg.FormIndicatore.valoreTestuale')}</span>
                                    </div>

                                    <div
                                        className={`${styles.bottoneTipo} ${tipoIndicatore === 'booleano' && styles.bottoneTipoAttivo}`}
                                        onClick={() => setTipoIndicatore('booleano')}>
                                        <span>{t('ModuloFattoriEsg.FormIndicatore.valoreBooleano')}</span>
                                    </div>

                                    {risposte_multiple_predefinite && risposte_multiple_predefinite.length > 1 &&
                                        <div
                                            className={`${styles.bottoneTipo} ${tipoIndicatore === 'risposta_multipla' && styles.bottoneTipoAttivo}`}
                                            onClick={() => setTipoIndicatore('risposta_multipla')}
                                        >
                                            <span>{t('ModuloFattoriEsg.FormIndicatore.valoreRispostaMultipla')}</span>
                                        </div>
                                    }


                                </div>
                            }

                            <div>
                                {
                                    tipoIndicatore === 'numerico' &&
                                    <div>
                                        <div className="flex gap-1 items-end">
                                            <InputField
                                                label={`${t('common.valore')} ${unita_misura_predefinita && `(in  ${unita_misura_predefinita})`}`}
                                                classi="valore"
                                                placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderValore')}
                                                type="number"
                                                form={register("valore_numerico")}
                                            />

                                            {!unita_misura_predefinita &&
                                                <div className="form max-w-[90px] items-center">
                                                    <Controller
                                                        name="unita_misura"
                                                        control={control}
                                                        render={({ field: { onChange } }) => (
                                                            <Select
                                                                options={listaUnitaMisura}
                                                                defaultInputValue={indicatore?.unita_misura}
                                                                onChange={(e: any) => onChange(e.value)}
                                                                placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderUm')}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }

                                {
                                    tipoIndicatore === 'testuale' &&
                                    <>
                                        <InputField
                                            label={t('ModuloFattoriEsg.FormIndicatore.Testo')}
                                            classi="testo"
                                            placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderTesto')}
                                            type="textarea"
                                            error={errors.valore_testuale}
                                            form={register("valore_testuale")}
                                        />
                                    </>
                                }

                                {
                                    tipoIndicatore === 'booleano' &&
                                    <>
                                        <label className="text-center block w-full mt-4">{t('common.selezionaValore')}</label>
                                        <div className="flex items-stretch mt-3 rounded-sm overflow-hidden">
                                            <div
                                                className={`p-2 grow cursor-pointer text-center ${watch('valore_booleano') ? 'bg-accent dark:bg-dark-accent text-white' : 'bg-lightgray dark:bg-darkgray'}`}
                                                onClick={() => setValue('valore_booleano', true)}>
                                                {t('common.si')}
                                            </div>
                                            <div
                                                className={`p-2 grow cursor-pointer text-center ${!watch('valore_booleano') ? 'bg-accent dark:bg-dark-accent text-white' : 'bg-lightgray dark:bg-darkgray'}`}
                                                onClick={() => setValue('valore_booleano', false)}>
                                                {t('common.no')}
                                            </div>
                                        </div>
                                    </>
                                }

                                {
                                    tipoIndicatore === 'risposta_multipla' &&
                                    <>
                                        <label htmlFor="">Risposta multipla {numero_risposte_multiple_possibili && `(max ${numero_risposte_multiple_possibili} risposte)`}</label>
                                        <div className="flex flex-col gap-1 mt-2">
                                            {
                                                risposte_multiple_predefinite &&
                                                risposte_multiple_predefinite.map((risposta: string) => (
                                                    <div
                                                        className={
                                                            `p-2 cursor-pointer duration-100 flex items-center gap-2
                                                            ${risposteMultipleSelezionate.includes(risposta) ? 'bg-accent dark:bg-dark-accent text-white' : 'bg-lightgray dark:bg-darkgray'}
                                                            ${risposteMultipleSelezionate.length >= numero_risposte_multiple_possibili && !risposteMultipleSelezionate.includes(risposta) && 'pointer-events-none opacity-70'}
                                                        `}
                                                        onClick={() => handleRisposteSelezionate(risposta)}
                                                    >
                                                        {risposteMultipleSelezionate.includes(risposta) &&
                                                            <div>
                                                                <FaCheck />
                                                            </div>
                                                        }
                                                        <span className="grow">{risposta}</span>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                }
                            </div>

                        </div>

                        <div className="mt-4">
                            <InputField
                                label={t('ModuloFattoriEsg.FormIndicatore.note')}
                                classi="note"
                                placeholder={t('ModuloFattoriEsg.FormIndicatore.placeholderNoteAltra')}
                                type="textarea"
                                error={errors.note}
                                form={register("note")}
                            />
                        </div>

                        <div className="form">
                            {/* Submit modifica indicatore */}
                            {indicatore &&

                                <>
                                    <div className="form">
                                        <div>
                                            {!editingIndicatore ? <input type="submit" value={t('common.salvaModifica')} /> : <SpinnerButton />}
                                        </div>
                                    </div>

                                    {
                                        isSubmitSuccessful && !editingIndicatore && (

                                            editingIndicatoreError
                                                ?
                                                <div className="my-2">
                                                    <ErrorBox errore={editingIndicatoreError} />
                                                </div>
                                                :
                                                <div className="my-2">
                                                    <InfoBox messaggio={t('common.modificaSalvato')} />
                                                </div>

                                        )
                                    }

                                </>
                            }

                            {/* Submit creazione indicatore */}
                            {!indicatore &&

                                <>
                                    {
                                        (isSubmitSuccessful && !creatingIndicatoreError && !creatingIndicatore) ?
                                            <InfoBox messaggio={t('ModuloFattoriEsg.FormRendicontazioneIndicatotre.IndicatoreAttribuitoSucesso')} />
                                            :
                                            <>
                                                {ruolo >= 1 &&
                                                    <div className="form">
                                                        {!creatingIndicatore ? <input type="submit" value={t('common.salva')} className="capitalize" /> : <SpinnerButton />}
                                                    </div>
                                                }
                                            </>
                                    }

                                    {
                                        creatingIndicatoreError &&
                                        <div className="mb-2">
                                            <ErrorBox errore={creatingIndicatoreError.toString()} />
                                        </div>
                                    }

                                </>
                            }


                        </div>

                    </form>

                    {indicatore &&
                        <div className="flex gap-1">
                            {
                                ruolo >= 2 &&
                                <div className="grow" onClick={() => handleValidaIndicatore()}>
                                    <button className="p-2 bg-green dark:bg-dark-green w-full text-white hover:bg-green hover:dark-bg-dark-green hover:text-white flex items-center justify-center gap-2">
                                        <FaCheck />
                                        <span>{t('ModuloFattoriEsg.FormIndicatoreTabella.validaIndicatore')}</span>
                                    </button>
                                </div>
                            }

                            {
                                ruolo >= 3 &&
                                <div className="grow" onClick={() => handleEliminaIndicatore()}>
                                    <button className="p-2 bg-red dark:bg-dark-red w-full text-white hover:bg-red hover:dark-bg-dark-red hover:text-white flex items-center justify-center gap-2">
                                        <FaTrash />
                                        <span>{t('ModuloFattoriEsg.FormIndicatoreTabella.eliminaIndicatore')}</span>
                                    </button>
                                </div>
                            }
                        </div>
                    }

                </>
            }

        </div>
    )
})

export default RendicontazioneIndicatoreForm;