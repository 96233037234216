export const it_fattori_esg = {
    FormFattoreEsgBilanci: {
        modificaBilancioPerAnno: 'Modifica il bilancio per l\'anno',
        creaUnNuovaBilancio: 'Crea un nuovo bilancio',
        modificaDatiIBilanci: 'Modifica i dati del bilancio dell\'anno',
        testoModifica: 'Scegli un anno e inserisci una descrizione. Potrai successivamente inserire dei Fattori ESG per il bilancio creato',
        selezionaAnno: 'Seleziona un anno',
        fornisciBilancio: 'Fornisci una descrizione per il bilancio',
        placeholderBusinessunit: 'Descrizione del bilancio',
        creaBilancio: 'Crea bilancio',
    },

    BilancioContextMenu: {
        eliminatoSucesso: 'Bilancio eliminato con successo',
        modificaBilancio: 'Modifica bilancio',
        eliminaBilacio: 'Sei sicuro di voler eliminare il bilancio del',
        eliminaBilancioTesto: 'Questa azione è irreversibile. Tutti  i dati precedentemente associati a questo bilancio andranno persi definitivamente.',
        eliminaBilancioDelAnno: 'Elimina il bilancio dell\'anno',
        eliminaBilancioButtone: 'Elimina bilancio',
    },

    BilancioTile: {
        bilancioDiSostenibilita: 'Bilancio di sostenibilità per l\'anno',
        rendiconta: 'rendiconta',
    },

    CanvasAttribuisciBusinessUnit: {
        nessunBusinessUnitTrovata: 'Nessuna business unit trovata',
        utilizzaIl: 'Utilizza il',
        moduloBusinessUnit: 'modulo Business Units',
        perCrearneDiNuovo: 'per crearne di nuove',
    },

    FiltroRicerca: {
        testoFiltroRicerca: "Cerca fattori per nome e descrizione"
    },

    FormFattoreBilancioBusinessUnit: {
        selezionaLebusinessUnit: 'Seleziona le business units che vuoi attribuire al fattore',
        perIlBilancio: 'per il bilancio del',
        testoBusinessUnitSelezionato: 'Ogni business unit selezionata e attribuita a questo fattore garantisce l\'accesso a cascata anche ai suoi sotto fattori.',
        nonSelezionata: 'Non selezionata',
        selezionata: 'Selezionata',
        conAcessoEridato: 'Con accesso ereditato',
        ricarica: 'Ricarica',
        businessUnitSelezionate: 'Business units selezionate',
        businessUnitPadriSelezionate: 'Business units padri selezionate',
        attribuisci: 'Attribuisci',
        businessUnitQestoFattore: 'business units a questo fattore',
    },

    statoAvanzamentoCompletamentoIndicatori: {
        indicatoriRendicontare: 'indicatori da rendicontare',
        indicatoriDiValidare: 'indicatori da validare',
        nonSonoAncoraRendicontatre: 'Non sono ancora stati rendicontati indicatori',
        rendicontati: 'rendicontati',
        validati: 'validati',
        nonSonoPresenti: 'Non sono presenti indicatori',
    },

    BloccoFattore: {
        obbietivo2030: 'Obiettivo agenda 2030',
        core: 'Core',
        businessUnit: 'Business units',
        collegate: 'collegate',
        nessunaBusinessUnitcollegata: 'Nessuna business unit collegata a questo fattore',
        collegaBusinessUnitABilancio: 'Collega business units',
        vaiAlModuloBUPerCollegareBusinessUnitABilancio: 'Vai al modulo "Attribuzione Business Units a bilanci" per collegare delle business units',
        fattori: 'fattori',
    },

    ModuloBilanciFattori: {
        fattoriBilancio: 'Fattori Bilancio',
        fattoriBilancioBu: 'Fattori Bilancio BU',
        materialitaBilancio: 'Attribuisci Materialità Bilancio'
    },

    BloccoFattoreDefaultTabella: {
        eliminatoSucessoTesto: 'Eliminata con successo',
        primaRiga: 'Crea prima riga',
        PrimaCollona: 'Crea prima colonna',
        tabellaEVuoto: 'Questa tabella è vuota',
        eliminaCollona: 'Sei sicuro di voler eliminare la colonna?',
        dellaTabella: 'dalla tabella',
        nessunaCollonaInserita: 'Nessuna colonna inserita',
        eliminaRiga: 'Sei sicuro di voler eliminare la riga?',
        nessunaRigaInserita: 'Nessuna riga inserita',
    },

    HoverFattoriBilancio: {
        nessunDescrizione: 'Nessuna descrizione',
    },

    BottoneAttribuisciFattore: {
        fattorePuoAggiunto: ' fattore può essere aggiunto',
        fattoreNonPuoAggiunto: ' fattori possono essere aggiunti',
    },

    BottoneAttribuisciStandard: {
        standardAttribuito: 'Standard attribuito con successo',
        verficatoUnErrore: 'Si è verificato un errore',
        scegliUnStandard: 'Scegli uno standard',
        proseguandoAttribuirai: 'Proseguendo attribuirai tutti i fattori presenti nello standard',
        bilancioDel: 'al bilancio del',
        nessunStandardTrovato: 'Nessuno standard trovato',
    },

    ListaFattoriDisattivi: {
        fattoreAttivoSucesso: 'Fattore attivato con successo nel bilancio',
        erroreDurante: 'Errore durante l\'attivazione del fattore',
        standardUtilizato: 'Lo standard utilizzato non prevede altri elementi attribuibili a questa categoria.',
    },

    bloccoFattoreBilancioContextMenu: {
        fattoreDisattivatoSucesso: 'Fattore disattivato con successo dal bilancio',
        disattivaFattore: 'Disattiva fattore',
        attribuisciBusinessUnitFattore: 'Attribuisci business unit',
        collegaTemiDiMaterialita: 'Collega temi di materialità',
    },

    bloccoFattoreContextMenu: {
        fattoreEliminatoSucesso: 'Fattore eliminato con successo',
        eliminaFattore: 'Sei sicuro di voler eliminare il fattore',
        eliminaFattoreTesto: 'Tutti i bilanci che utilizzano questo fattore lo perderanno insieme ad eventuali indicatori associati.',
        eliminaIlFattore: 'Elimina il fattore',
    },

    bloccoStandardContextMenu: {
        standardRimosoConSucesso: 'Standard rimosso con successo dal bilancio',
        eliminaStandard: 'Sei sicuro di voler rimuovere questo standard dal bilanco del',
        eliminaStandardTesto: 'Tutti i fattori verrano a loro volta eliminati.',
        rimouviLoStandard: 'Rimuovi lo standard',
    },

    canvasFattoriEsg: {
        vaiAllaCategoriaPadre: 'Vai alla categoria padre',
        attribuisciUnoStandardSulBilancio: 'Attribuisci uno standard al bilancio',
        vaiAlModuloAttribuzione: ' Vai al modulo "Attribuzione fattori a bilanci" per attribuire uno standard a questo bilancio',
        tornaAgliStandard: 'Torna alla lista degli standard'
    },

    formFattorEsg: {
        modificandoLinguaIn: 'Stai modificando questo fattore in: ',
        ilFattore: 'il fattore',
        modificaIDati: 'Modifica i dati per',
        delFattore: 'del fattore',
        stabilisciCodice: 'Stabilisci un codice, un nome e una descrizione per',
        fornisciUnCodice: 'Fornisci un codice per il fattore',
        placeholderFattore: 'es: gri_2016_12',
        fornisciUnNome: 'Fornisci un nome per il fattore',
        placeHolderNomeEsteso: 'es: Informativa Generale',
        placeholderDescrizionefattore: 'Descrizione approfondita',
        richiesto: ' Richiesto',
        determinaSeQuesto: 'Determina se questo è un fattore core, se spunti questa casella il fattore non potrà essere disattivato nei bilanci.',
        FattorecreatoConSucesso: 'creato con successo',
        specificaTipoIndicatore: 'Puoi specificare una tipologia di indicatore per limitare le possibilità di rendicontazione di questo fattore.',
        aggiungiRispostePossibili: 'Aggiungi le risposte possibili per rendicontare questo fattore, l\'utente potrà successivamente rendicontare sul set di valori che stabilisci in questa fase.',
        aggiungiRisposta: 'Aggiungi risposta',
        stabilisciRispostePossibili: 'Stabilisci il numero massimo di risposte possibili per questo fattore',
        defaultUM: 'Puoi specificare un\'unità di misura prestabilita per questo fattore ESG',
        specificaSelezioneUM: 'Lascia libero questo campo, oppure setta l\'opzione "Nessuna", per dare la possibilità di inserire qualsiasi unità di misura in fase di rendicontazione.'
    },

    GraficoBarreFattoreBilancio: {
        perAnnoConUnitaDiMisura: 'per anno con unità di misura',
        datiGrezzi: 'Dati grezzi',
        graficoLineare: 'Grafico lineare',
        graficoBarre: 'Grafico a barre',
        salvaComeImagine: 'Salva come immagine',
    },

    GraficoBarreTabellaBilanci: {
        laUnitaDiMisura: 'Le unità di misura utilizzate per rendicontare questa cella non risultano coerenti lungo gli anni, per questo i dati indicati in questo grafico non possono essere ritenuti consistenti.',
        unitaDimisuraInconsistente: 'Unità di misura inconsistente',
        nessunDatoRendicontazione: 'Nessun dato rendicontato nei bilanci per la colonna:',
        nellaRiga: 'nella riga:',
    },

    IndicatoriSchedaTabellaESG: {
        NonRendicontato: 'Questa cella non è ancora stata rendicontata in nessun bilancio'
    },

    standardEsgContextMenu: {
        standardEliminatoSuccesso: 'Standard eliminato con successo',
        eliminaStandardEsg: 'Sei sicuro di voler eliminare lo standard',
        eliminaStandardEsgTesto: 'Questa azione è irreversibile. Tutti i dati dei bilanci associati a questo standard andranno persi definitivamente.',
        eliminaLoStandard: 'Elimina lo standard',
        modificaStandard: 'Modifica standard',
    },

    FormIndicatore: {
        giaStatoValidato: 'Questo indicatore è già stato validato',
        indicatore: 'L\'indicatore',
        nelBilancioDel: 'nel bilancio del',
        perLaBusinessUnit: 'per la business unit',
        seguente: 'è il seguente:',
        placeholderValore: '0.2, 2, 22',
        placeholderUm: 'UM',
        Testo: 'Testo',
        placeholderTesto: 'Inserisci un testo',
        note: 'Note',
        placeholderNote: 'Non sono state inserite note',
        rimuviValidizioneIndicatori: 'Rimuovi validazione indicatore',
        nonHaiIlPrivellagio: 'Non hai il privilegio necessario per interagire con questo indicatore',
        attribuisciIndicatore: ' Attribuisci un indicatore a',
        valoreNumerico: 'Valore numerico',
        valoreTestuale: 'Valore testuale',
        valoreBooleano: 'Si / No',
        valoreRispostaMultipla: 'Risposta multipla',
        placeholderNoteAltra: 'Inserisci delle note facoltative',
    },

    FormIndicatoreTabella: {
        indicatoreEliminatoSucesso: 'Indicatore eliminato con successo',
        indicatoreValidatoSucesso: 'Indicatore validato con successo',
        Datistrutturati: 'Dati strutturati',
        statistiche: 'statistiche',
        statistichePerRiga: 'Statistiche per riga',
        minimo: 'Minimo',
        media: 'Media',
        massimo: 'Massimo',
        somma: 'Somma',
        indicatoreValidato: 'Indicatore validato',
        modificaIndicatore: 'Modifica indicatore',
        validaIndicatore: 'Valida indicatore',
        eliminaIndicatore: 'Elimina indicatore',
        rimouviValidazione: 'Rimuovi validazione indicatore',
        perCollona: 'per colonna',
        Inconsistenti: 'inconsistenti',
        unitaDiMisura: 'Unità di misura',
    },

    InformativeFattoriEsg: {
        SchedaInformativa: 'Scheda informativa',
        listaObiettivi: 'Lista Obiettivi dell\'Agenda 2030 collegati a questo fattore:',
        nessunaDescrizione: 'Nessuna descrizione disponibile per questo fattore ESG',
    },

    SchedaCategoria: {
        TabellePresenti: 'Tabelle presenti',
        listaTuttiTabelle: 'Lista di tutte le tabelle presenti.',
    },

    SchedaFattoreEsg: {
        questoFattoreRendicontato: 'Questo fattore può essere rendicontato per',
        questoFattoreNonCollegato: 'Questo fattore non è collegato a nessuna business unit, quindi non può essere rendicontato.',
        businessUnit: 'business units.',
    },

    BoxMaterialitaBilanco: {
        MatriceDiMaterialita: 'Matrice di materialità:',
        mattriceDellaMaterialita: 'La matrice di materialità permette a un’azienda di individuare gli aspetti “materiali” legati alla sostenibilità più rilevanti per l’azienda e per i suoi stakeholder. Il termine materialità sottolinea la concretezza e la misurabilità di tutti gli elementi che mostrano l’impegno di un’azienda a essere sostenibile e che possono essere comunicabili.',
        aggiungiNuovaTemi: 'Aggiungi un nuovo tema',
        vissulizaListatemi: 'Visualizza lista dei temi di materialità',
        vissulizaMatrice: 'Visualizza matrice',
        nessunTemiApplicato: 'Nessun tema di materialità applicato a questo bilancio',
        applicailPrimaTemo: 'Applica il primo tema',
        vaiAlModulo: 'Vai al modulo "Attribuzione temi di materialità ai bilanci" per applicarli'
    },

    GraficoMaterialitaBilancio: {
        valorePerGliStakeholder: 'Valore per gli stakeholder',
        valorePerAzienda: 'Valore per l\'azienda',
    },

    TemaMaterialitaBilancioContextMenu: {
        scollegatoSucesso: 'Il Tema di Materialità è stato scollegato con successo dal bilancio.',
        modificaParametri: 'Modifica Parametri',
        scollegaTemiMaterialita: 'Sei sicuro di voler scollegare il Tema di Materialità',
        daQuestoBilancio: 'da questo bilancio?.',
        scollegaIlTemaMaterialita: 'Scollega il Tema di Materialità',
        scollegaDalBilancio: 'Scollega dal bilancio',
    },

    FormTemaMaterialitaBilancio: {
        ModificaMaterilita: 'Modifica il Tema di Materialità',
        applicatoAquestoBilancio: 'applicato a questo bilancio',
        applicaUnatema: 'Applica un Tema di Materialità al bilancio del',
        modificaIDati: 'Modifica i dati per questo tema di materialità nel bilancio',
        scgliUnaTema: 'Scegli un tema, stabilisci una sua descrizione per questo bilancio e una rilevanza.',
        temaDiMaterialita: 'Tema di materialità',
        scegliUnatemaMaterialita: 'Scegli un tema di materialità',
        RilevanzaPerlazienda: 'Rilevanza per l\'azienda',
        fornisciUnValore: 'Fornisci un valore di rilevanza per l\'azienda',
        RilevanzaPerStakeholder: 'Rilevanza per gli stakeholder',
        fornisciValorePerStakeHolder: 'Fornisci un valore di rilevanza per gli stakeholder',
        modifciatemaDiMaterialita: 'Modifica tema di materialità',
        applicaTemaDiMaterialita: 'Applica tema di materialità',
        applicatoSucesso: 'Tema di materialità applicato con successo',
    },

    TileTemaMaterialitaBilancio: {
        RilevanzaAzienda: 'Rilevanza azienda',
        RilevanzaStakeholder: 'Rilevanza stakeholder',
    },

    FormtemaMaterialitaFattoreBilancio: {
        applicaTemaMaterilita: 'Applica dei temi di materialità a questo fattore',
        selezioneUnoOPiuTemi: 'Seleziona uno o più temi di materialità da collegare al fattore',
        temi: 'Temi',
        selezioneTemi: 'Seleziona temi',
        tuttiIselezionati: 'Tutti i temi selezionati',
        selezionaTutti: 'Seleziona tutti',
        collegaTemiAFattore: 'Collega temi di materialità al fattore',
        temiCollegatiSucesso: 'Temi collegati con successo al fattore',
    },

    ArchivioRendicontazioneBilancio: {
        nonHaiAncoraAcesso: 'Non hai ancora accesso a nessun bilancio, contatta un amministratore per richiedere maggiori informazioni.',
    },

    FormRendicontazioneIndicatotre: {
        giaValidato: 'Questo indicatore è già stato validato',
        IndicatoreAttribuitoSucesso: 'Indicatore attribuito con successo',
    },

    FormRendicontazioneIndicatori: {
        validazionrRomiso: 'Validazione rimossa con successo',
    },

    SchedaRendicontazioneBilancio: {
        schedaDirendicontazione: 'Scheda di rendicontazione per il bilancio del',
        inQuestoSezione: 'In questa sezione è possibile rendicontare tutti i fattori ESG ai quali hai accesso.',
        fattoriDisponibile: 'Fattori disponibili per la business unit:',
        puoiRendicontare: 'Puoi rendicontare',
        fattoriInQuestoBusinessUnit: 'in questa business unit con un accesso di livello:',
        Consultazione: ' Consultazione',
        Raccolta: ' Raccolta',
        Validazione: ' Validazione',
        Gestione: ' Gestione',
        caricandoFattoriESG: 'Caricando i fattori ESG che puoi rendicontare...'
    },

    TileRendicontazioneFattore: {
        statoAvanzamentoRendicontazione: 'Stato avanzamento rendicontazione',
        nonRendicontato: 'Non rendicontato',
        rendicontato: 'Rendicontato',
        validato: 'Validato',
        fattoreRendicontatoLiberamente: 'Questo fattore può essere rendicontato liberamente, tramite valore testuale, numerico o si / no.',
        tipologiaRendicontazioneRichiesta: 'Tipologia di rendicontazione richiesta:'
    },

    IndicatoreFattoreBusinessUnit: {
        nessunDescrizione: 'Nessuna descrizione disponibile per questa business unit',
    },

    SchedaBilancio: {
        tornaAllArchivio: 'Torna all\'archivio dei bilanci',
        BilancioSostenibilita: 'Bilancio di sostenibilità del',
        vaiAllaSchedaCompleta: 'Passa alla scheda completa del bilancio del',
        bilancio: 'Bilancio'
    },

    NavbarSchedaBilancio: {
        IndiceContenuti: 'Indice dei contenuti',
        TemiMaterialita: 'Temi di materialità',
        ObiettiviAgenda2030: 'Obiettivi agenda 2030',
        Gruppi: 'Gruppi'
    },

    DashboardSchedaBilancio: {
        StandardApplicati: 'Standard applicati al bilancio',
        Fattori: 'Fattori nel bilancio',
        FattoriRendicontabili: 'Fattori rendicontabili nel bilancio',
        TabelleRendicontabili: 'Tabelle rendicontabili nel bilancio',
        TemiMaterialita: 'Temi di materialità applicati al bilancio',
        Obiettivi2030: 'Obiettivi dell\'agenda 2030 presi in considerazione nel bilancio',
        BusinessUnits: 'Business Units hanno accesso al bilancio',
        Gruppi: 'Gruppi utenti hanno accesso al bilancio',
        analisi: {
            tabs: {
                anno_tema_indicatori_bu: 'Riepilogo per anno, tema e business unit',
                anno_tema: 'Riepilogo per anno e tema',
                tema_indicatori_bu: 'Riepilogo per tema e business unit',
                tema_bu: 'Riepilogo per business unit',
            },
            titles: {
                anno_tema_indicatori_bu: 'Riepilogo per anno, tema, e business unit',
                anno_tema: 'Riepilogo per anno e tema',
                tema_indicatori_bu: 'Riepilogo per tema e business unit',
                tema_bu: 'Riepilogo per business unit',
            },
            anno: 'Anno',
            tema: 'Tema',
            indicatore_id: 'Indicatore',
            business_unit: 'Business unit',
            codice_padre: 'Fattore',
            fattore_codice: 'Fattore - Dettaglio',
            fattore_nome_tradotto: 'Fattore - Nome',
            valore_numerico: 'Valore',
            unita_misura: 'Unità di misura',
        }
    },

    SchedafattoreEsgBilancio: {
        temiDiMaterialitaCollegati: 'temi di materialità collegati a questo fattore per il bilancio del',
    },

    SchedaTemaMaterialitaBilancio: {
        fattoreCollegati: 'fattori collegati a questo tema nel bilancio del',
        nessunFattoreESGAssociato: 'Nessun fattore ESG associato a questo tema di materialità per il bilancio del',
        associarefattori: 'Per associare dei fattori a questo tema di materialità:',
        collegaStandard: 'Collega uno standard a un bilancio dal modulo',
        AttribuzioneFattori: 'Attribuzione fattori a bilanci',
        utilizzaIlMenu: 'Utilizza il menù contestuale presente nell\'alberatura dei fattori ESG dal modulo',
        attribuzioneTemi: 'Attribuzione temi di materialità a bilanci',
        premendoVoce: 'premendo sulla voce "collega temi di materialità"',
        selezioneDalForm: 'Seleziona dal form tutti i temi che vuoi collegare al fattore',
    },

    ObiettivoAgenda2030ContextMenu: {
        obiettivoEliminatoSucesso: 'L\'obiettivo è stato eliminato con successo.',
        modificaObiettivo: 'Modifica obiettivo',
        eliminaObiettivo: 'Sei sicuro di voler eliminare l\'obiettivo',
        QuestaIrreversibile: 'Questa azione è irreversibile.',
        eliminaObiettivoTesto: 'Elimina l\'obiettivo',
    },

    FormObiettivoAgenda2030: {
        ModificaObiettivo: 'Modifica l\'obiettivo',
        creaObiettivo: 'Crea un obiettivo per l\'agenda 2030',
        modifcaIDati: 'Modifica i dati per',
        dellObietivo: 'dell\'obiettivo',
        StabilisciUnCodice: 'Stabilisci un codice, un nome e una descrizione per questo obiettivo.',
        id: 'Id',
        fornisciObiettivo: 'Fornisci un id per l\'obiettivo dell\'agenda 2030',
        placeholderObiettivo: '"es: 1, 2, 3"',
        placeholderNomeObiettivo: 'es: Nome obiettivo',
        fornisciNomePerObiettivo: 'Fornisci un nome per l\'obiettivo',
        placeholderNomeestesoObiettivo: '"es: Nome obiettivo esteso"',
        nomeImagine: 'Nome dell\'immagine',
        colorEtichetta: 'Colore etichetta',
    },

    ArchivioObiettiviAgenda2030: {
        creaNuovoObiettivo: 'Crea un nuovo obiettivo per l\'agenda 2030',
        nessunObittivioPresente: 'Nessun obiettivo presente',
        ruota: 'Ruota',
        lista: 'Lista',
    },

    SchedaObiettivoAgenda2030: {
        TornaAllaLista: 'Torna alla lista degli obiettivi',
        NonePossibileDescrizione: 'Non è disponibile nessuna descrizione per questo obiettivo',
    },

    CanvasBloccoApplicaObiettiviFattori: {
        scollegareObiettivio: 'Sei sicuro di voler scollegare l\'obiettivo',
        dalFattore: 'dal fattore',
        conferma: 'Conferma',
        obbietivo: 'Obiettivo',
        romosoSuccesso: 'rimosso con successo dal fattore'
    },

    CanvasApplicaObiettiviAgenda2030: {
        premiFattori: 'Premi sui fattori ESG per selezionarli, potrai successivamente attribuire gli obiettivi dell\'agenda 2030.',
    },

    FormAggiungiObiettiviAgenda2030Fattori: {
        fattoriSelezionati: 'Fattori selezionati',
        gliObbietviDellaAzeinda: 'gli Obiettivi dell\'Agenda 2030 da aggiungere a',
        obiettivi: 'obiettivi a',
    },

    TemaMaterialitaContextMenu: {
        TemaEliminatoSucesso: 'Il Tema di Materialità è stato eliminato con successo.',
        eliminaTema: 'Sei sicuro di voler eliminare il Tema di Materialità ',
        eliminaTemaMaterialita: 'Elimina il Tema di Materialità',
    },

    FormTemaMaterialita: {
        creaTemaMaterialita: 'Crea un Tema di Materialità',
        delTema: 'del tema',
        fornisciCodice: 'Fornisci un codice per il tema di materialità',
        placeholderTemaMaterilita: 'es: Tema di Materialità',
        fornisciNomeTema: 'Fornisci un nome per il tema di materialità',
    },

    ArchivioMaterialita: {
        nessunTemaPersente: 'Nessun Tema di Materialità presente',
        creaMaterialita: 'Crea un nuovo Tema di Materialità',
    },

    InformativaMaterialita: {
        nessunDescrizioneDisponibile: 'Nessuna descrizione disponibile per questo Tema di Materialità'
    },

    schedaBilancioTemaMaterialita: {
        tornaAlBilancio: 'Torna alla lista dei bilanci',
        listaDeiFattori: 'Lista dei fattori ESG applicati al bilancio del',
        perTema: 'per il tema di materialità',
        nonCiSonoFattori: 'Non ci sono fattori ESG collegati a questo tema nel bilancio del',
    },

    SchedaTemaMaterialita: {
        tornaAllaLista: 'Torna alla lista dei temi di materialità'
    },

    ArchivioStandardEsg: {
        nessunStandardPresente: 'Nessuno standard presente',
        creaNuovoStandard: 'Crea un nuovo nuovo standard',
    }
} 