import React from 'react'
import ReactEcharts from "echarts-for-react"

const GraficoResocontoAnnuale: React.FC<{ resocontoAnnuale: any[] }> = ({resocontoAnnuale}) => {

    let emissioniCombustibile = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_combustibile, name: 'Combustibile'}
    let emissioniGas = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_gas_refrigerante, name: 'Gas Refrigeranti'}
    let emissioniVeicoli = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_veicoli, name: 'Veicoli'}
    let emissioniElettricita = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_elettricita, name: 'Elettricità'}
    let emissioniUpstream = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_upstream_transport, name: 'Upstream Transport'}
    let emissioniWaste = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_waste, name: 'Waste'}
    let emissioniWastewater = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_wastewater, name: 'Wastewater'}
    let emissioniOther = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_other_disposal, name: 'Other Disposal'}
    let emissioniBusinessTravelHotel = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_business_travel_hotel, name: 'Business Travel by Hotel'}
    let emissioniBusinessTravelMode = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_business_travel_mode, name: 'Business Travel by Mode'}
    let emissioniEmployeeCommuting = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_employee_commuting, name: 'Employee Commuting'}
    let emissioniUseProducts = {value: resocontoAnnuale[0].emissione_annuale_t_co2e_use_of_sold_products, name: 'Use of Sold Products'}

    let dati: any[] = []

    if (emissioniCombustibile.value > 0) { dati.push(emissioniCombustibile) }
    if (emissioniGas.value > 0) { dati.push(emissioniGas) }
    if (emissioniVeicoli.value > 0) { dati.push(emissioniVeicoli) }
    if (emissioniElettricita.value > 0) { dati.push(emissioniElettricita) }
    if (emissioniUpstream.value > 0) { dati.push(emissioniUpstream) }
    if (emissioniWaste.value > 0) { dati.push(emissioniWaste) }
    if (emissioniWastewater.value > 0) { dati.push(emissioniWastewater) }
    if (emissioniOther.value > 0) { dati.push(emissioniOther) }
    if (emissioniBusinessTravelHotel.value > 0) { dati.push(emissioniBusinessTravelHotel) }
    if (emissioniBusinessTravelMode.value > 0) { dati.push(emissioniBusinessTravelMode) }
    if (emissioniEmployeeCommuting.value > 0) { dati.push(emissioniEmployeeCommuting) }
    if (emissioniUseProducts.value > 0) { dati.push(emissioniUseProducts) }

    let option = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                type: 'pie',
                radius: ['36%', '50%'],
                avoidLabelOverlap: false,
                label: {
                    formatter: function (params: any) {
                        return `${params.name} (${params.value} t / co2e)`
                    }
                },
                tooltip: {
                    formatter: '{b0}<br/><b>{d} %</b>'
                    // formatter: function (params: { percent: number }) {
                    //     return `${params.percent.toFixed(6)} %`
                    // }
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 2
                },
                emphasis: {
                    label: {
                        fontSize: 14,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: dati,
                // roseType: 'radius', // ['area','radius']
                percentPrecision: 6
            }
        ]
    };

    return (
        <div>
            <ReactEcharts style={{minHeight: 600}} option={option}/>
        </div>
    )
}


export default GraficoResocontoAnnuale;