import React, {useState} from 'react'

//Data
import {useForm} from 'react-hook-form';
import {useStores} from '../../../../../../hooks/useStores';
import {crea, modifica} from '../../../../../../rest/crud_generica';

//Components
import ErrorBox from '../../../../../../components/common/ErrorBox';
import InputField from '../../../../../../components/common/form/InputField';
import InfoBox from '../../../../../../components/common/InfoBox';
import SpinnerButton from '../../../../../../components/common/SpinnerButton';
import {ITravelMode} from '../../../../types';

const TravelModeForm: React.FC<{ travelmode?: ITravelMode }> = ({travelmode}) => {
    const {ui} = useStores()
    const [attesa, setAttesa] = useState<boolean>(false)
    const [successo, setSuccesso] = useState<string>('')
    const [errore, setErrore] = useState<string>('')

    const {register, handleSubmit, watch, control, setValue, formState: {errors, isSubmitSuccessful}} = useForm({
        defaultValues: {
            nome: travelmode ? travelmode.nome : '',
            descrizione: travelmode ? travelmode.descrizione : '',
        }
    });

    const onSubmit = () => {
        setAttesa(true)
        travelmode
            ?
            modifica(`carbon_footprint/travel_mode_employee_commuting/${travelmode.nome}`, {
                nome: watch('nome'),
                descrizione: watch('descrizione'),
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Modalità viaggio modificata con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
            :
            crea(`carbon_footprint/travel_mode_employee_commuting`, {
                nome: watch('nome'),
                descrizione: watch('descrizione'),
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Modalità viaggio creato con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
    }

    return (
        <div>
            <div>
                {travelmode
                    ? <h2 className="text-2xl mb-2">Modifica la Modalità di viaggio {travelmode.nome}</h2>
                    : <h2 className="text-2xl mb-2">Crea una nuova Modalità di viaggio</h2>
                }
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>

                <InputField
                    label="Nome"
                    type="text"
                    error={errors.nome}
                    form={register("nome", {required: "Il nome è richiesto"})}
                    placeholder="Nome"
                    disabled={!!travelmode}
                />

                <InputField
                    label="Descrizione"
                    type="textarea"
                    error={errors.descrizione}
                    form={register("descrizione")}
                    placeholder="Descrizione"
                />

                {
                    !attesa
                        ? <input type="submit" value={travelmode ? 'Salva modifiche' : 'Crea Modalità di Viaggio'}/>
                        : <SpinnerButton/>
                }

                {(errore || successo) &&
                    <div className="mt-2">
                        {errore && <ErrorBox errore={errore}/>}
                        {successo && <InfoBox messaggio={successo}/>}
                    </div>
                }

            </form>
        </div>
    )
}

export default TravelModeForm;