import { en_modulo_gestione_lingue } from "../moduli/gestioneLingue/locales/en"
import { en_modulo_gestione_moduli } from "../moduli/gestioneModuli/locales/en"
import { en_modulo_gestione_privilegi } from "../moduli/gestionePrivilegi/locales/en"
import { en_modulo_gestione_unita_misura } from "../moduli/gestioneUnitaMisura/locales/en"
import { en_modulo_gestione_utenti } from "../moduli/gestioneUtenti/locales/en"
import { en_modulo_notizie } from "../moduli/notizie/locales/en"
import { en_business_unit } from "../moduli/UiModuloBusinessUnit/locales/en"
import { en_dashboard } from "../moduli/UiModuloDashboard/locales/en"
import { en_fattori_esg } from "../moduli/UiModuloFattoriESG/locales/en"
import { en_carbon_footprint, en_modulo_carbon_footprint } from "../moduli/UiModuloCarbonFootprint/en"

export const en = {

  common: {
    ciao: 'Hi',
    salva: 'save',
    modifica: 'Edit',
    annulla: 'cancel',
    chiudi: 'close',
    conferma: 'confirm',
    crea: 'create',
    rimani: 'stay',
    richiediAccesso: 'Request an account',
    tornaLogin: 'Go back to login',
    password: 'Password',
    fornisciPassword: 'Insert a password',
    ripetiLaPassword: 'Repeat the password',
    nuovaPassword: 'New password',
    nome: 'Name',
    fornisciNome: 'Name required',
    cognome: 'Last name',
    fornisciCognome: 'Last name required',
    eMail: 'E-mail',
    fornisciEmail: 'E-mail required',
    azienda: 'Company',
    specificaAzienda: 'Company required',
    telefono: 'Phone number',
    fornisciTelefone: 'Phone number required',
    telefonoMessagio: 'It must be a valid phone number',
    login: 'Login',
    codice: 'Code',
    fornisciCodice: 'Code required',
    descrizione: 'Description',
    descrizioneEstesa: 'Extended Description',
    fornisciDescrizione: 'Description required',
    attivo: 'active',
    disattivo: 'not active',
    valore: 'Value',
    fornisciValore: 'Value required',
    salvaModifica: 'Save changes',
    modificaSalvato: 'Successfully saved',
    elimina: 'Delete',
    aggiungiGruppo: 'Add group',
    cerca: 'Search',
    gruppo: 'Group',
    gruppi: 'Groups',
    unitaMisura: 'Unit of measurement',
    categoria: 'Category',
    categorie: 'Categories',
    fattore: 'Topic',
    fattori: 'Topics',
    tabella: 'Table',
    tabelle: 'Tables',
    aggiungi: 'Add',
    filtraFattori: 'Filter topics',
    nomeEsteso: 'Full Name',
    tipo: 'Type',
    tipo_indicatore: 'Value type',
    indicatoriNumerici: 'Numeric value',
    indicatoriTestuali: 'Text value',
    vaiAllaTabella: 'Go to the table',
    businessUnit: 'Business Unit',
    anno: 'Year',
    perAnno: 'by year',
    perBusinessUnit: 'by business unit',
    conUnitaMisura: 'with measurement unit ',
    nascondi: 'Hide',
    mostra: 'Show',
    scegli: 'Choose',
    bandiera: 'flag',
    prodottoDi: 'is a product from',
    aggiungiNuovoBilancio: 'Add new sustainability report',
    statistiche: 'Statistics',
    analisi: 'Analysis',
    disclaimerModificheNonSalvate: 'You will lose any unsaved changes, are you sure?',
    si: 'Yes',
    no: 'No',
    lingua: 'Language',
    cambiaLingua: 'Change language',
    mostraTutti: 'Show all',
    nonRendicontati: 'Not reported on yet',
    rendicontati: 'Reported',
    validati: 'Validated',
    statoRendicontazione: 'Reporting status',
    filtra: 'Filter',
    selezionaValore: 'Select a value',
    nessunFattoreTrovato: 'No topic found',
    paese: 'Country',
    posizione_geografica: 'Location',
    a: 'to',
    quantita: 'Quantity',
    erroreGenerico: 'Generic error',
    nessuna: 'None',
    profilo: 'Profile',
    attivita: 'Activities',
    facoltativo: 'Optional',
    scarica: 'Download',
    passwordRegex: 'The password must be at least 12 characters, have at least a number, an uppercase letter, a lowercase letter and a special character.',
    valida: 'Validate',
    rimuoviValidazione: 'Remove validation',
    contenutiMultilingua: 'This content can be created and read in multiple languages.',
    dateFormat: 'DD MMMM, YYYY h:mm A',
    fileObbligatorio: 'File required',
    revisione:'File Version',
    filename: 'File Name',
    compilatore: 'Author',
    note: 'Notes',
    transportMethod: 'Transport Method'
  },

  tabellaInterattiva: {
    elementiPerPagina: 'Items per page',
    elementiDa: 'Items from',
    visualizzatiSu: 'displayed out of',
    paginaSuccessiva: 'Next page',
    paginaPrecedente: 'Previous page',
    filtraTabella: 'Filter results',
    nessunDatoPresente: 'No data found',
    mostraPiu: 'Show more',
    mostraMeno: 'Show less',
  },

  paginaLogin: {
    accessAccount: 'Access your account',
    messaggioLoginGenerale: 'It\'s great to see you back on Ipè!',
    dimenticatoPassword: 'Forgot your password?'
  },

  paginaSignup: {
    messaggioTesta: 'We manually verify and approve  the identity of all of the users who desire to access this workspace. Please enter your info to proceed with the request.',
    giaAccount: 'Do you already have an account? ',
    effettuaLogin: 'Go back to login',
  },

  PaginaCompleteAccount: {
    completeAccount: 'Complete your account',
    richiestaAcettata: 'Your subscription request to this Ipè workspace has been approved! Complete the form with the required info to access your account.',
  },
  paginaAggiornaContatti: {
    titolo: 'Confirm your contact details',
    sottotitolo: 'Your contact details have been verified. Please login with your new e-mail.',
  },

  PaginaContatti: {
    vegaParco: 'VEGA Parco Scientifico e Tecnologico',
    torreHammon: 'Hammon Tower',
    indirizzo: ' Via delle Industrie 5, Marghera (Venezia)',
    testoTel: 'Phone:',
    numero: '+39 041 5093820',
    testoEmail: 'E-mail:',
    email: 'info@eambientegroup.com',
  },

  PaginaImpostaPassword: {
    impostaNuovaPassword: 'Create a new password',
  },

  Pagina404: {
    errore404: 'Error 404',
    paginaNonEsiste: 'This page does not exists',
    tornaIndietro: 'Go back',
    tornaPaginaPricipale: 'Go back to the main page',
  },

  PaginaProfilo: {
    titolo: 'Profile',
    descrizione: 'Manage your personal profile and public info',
    abilitaDebug: 'Enable debug',
    disabilitaDebug: 'Disable debug',
    abilitaBenvenuto: 'Enable welcome box',
    disabilitaBenvenuto: 'Disable welcome box',
  },

  PaginaAttivita: {
    titolo: 'Activities',
    descrizione: 'History of your activities in Ipè',
    nessunaAttivitaTrovata: 'No activities found for your account',
  },

  PaginaResetPassword: {
    richiediPassword: 'Request a password reset',
    mesaggioTesto: 'You can easily recover your account. Please insert your e-mail to receive the link which you can use to reset your password.',

  },

  FormCompleteAccount: {
    passwordNOnCoincidono: 'Passwords do not match',
    emailVerificatoConSucesso: 'E-mail succesfully verified',
    ripetiLaPassword: 'Repeat Password',
    mesaggioSucessco: 'User successfully activated',
    vailalLogin: 'Go to login',
    erroreNellImpostazione: 'Error during password reset',
    attivaAccediAccount: 'Activate and access your account',
  },

  FormImpostaNuovaPassword: {
    messaggioSucesso: 'Password successfully updated',
  },

  FormIscrizione: {
    messaggioDati: 'I have read and consent to the processing of my personal data according to the ',
    testoLink: 'data policy statement',
    messaggioSuccesso: 'Thank you for requesting access to Ipé, you will receive an e-mail when your account is enabled by an admin',
    deviAcconsentire: 'You must accept the data policy statement'
  },

  FormResetPassword: {
    messaggioInviaEmail: 'We sent you an e-mail with a link to reset your password.',
    recuperaPassword: 'Recover your password',
  },

  FormCambiaEmail: {
    testoNonPossibileModificare: 'It is not currently possible to change your e-mail.',
    testCambioEmail: 'Change e-mail',
    messagioInviatoeamil: 'We sent you an e-mail to the new address with a link to confirm the change.',
    richiestaInviata: 'Request sent',
    richiediCambiaEmail: 'Require e-mail change',
  },

  FormCambiaPassword: {
    testoCambiaPassword: 'Change password',
    passwordAttuale: 'Current password',
    inserisciPasswordAttulae: 'Insert your current password',
    inserisciNuovaPassword: 'Insert the new password',
    ripetiNuovaPassword: 'Repeat the new password',
    passwordAggiornata: 'Password updated',
    aggiornaPassword: 'Update Password',
  },

  FormModificaProfilio: {
    messaggioAdmin: 'Only admins can edit users\' personal data.',
    infoPersonali: 'Personal data',
  },

  FormModificaLingua: {
    titolo: 'Language preferences',
    bottone: 'Update language',
  },

  ListaModuli: {
    listaDeiModuli: 'List of modules in Ipè that you have access to',
  },
  
  PaginaAzureAuthenticazione:{
    verificazione: 'We are verifying you credentials to perform authentication.'
  },

  ModuloGestioneUtenti: en_modulo_gestione_utenti,
  ModuloGestioneModuli: en_modulo_gestione_moduli,
  ModuloGestionePrivilegi: en_modulo_gestione_privilegi,
  ModuloGestioneUnitaMisura: en_modulo_gestione_unita_misura,
  ModuloGestioneLingue: en_modulo_gestione_lingue,
  ModuloNotizie: en_modulo_notizie,
  ModuloDashboard: en_dashboard,
  ModuloBusinessUnit: en_business_unit,
  ModuloFattoriEsg: en_fattori_esg,
  ModuloCarbonFootprint: en_modulo_carbon_footprint,
}