import React from 'react'
import ReactEcharts from "echarts-for-react"
import {IAnalisiCarbonFootprintTotale} from "../../types";

const GraficoResocontoGenerale: React.FC<{
    resocontoGenerale: IAnalisiCarbonFootprintTotale[]
}> = ({resocontoGenerale}) => {

    let nomiSeries = [
        'Impianti stazionari',
        'Gas Refrigeranti',
        'Veicoli',
        'Elettricità',
        'Upstream Transport',
        'Waste',
        'Wastewater',
        'Other Disposal',
        'Business Travel by Hotel',
        'Business Travel by Mode',
        'Employee Commuting',
        'Use of Sold Products',
    ]
    let series: any[] = []
    let setBu: Set<string> = new Set('')

    resocontoGenerale.forEach(item => setBu.add(item.business_unit))
    const listaBu = Array.from(setBu)

    type datiEmissione = {
        business_unit: string
        anno: number
        valore: number
    }

    let datiCombustibile: datiEmissione[] = []
    let datiGas: datiEmissione[] = []
    let datiVeicoli: datiEmissione[] = []
    let datiElettricita: datiEmissione[] = []
    let datiUpstream: datiEmissione[] = []
    let datiWaste: datiEmissione[] = []
    let datiWastewater: datiEmissione[] = []
    let datiOther: datiEmissione[] = []
    let datiBusinessTravelHotel: datiEmissione[] = []
    let datiBusinessTravelMode: datiEmissione[] = []
    let datiEmployeeCommuting: datiEmissione[] = []
    let datiUseProducts: datiEmissione[] = []

    resocontoGenerale.forEach((item) => {

        datiCombustibile.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_combustibile * 100) / 100, // arrotonda a 2 cifre decimali
        })

        datiGas.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_gas_refrigerante * 100) / 100,
        })

        datiVeicoli.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_veicoli * 100) / 100,
        })

        datiElettricita.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_elettricita * 100) / 100,
        })

        datiUpstream.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_upstream_transport * 100) / 100,
        })

        datiWaste.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_waste * 100) / 100,
        })

        datiWastewater.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_wastewater * 100) / 100,
        })

        datiOther.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_other_disposal * 100) / 100,
        })

        datiBusinessTravelHotel.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_business_travel_hotel * 100) / 100,
        })

        datiBusinessTravelMode.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_business_travel_mode * 100) / 100,
        })

        datiEmployeeCommuting.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_employee_commuting * 100) / 100,
        })

        datiUseProducts.push({
            business_unit: item.business_unit,
            anno: item.anno,
            valore: Math.floor(item.emissione_totale_t_co2e_use_of_sold_products * 100) / 100,
        })

    })

    let datiCombustibile_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiCombustibile.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiCombustibile_sum[idx] += valore
    })

    let datiGas_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiGas.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiGas_sum[idx] += valore
    })

    let datiVeicoli_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiVeicoli.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiVeicoli_sum[idx] += valore
    })

    let datiElettricita_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiElettricita.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiElettricita_sum[idx] += valore
    })

    let datiUpstream_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiUpstream.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiUpstream_sum[idx] += valore
    })

    let datiWaste_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiWaste.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiWaste_sum[idx] += valore
    })

    let datiWastewater_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiWastewater.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiWastewater_sum[idx] += valore
    })

    let datiOther_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiOther.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiOther_sum[idx] += valore
    })

    let datiBusinessTravelHotel_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiBusinessTravelHotel.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiBusinessTravelHotel_sum[idx] += valore
    })

    let datiBusinessTravelMode_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiBusinessTravelMode.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiBusinessTravelMode_sum[idx] += valore
    })

    let datiEmployeeCommuting_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiEmployeeCommuting.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiEmployeeCommuting_sum[idx] += valore
    })

    let datiUseProducts_sum: number[] = new Array<number>(listaBu.length).fill(0)
    datiUseProducts.forEach(item => {
        const {business_unit, valore} = item
        let idx = listaBu.indexOf(business_unit)
        datiUseProducts_sum[idx] += valore
    })


    nomiSeries.forEach(nomeSerie => {

        let data: number[] = []
        let color = ''

        /* Colori assegnati in base alla rosa dei colori primari */
        switch (nomeSerie) {
            case ('Impianti stazionari'):
                data = datiCombustibile_sum
                // color = '#EDAA25' // colore originale, quando c'erano solo gli scope 1 e 2
                color = '#ffea00'
                break;

            case ('Gas Refrigeranti'):
                data = datiGas_sum
                // color = '#C97E3C' // colore originale, quando c'erano solo gli scope 1 e 2
                color = '#fec400'
                break;

            case ('Veicoli'):
                data = datiVeicoli_sum
                // color = '#DB3A23' // colore originale, quando c'erano solo gli scope 1 e 2
                color = '#ff9501'
                break;

            case ('Elettricità'):
                data = datiElettricita_sum
                // color = '#025259' // colore originale, quando c'erano solo gli scope 1 e 2
                color = '#fe5e00'
                break;

            case ('Upstream Transport'):
                data = datiUpstream_sum
                color = '#ef1d1c'
                break;

            case ('Waste'):
                data = datiWaste_sum
                color = '#a01f93'
                break;

            case ('Wastewater'):
                data = datiWastewater_sum
                color = '#641fa0'
                break;

            case ('Other Disposal'):
                data = datiOther_sum
                color = '#5d26c2'
                break;

            case ('Business Travel by Hotel'):
                data = datiBusinessTravelHotel_sum
                color = '#1e44ff'
                break;

            case ('Business Travel by Mode'):
                data = datiBusinessTravelMode_sum
                color = '#029084'
                break;

            case ('Employee Commuting'):
                data = datiEmployeeCommuting_sum
                color = '#00b642'
                break;

            case ('Use of Sold Products'):
                data = datiUseProducts_sum
                color = '#85e03b'
                break;
        }

        series.push({
            name: nomeSerie,
            type: 'bar',
            stack: 'total',
            itemStyle: {color: color},
            label: {
                formatter: function (d: any) {
                    return d.data > 0 ? `${d.data}` : '';
                },
                show: true
            },
            emphasis: {
                focus: 'series'
            },
            data: data,
        })

    })

    const option = {
        title: {
            show: false,
            text: 'Resoconto generale aggregato per anno',
        },
        legend: {
            data: Array.from(nomiSeries)
        },
        toolbox: {
            show: true,
            feature: {
                mark: {show: true},
                dataView: {show: true, readOnly: false},
                restore: {show: true},
                saveAsImage: {show: true}
            }
        },
        dataZoom: [
            {
                id: 'dataZoomY',
                type: 'slider',
                yAxisIndex: [0],
            },
            {
                id: 'dataZoomX',
                type: 'slider',
                xAxisIndex: [0],
            }
        ],
        responsive: true,
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                // Use axis to trigger tooltip
                type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
            }
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '1%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
        },
        yAxis: {
            data: listaBu,
            axisLabel: {
                width: 150,
                overflow: 'truncate',
                ellipsis: '...',
            },
        },
        series: series
    };

    return (
        <div>
            <div className="text-center font-bold">Valori in t / co2e (aggregato negli anni)</div>
            <ReactEcharts style={{height: listaBu.length * 40, minHeight: 200}} className='h-full' option={option}/>
        </div>
    )
}

export default GraficoResocontoGenerale;