import React from 'react'

//Data
import {observer} from 'mobx-react-lite'
import {useStores} from '../../hooks/useStores'

//Components
import ConfermaChiusuraLogScreenModal from './ConfermaChiusuraLogScreenModal';
import {FaPlus} from 'react-icons/fa'

export const LogScreenModal = observer(() => {
    const {ui} = useStores();

    return (
        <section>
            <div
                className="sfondo-dialogue-animato fixed top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0,0.3)] z-[500]"
                onClick={() => ui.unmountConfermaChiusuraLogScreenModal()}
            />

            <div
                className="dialogue-animato fixed top-6 left-6 right-6 bottom-6 bg-white dark:bg-darkgray-dd shadow-xl dark:border dark:border-darkgray z-[500] overflow-y-scroll">
                <div className="w-full relative p-5">
                    {ui.logScreenModalComponent}

                    {ui.ConfermaChiusuraLogScreenModal && <ConfermaChiusuraLogScreenModal/>}

                    <div>
                        <div
                            className="fixed top-6 right-6 cursor-pointer bg-white p-[4px]"
                            onClick={() => ui.unmountConfermaChiusuraLogScreenModal()}
                        >
                            <div className="rotate-45">
                                <FaPlus size={20}/>
                            </div>
                        </div>
                    </div>

                    {/*<div dangerouslySetInnerHTML={{__html: ui.logScreenText}}>*/}
                    <div>
                        <pre>
                            {ui.logScreenText}
                        </pre>
                    </div>

                </div>


            </div>

        </section>
    )
})