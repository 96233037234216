import React, {useEffect, useState} from 'react'

//Data
import {Controller, useForm} from 'react-hook-form';
import {useStores} from '../../../../../../hooks/useStores';
import {crea, get, modifica} from '../../../../../../rest/crud_generica';
import {ITravelType} from '../../../../types';

//Components
import ErrorBox from '../../../../../../components/common/ErrorBox';
import InputField from '../../../../../../components/common/form/InputField';
import InfoBox from '../../../../../../components/common/InfoBox';
import SpinnerButton from '../../../../../../components/common/SpinnerButton';
import Select from 'react-select'

const TravelTypeForm: React.FC<{ traveltype?: ITravelType }> = ({traveltype}) => {
    const {ui} = useStores()
    const [attesa, setAttesa] = useState<boolean>(false)
    const [successo, setSuccesso] = useState<string>('')
    const [errore, setErrore] = useState<string>('')

    const {register, handleSubmit, watch, control, setValue, formState: {errors, isSubmitSuccessful}} = useForm({
        defaultValues: {
            nome: traveltype ? traveltype.nome : '',
            descrizione: traveltype ? traveltype.descrizione : '',
            travel_mode: traveltype ? traveltype.travel_mode : '',
        }
    });

    const [travelmode, setTravelmode] = useState<[]>([])

    useEffect(() => {
        get('carbon_footprint/travel_mode_employee_commuting').then((res) => {
            setTravelmode(res.data.data)
        })
    }, [])

    //Ristruttura dati per label MultiSelect
    travelmode.map((travel: any) => {
        travel.value = travel.nome
        travel.label = travel.nome
    })

    const onSubmit = () => {
        setAttesa(true)
        traveltype
            ?
            modifica(`carbon_footprint/travel_type_employee_commuting/${traveltype.nome}/${traveltype.travel_mode}`, {
                nome: watch('nome'),
                descrizione: watch('descrizione'),
                travel_mode: watch('travel_mode')
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Tipo di Viaggio modificato con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
            :
            crea(`carbon_footprint/travel_type_employee_commuting`, {
                nome: watch('nome'),
                descrizione: watch('descrizione'),
                travel_mode: watch('travel_mode')
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Tipo di Viaggio creato con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
    }

    return (
        <div>
            <div>
                {traveltype
                    ? <h2 className="text-2xl mb-2">Modifica la Tipologia {traveltype.nome}</h2>
                    : <h2 className="text-2xl mb-2">Crea una nuova Tipologia</h2>
                }
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>

                <InputField
                    label="Nome"
                    type="text"
                    error={errors.nome}
                    form={register("nome", {required: "Il nome è richiesto"})}
                    placeholder="Nome"
                    disabled={!!traveltype}
                />

                <InputField
                    label="Descrizione"
                    type="textarea"
                    error={errors.descrizione}
                    form={register("descrizione")}
                    placeholder="Descrizione"
                />

                <div className="form">
                    <label htmlFor="">Modo di Trasporto</label>
                    <Controller
                        name="travel_mode"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={travelmode}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={traveltype?.travel_mode && {
                                    value: traveltype.travel_mode,
                                    label: traveltype.travel_mode,
                                }}
                                isDisabled={!!traveltype}
                            />
                        )}
                    />
                </div>

                {
                    !attesa
                        ? <input type="submit" value={traveltype ? 'Salva modifiche' : 'Crea tipologia Veicolo'}/>
                        : <SpinnerButton/>
                }

                {(errore || successo) &&
                    <div className="mt-2">
                        {errore && <ErrorBox errore={errore}/>}
                        {successo && <InfoBox messaggio={successo}/>}
                    </div>
                }

            </form>
        </div>
    )
}

export default TravelTypeForm;