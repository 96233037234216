import React, {useEffect, useState} from 'react'
import {get} from '../../../../rest/crud_generica'
import TabellaInterattiva from '../../../../components/tabellaInterattiva/TabellaInterattiva'
import GraficoBusinessUnitsRendicontabili from '../grafici/GraficoBusinessUnitsRendicontabili'
import GraficoBusinessUnitsRendicontabiliTable from '../grafici/GraficoBusinessUnitsRendicontabiliTable';
import {IAnalisiCarbonFootprintTotale, IAnno} from '../../types';
import {FaAngleDown} from 'react-icons/fa';

const AnalisiCarbonFootprintBusinessUnitRendicontabili = () => {
    const [annoAttivo, setAnnoAttivo] = useState(new Date().getFullYear())
    const [listaAnni, setListaAnni] = useState<IAnno[]>([])
    const [listaAnniVisibile, setListaAnniVisibile] = useState<boolean>(false)
    const [businessUnitsRendicontabili, setBusinessUnitsRendicontabili] = useState(new Array<IAnalisiCarbonFootprintTotale>())
    const [businessUnitsRendicontabiliAnno, setBusinessUnitsRendicontabiliAnno] = useState(new Array<IAnalisiCarbonFootprintTotale>())
    const [caricamentoFinito, setCaricamentoFinito] = useState<boolean>(false)

    useEffect(() => {
        get('carbon_footprint/anni').then((res) => {
            setListaAnni(res.data.data)
        })
    }, [])

    useEffect(() => {
        setBusinessUnitsRendicontabili([])
        setCaricamentoFinito(false)
        get('carbon_footprint/analisi/business_units_rendicontabili_resoconto').then((res) => {
            setBusinessUnitsRendicontabili(res.data.data)
            setCaricamentoFinito(true)
        })
    }, [])

    useEffect(() => {
        setBusinessUnitsRendicontabiliAnno(businessUnitsRendicontabili.filter(el => el.anno == annoAttivo))
    }, [annoAttivo])

    return (
        <div className='bg-white dark:bg-darkgray-dd'>

            <div className="py-6 text-center">
                <h1 className='flex items-center gap-1 justify-center my-2'>
                    <span className="text-3xl font-bold">Business Units Rendicontabili</span>

                    <div className="relative bg-lightgray cursor-pointer"
                         onClick={() => setListaAnniVisibile(!listaAnniVisibile)}>
                        <div className='flex items-center gap-2 h-[60px] px-3 hover:bg-[rgba(0,0,0,0.1)] duration-200'>
                        <span className="text-3xl font-bold">
                            {annoAttivo}
                        </span>
                            <div className={`duration-300 ${listaAnniVisibile && 'rotate-180'}`}>
                                <FaAngleDown/>
                            </div>
                        </div>

                        {listaAnniVisibile &&
                            <div className='absolute bg-lightgray top-[60px] left-0 right-0 z-10'>
                                {
                                    listaAnni.map((anno: IAnno) => (
                                        <div
                                            className='w-full px-4 py-1 font-bold text-3xl hover:bg-[rgba(0,0,0,0.1)] duration-200'
                                            onClick={() => setAnnoAttivo(anno.anno)}
                                        >
                                            {anno.anno}
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </h1>
                <p className="text-lg">
                    Lista di tutte le business units che possiedono almeno un gruppo e un utente attivo, con il rispettivo stato di rendicontazione
                </p>
            </div>

            {/*solo scope 1 e 2*/}
            {/*<GraficoBusinessUnitsRendicontabili businessUnitsRendicontabili={businessUnitsRendicontabili}/>*/}

            {/*scope 1, 2 e 3*/}
            <GraficoBusinessUnitsRendicontabiliTable businessUnitsRendicontabili={businessUnitsRendicontabiliAnno}/>

            <TabellaInterattiva
                synergical={true}
                titolo="Dati grezzi"
                dati={businessUnitsRendicontabili}
                caricamentoFinito={caricamentoFinito}
                colonne={[
                    {id: 'business_unit', label: 'Business Unit', link: 'app/modulo/analisi_carbon_footprint/{{valore}}'},
                    {id: 'anno', label: 'Anno'},
                    {id: 'n_gruppi', label: 'Numero di gruppi'},
                    {id: 'n_utenti_attivi', label: 'Numero di utenti attivi'},
                    {id: 'emissione_totale_t_co2e_combustibile', label: 'Emissioni degli impianti stazionati (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_gas_refrigerante', label: 'Emissioni dei gas refrigeranti (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_veicoli', label: 'Emissioni dei veicoli (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_elettricita', label: 'Emissioni elettriche (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_upstream_transport', label: 'Emissioni indirette upstream transport (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_waste', label: 'Emissioni indirette waste (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_wastewater', label: 'Emissioni indirette wastewater (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_other_disposal', label: 'Emissioni indirette other disposal (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_business_travel_hotel', label: 'Emissioni indirette business travel hotel (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_business_travel_mode', label: 'Emissioni indirette business travel mode (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_employee_commuting', label: 'Emissioni indirette employee commuting (t / co2e)'},
                    {id: 'emissione_totale_t_co2e_use_of_sold_products', label: 'Emissioni indirette use of sold products (t / co2e)'},
                ]}
            />
        </div>
    )
}

export default AnalisiCarbonFootprintBusinessUnitRendicontabili;