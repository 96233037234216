import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useTranslation} from 'react-i18next'
import {get} from '../../../../rest/crud_generica'
import MenuGenerico from '../../../../components/common/MenuGenerico'
import TabellaInterattiva from '../../../../components/tabellaInterattiva/TabellaInterattiva'
import { IColonnaTabellaInterattiva } from '../../../../components/tabellaInterattiva/types'

const StatisticheTema: React.FC<{ anno_bilancio: number }> = observer(({anno_bilancio}) => {

    const {t} = useTranslation()
    const [tabellaAttiva, setTabellaAttiva] = useState<'analitica_anno_tema_indicatori_bu' | 'analitica_anno_tema' | 'analitica_tema_indicatori_bu' | 'analitica_tema_bu'>('analitica_anno_tema_indicatori_bu')
    const [statisticheAnnoTemaIndBU, setStatisticheAnnoTemaIndBU] = useState([])
    const [statisticheAnnoTema, setStatisticheAnnoTema] = useState([])
    const [statisticheTemaIndBU, setStatisticheTemaIndBU] = useState([])
    const [statisticheTemaBU, setStatisticheTemaBU] = useState([])
    const [statisticheAnnoTemaIndBU_colonne, setStatisticheAnnoTemaIndBU_colonne] = useState(new Array<IColonnaTabellaInterattiva>())

    let colonne: IColonnaTabellaInterattiva[] = [
        {id: 'anno', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.anno')},
        {id: 'tema', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.tema')},
        {id: 'indicatore_id', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.indicatore_id')},
        {id: 'business_unit', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.business_unit')},
        {id: 'codice_padre', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.codice_padre')},
        {id: 'fattore_codice', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.fattore_codice')},
        {id: 'nome_IT', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.fattore_nome_tradotto')},
        {id: 'valore_numerico', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.valore_numerico')},
        {id: 'unita_misura', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.unita_misura')},
    ]

    const getLingua = () => {
        const data = localStorage.getItem("utente");
        return data ? JSON.parse(data).lingua : '';
    };

    useEffect(() => {

        switch (getLingua()) {
            case 'it':
                colonne[6] = {id: 'nome_IT', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.fattore_nome_tradotto')}
                break
            case 'en':
                colonne[6] = {id: 'nome_EN', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.fattore_nome_tradotto')}
                break
            case 'de':
                colonne[6] = {id: 'nome_DE', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.fattore_nome_tradotto')}
                break
            case 'fr':
                colonne[6] = {id: 'nome_FR', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.fattore_nome_tradotto')}
                break
            case 'es':
                colonne[6] = {id: 'nome_ES', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.fattore_nome_tradotto')}
                break
        }

        switch (tabellaAttiva) {

            case ('analitica_anno_tema_indicatori_bu'):
                get(`bilancio/statistiche/analitica_anno_tema_indicatori_bu/${anno_bilancio}`)
                    .then((res) => {
                        setStatisticheAnnoTemaIndBU(res.data.data)
                        setStatisticheAnnoTemaIndBU_colonne(colonne)
                    })
                break;

            case ('analitica_anno_tema'):
                get(`bilancio/statistiche/analitica_anno_tema/${anno_bilancio}`)
                    .then((res) => setStatisticheAnnoTema(res.data.data))
                break;

            case ('analitica_tema_indicatori_bu'):
                get(`bilancio/statistiche/analitica_tema_indicatori_bu`)
                    .then((res) => setStatisticheTemaIndBU(res.data.data))
                break;

            case ('analitica_tema_bu'):
                get(`bilancio/statistiche/analitica_tema_bu`)
                    .then((res) => setStatisticheTemaBU(res.data.data))
                break;

        }

    }, [tabellaAttiva])

    return (
        <div>
            <MenuGenerico bottoni={[
                {
                    label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.tabs.anno_tema_indicatori_bu'),
                    action: () => setTabellaAttiva('analitica_anno_tema_indicatori_bu'),
                    attivo: tabellaAttiva === 'analitica_anno_tema_indicatori_bu'
                },
                {
                    label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.tabs.anno_tema'),
                    action: () => setTabellaAttiva('analitica_anno_tema'),
                    attivo: tabellaAttiva === 'analitica_anno_tema'
                },
                {
                    label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.tabs.tema_indicatori_bu'),
                    action: () => setTabellaAttiva('analitica_tema_indicatori_bu'),
                    attivo: tabellaAttiva === 'analitica_tema_indicatori_bu'
                },
                {
                    label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.tabs.tema_bu'),
                    action: () => setTabellaAttiva('analitica_tema_bu'),
                    attivo: tabellaAttiva === 'analitica_tema_bu'
                },
            ]}/>

            {

                tabellaAttiva === 'analitica_anno_tema_indicatori_bu' &&
                <TabellaInterattiva
                    synergical={true}
                    titolo={t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.titles.anno_tema_indicatori_bu')}
                    dati={statisticheAnnoTemaIndBU}
                    colonne={statisticheAnnoTemaIndBU_colonne}
                />
            }

            {
                tabellaAttiva === 'analitica_anno_tema' &&
                <TabellaInterattiva
                    synergical={true}
                    titolo={t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.titles.anno_tema')}
                    dati={statisticheAnnoTema}
                    colonne={
                        [
                            {id: 'anno', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.anno')},
                            {id: 'tema', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.tema')},
                            {id: 'codice_padre', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.codice_padre')},
                            {id: 'valore_numerico', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.valore_numerico')},
                            {id: 'unita_misura', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.unita_misura')},
                        ]
                    }
                />
            }

            {
                tabellaAttiva === 'analitica_tema_indicatori_bu' &&
                <TabellaInterattiva
                    synergical={true}
                    titolo={t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.titles.tema_indicatori_bu')}
                    dati={statisticheTemaIndBU}
                    colonne={
                        [
                            {id: 'tema', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.tema')},
                            // {id: 'indicatore_id', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.indicatore_id')}, // not necessary in this view
                            {id: 'business_unit', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.business_unit')},
                            {id: 'valore_numerico', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.valore_numerico')},
                            {id: 'unita_misura', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.unita_misura')},
                        ]
                    }
                />
            }

            {
                tabellaAttiva === 'analitica_tema_bu' &&
                <TabellaInterattiva
                    synergical={true}
                    titolo={t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.titles.tema_bu')}
                    dati={statisticheTemaBU}
                    colonne={
                        [
                            {id: 'tema', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.tema')},
                            {id: 'business_unit', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.business_unit')},
                            {id: 'valore_numerico', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.valore_numerico')},
                            {id: 'unita_misura', label: t('ModuloFattoriEsg.DashboardSchedaBilancio.analisi.unita_misura')},
                        ]
                    }
                />
            }

        </div>
    )
})

export default StatisticheTema;
