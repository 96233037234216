import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {get} from '../../../../rest/crud_generica'
import TabellaInterattiva from '../../../../components/tabellaInterattiva/TabellaInterattiva'
import GraficoBusinessUnit from '../grafici/graficiBusinessUnit/GraficoBusinessUnit'
import BackButton from "../../../../components/common/BackButton";
import {IAnalisiCarbonFootprintAggregato} from "../../types";

export default function AnalisiCarbonFootprintBusinessUnit() {

    const {business_unit} = useParams()
    const [emissioniBusinessUnit, setEmissioniBusinessUnit] = useState(new Array<IAnalisiCarbonFootprintAggregato>())
    const [caricamentoFinito, setCaricamentoFinito] = useState<boolean>(false)

    useEffect(() => {
        setEmissioniBusinessUnit([])
        setCaricamentoFinito(false)
        get(`carbon_footprint/analisi/resoconto/business_unit/${business_unit}`).then((res) => {
            setEmissioniBusinessUnit(res.data.data)
            setCaricamentoFinito(true)
        })
    }, [])

    return (
        <div className='bg-white dark:bg-darkgray-dd'>

            <div className="py-6 text-center">
                <BackButton destinazione={'/app/modulo/analisi_carbon_footprint'} testo={'Torna indietro'}></BackButton>
                <h1 className='text-3xl font-bold'>Resoconto "{business_unit}"</h1>
                <p className="text-lg">Storico delle emissioni su base annuale per questa business unit</p>
            </div>

            <GraficoBusinessUnit emissioniBusinessUnit={emissioniBusinessUnit}/>

            <TabellaInterattiva
                synergical={true}
                titolo="Dati grezzi"
                dati={emissioniBusinessUnit}
                caricamentoFinito={caricamentoFinito}
                colonne={[
                    {id: 'business_unit', label: 'Business Unit'},
                    {id: 'business_unit_padre', label: 'Business Unit Padre'},
                    {id: 'anno', label: 'Anno'},
                    {id: 'emissione_cumulata_t_co2e_totale', label: 'Emissioni totali (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_combustibile', label: 'Emissioni degli impianti stazionati (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_gas_refrigerante', label: 'Emissioni dei gas refrigeranti (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_veicoli', label: 'Emissioni dei veicoli (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_elettricita', label: 'Emissioni elettriche (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_upstream_transport', label: 'Emissioni indirette upstream transport (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_waste', label: 'Emissioni indirette waste (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_wastewater', label: 'Emissioni indirette wastewater (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_other_disposal', label: 'Emissioni indirette other disposal (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_business_travel_hotel', label: 'Emissioni indirette business travel hotel (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_business_travel_mode', label: 'Emissioni indirette business travel mode (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_employee_commuting', label: 'Emissioni indirette employee commuting (t / co2e)'},
                    {id: 'emissione_cumulata_t_co2e_use_of_sold_products', label: 'Emissioni indirette use of sold products (t / co2e)'},
                ]}
            />
        </div>
    )
}
