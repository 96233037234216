import React, {useEffect, useState} from 'react'

//Data
import {Controller, useForm} from 'react-hook-form'
import InputField from '../../../../../../components/common/form/InputField';
import {crea, get, modifica} from '../../../../../../rest/crud_generica';
import {IFattoreUseOfSoldProducts} from '../../../../types'
import {useStores} from '../../../../../../hooks/useStores';

//Components
import Select from 'react-select'
import SpinnerButton from '../../../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../../components/common/InfoBox';
import {useTranslation} from 'react-i18next';
import {getUnitaMisuraLista} from '../../../../../gestioneUnitaMisura/rest/gestioneUnitaMisura';

const UseOfSoldProductsForm: React.FC<{ useofsoldproducts?: IFattoreUseOfSoldProducts }> = ({useofsoldproducts}) => {

    const {ui} = useStores()
    const {t} = useTranslation()

    const [attesa, setAttesa] = useState<boolean>(false)
    const [successo, setSuccesso] = useState<string>('')
    const [errore, setErrore] = useState<string>('')

    const {register, handleSubmit, watch, control, setValue, formState: {errors, isSubmitSuccessful}} = useForm({
        defaultValues: {
            anno: useofsoldproducts ? useofsoldproducts.anno : '',
            paese: useofsoldproducts ? useofsoldproducts.paese : '',
            unita_misura: useofsoldproducts ? useofsoldproducts.unita_misura : '',
            kg_co2e: useofsoldproducts ? useofsoldproducts.kg_co2e : '',
        }
    });

    const [listaAnni, setListaAnni] = useState<any[]>([])
    const [paesi, setPaesi] = useState<[]>([])
    const [listaUnitaMisura, setListaUnitaMisura] = useState<any[]>([])

    //setta i paesi, Shipping Method e Carrier Type
    useEffect(() => {
        get('paesi').then((res) => {
            setPaesi(res.data.data)
        })

        getUnitaMisuraLista().then((res) => {
            setListaUnitaMisura([...res.data.data, {
                label: t('common.nessuna'),
                value: null
            }])
        })

        listaAnno()
    }, [])

    //Ristruttura dati per label MultiSelect
    paesi.map((paese: any) => {
        paese.value = paese.nome
        paese.label = paese.nome
    })

    //Ottieni lista gas
    const listaAnno = () => get('carbon_footprint/anni').then((res) => {
        setListaAnni(res.data.data)
    })

    //Ristruttura dati per label MultiSelect
    listaAnni.filter((um) => !um.label).map((listaAnno: any) => {
        listaAnno.value = listaAnno.anno
        listaAnno.label = listaAnno.anno
    })

    //Ristruttura dati per label MultiSelect
    listaUnitaMisura.filter((um) => !um.label).map((unitaMisura: any) => {
        unitaMisura.value = unitaMisura.codice
        unitaMisura.label = unitaMisura.codice
    })

    const onSubmit = (data:any) => {
        setAttesa(true)

        useofsoldproducts
            ?
            modifica(`carbon_footprint/use_of_sold_products/${data.anno}/${data.paese}/${data.unita_misura}`, {
                anno: data.anno,
                paese: data.paese,
                unita_misura: data.unita_misura,
                kg_co2e: data.kg_co2e,
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Fattore di Use sold of Products modificato con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
            :
            crea(`carbon_footprint/use_of_sold_products`, {
                anno: data.anno,
                paese: data.paese,
                unita_misura: data.unita_misura,
                kg_co2e: data.kg_co2e,
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Fattore di  Use sold of Products creato con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
    }

    return (
        <div>
            <div>
                {useofsoldproducts
                    ? <h2 className="text-2xl mb-2">Modifica il fattore Upstream Transport {useofsoldproducts.anno}, per il paese {useofsoldproducts.paese}</h2>
                    : <h2 className="text-2xl mb-2">Crea un nuovo fattore Upstream Transport</h2>
                }
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>

                <div className="form w-full">
                    <label htmlFor="">Anno</label>
                    <Controller
                        name="anno"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={listaAnni}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={useofsoldproducts?.anno && {
                                    value: useofsoldproducts.anno,
                                    label: useofsoldproducts.anno,
                                }}
                                isDisabled={!!useofsoldproducts}
                            />
                        )}
                    />
                </div>

                <div className="form">
                    <label>{t('common.paese')}</label>
                    <Controller
                        name="paese"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={paesi}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={useofsoldproducts?.paese && {
                                    value: useofsoldproducts.paese,
                                    label: useofsoldproducts.paese,
                                }}
                                isDisabled={!!useofsoldproducts}
                            />
                        )}
                    />
                </div>

                <div className="form">
                    <label>Unita Misura</label>
                    <Controller
                        name="unita_misura"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={listaUnitaMisura}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={useofsoldproducts?.unita_misura && {
                                    value: useofsoldproducts.unita_misura,
                                    label: useofsoldproducts.unita_misura,
                                }}
                                isDisabled={!!useofsoldproducts}
                            />
                        )}
                    />
                </div>

                <InputField
                    label="Kg co2e"
                    type="number"
                    error={errors.kg_co2e}
                    form={register("kg_co2e", {required: "Il fattore di emissione è richiesto"})}
                    placeholder="CO2 Equivalente"
                />

                {
                    !attesa
                        ? <input type="submit" value={useofsoldproducts ? 'Salva modifiche' : 'Crea Emissione'}/>
                        : <SpinnerButton/>
                }

                {(errore || successo) &&
                    <div className="mt-2">
                        {errore && <ErrorBox errore={errore}/>}
                        {successo && <InfoBox messaggio={successo}/>}
                    </div>
                }

            </form>
        </div>
    )
}

export default UseOfSoldProductsForm;