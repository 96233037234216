import React, {useEffect, useState, useRef} from 'react'

//Data
import {Controller, useForm} from 'react-hook-form'
import InputField from '../../../../../../components/common/form/InputField';
import {crea, get, modifica} from '../../../../../../rest/crud_generica';
import {IFattoreWaste} from '../../../../types'
import {useStores} from '../../../../../../hooks/useStores';

//Components
import Select from 'react-select'
import SpinnerButton from '../../../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../../components/common/InfoBox';
import {useTranslation} from 'react-i18next';

const WasteForm: React.FC<{ waste?: IFattoreWaste }> = ({waste}) => {

    const {ui} = useStores()
    const {t} = useTranslation()

    const [attesa, setAttesa] = useState<boolean>(false)
    const [successo, setSuccesso] = useState<string>('')
    const [errore, setErrore] = useState<string>('')

    const {register, handleSubmit, watch, control, setValue, formState: {errors, isSubmitSuccessful}} = useForm({
        defaultValues: {
            anno: waste ? waste.anno : '',
            codice_waste: waste ? waste.codice_waste : '',
            waste_category: waste ? waste.waste_category : '',
            posizione_geografica: waste ? waste.posizione_geografica : '',
            incineration_with_energy: waste ? waste.incineration_with_energy : '',
            incineration_without_energy: waste ? waste.incineration_without_energy : '',
            delivery_to_landfill: waste ? waste.delivery_to_landfill : '',
            other_disposal: waste ? waste.other_disposal : '',
        }
    });

    const [listaAnni, setListaAnni] = useState<any[]>([])
    const [listaWasteCategory, setListaWasteCategory] = useState<any[]>([])
    const [listaPosizioneGeografica, setPosizioneGeografica,] = useState<any[]>([])

    const selectRef = useRef("");

    //setta i paesi, Shipping Method e Carrier Type
    useEffect(() => {

        get('carbon_footprint/waste_category').then((res) => {
            setListaWasteCategory(res.data.data)
        })

        get('business_unit_helper/posizione_geografica').then((res) => {
            setPosizioneGeografica(res.data.data)
        })

        listaAnno();
    }, [])

    //Ottieni lista gas
    const listaAnno = () => get('carbon_footprint/anni').then((res) => {
        setListaAnni(res.data.data)
    })

    //Ristruttura dati per label MultiSelect
    listaAnni.filter((um) => !um.label).map((listaAnno: any) => {
        listaAnno.value = listaAnno.anno
        listaAnno.label = listaAnno.anno
    })

    useEffect(() => {
        const tempWasteCategory = listaWasteCategory.find((el) => el.label == waste?.waste_category)
        tempWasteCategory && handleSelectWasteCategoryChange(tempWasteCategory);
    }, [listaWasteCategory])

    useEffect(() => {
        const tempPosizioneGeografica = listaPosizioneGeografica.find((el) => el.label == waste?.posizione_geografica)
        tempPosizioneGeografica && handleSelectPosizioneGeograficaChange(tempPosizioneGeografica);
    }, [listaPosizioneGeografica])

    const handleSelectWasteCategoryChange = (e: any) => {
        setValue('waste_category', e.value)
    }

    const handleSelectPosizioneGeograficaChange = (e: any) => {
        setValue('posizione_geografica', e.value)
    }

    const onSubmit = (data:any) => {
        setAttesa(true)

        waste
            ?
            modifica(`carbon_footprint/waste/${data.anno}/${data.codice_waste}/${data.waste_category}/${data.posizione_geografica}`, {
                anno: data.anno,
                codice_waste: data.codice_waste,
                waste_category: data.waste_category,
                posizione_geografica: data.posizione_geografica,
                incineration_with_energy: data.incineration_with_energy,
                incineration_without_energy: data.incineration_without_energy,
                delivery_to_landfill: data.delivery_to_landfill,
                other_disposal: data.other_disposal,
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Fattore di Waste modificato con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
            :
            crea(`carbon_footprint/waste`, {
                anno: data.anno,
                codice_waste: data.codice_waste,
                waste_category: data.waste_category,
                posizione_geografica: data.posizione_geografica,
                incineration_with_energy: data.incineration_with_energy,
                incineration_without_energy: data.incineration_without_energy,
                delivery_to_landfill: data.delivery_to_landfill,
                other_disposal: data.other_disposal,
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Fattore di  Waste creato con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
    }

    return (
        <div>
            <div>
                {waste
                    ? <h2 className="text-2xl mb-2">Modifica il fattore Waste {waste.waste_category}, dell'anno {waste.anno}</h2>
                    : <h2 className="text-2xl mb-2">Crea un nuovo fattore Waste</h2>
                }
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>

                <div className="form w-full">
                    <label htmlFor="">Anno</label>
                    <Controller
                        name="anno"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={listaAnni}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={waste?.anno && {
                                    value: waste.anno,
                                    label: waste.anno,
                                }}
                                isDisabled={!!waste}
                            />
                        )}
                    />
                </div>

                <InputField
                    label="Waste Code"
                    type="text"
                    classi={"disabled:opacity-50"} // legge la property disabled come condizione per abilitare lo style
                    error={errors.codice_waste}
                    form={register("codice_waste")}
                    placeholder="Waste Code"
                    disabled={!!waste}
                />

                <div className="form">
                    <label>Waste Category</label>
                    <Controller
                        name="waste_category"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={listaWasteCategory}
                                onChange={(e: any) => handleSelectWasteCategoryChange(e)}
                                defaultValue={waste?.waste_category && {
                                    value: waste?.waste_category,
                                    label: waste?.waste_category,
                                }}
                                isDisabled={!!waste}
                            />
                        )}
                    />
                </div>

                <div className="form">
                    <label>Posizione Geografica</label>
                    <Controller
                        name="posizione_geografica"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={listaPosizioneGeografica}
                                onChange={(e: any) => handleSelectPosizioneGeograficaChange(e)}
                                defaultValue={waste?.posizione_geografica && {
                                    value: waste?.posizione_geografica,
                                    label: waste?.posizione_geografica,
                                }}
                                isDisabled={!!waste}
                            />
                        )}
                    />
                </div>

                <InputField
                    label="Incenerimento recupero energetico"
                    type="number"
                    error={errors.incineration_with_energy}
                    form={register("incineration_with_energy")}
                    placeholder="CO2 Equivalente"
                />

                <InputField
                    label="Incenerimento senza recupero energetico"
                    type="number"
                    error={errors.incineration_without_energy}
                    form={register("incineration_without_energy")}
                    placeholder="CO2 Equivalente"
                />

                <InputField
                    label="Discarica"
                    type="number"
                    error={errors.delivery_to_landfill}
                    form={register("delivery_to_landfill")}
                    placeholder="CO2 Equivalente"
                />

                <InputField
                    label="Altro"
                    type="number"
                    error={errors.other_disposal}
                    form={register("other_disposal")}
                    placeholder="CO2 Equivalente"
                />

                {
                    !attesa
                        ? <input type="submit" value={waste ? 'Salva modifiche' : 'Crea Emissione'}/>
                        : <SpinnerButton/>
                }

                {(errore || successo) &&
                    <div className="mt-2">
                        {errore && <ErrorBox errore={errore}/>}
                        {successo && <InfoBox messaggio={successo}/>}
                    </div>
                }

            </form>
        </div>
    )
}

export default WasteForm;