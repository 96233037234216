import React, {useEffect, useState} from 'react'

//Data
import {Controller, useForm} from 'react-hook-form'
import InputField from '../../../../../../components/common/form/InputField';
import {crea, get, modifica} from '../../../../../../rest/crud_generica';
import {ICostanteVeicoloTipologiaCarburante, IFattoreBusinessTravel} from '../../../../types'
import {useStores} from '../../../../../../hooks/useStores';

//Components
import Select from 'react-select'
import SpinnerButton from '../../../../../../components/common/SpinnerButton';
import ErrorBox from '../../../../../../components/common/ErrorBox';
import InfoBox from '../../../../../../components/common/InfoBox';
import {useTranslation} from 'react-i18next';
import {getUnitaMisuraLista} from '../../../../../gestioneUnitaMisura/rest/gestioneUnitaMisura';

const BusinessTravelForm: React.FC<{ businesstravel?: IFattoreBusinessTravel }> = ({businesstravel}) => {

    const {ui} = useStores()
    const {t} = useTranslation()

    const [attesa, setAttesa] = useState<boolean>(false)
    const [successo, setSuccesso] = useState<string>('')
    const [errore, setErrore] = useState<string>('')

    const {register, handleSubmit, watch, control, setValue, formState: {errors, isSubmitSuccessful}} = useForm({
        defaultValues: {
            anno: businesstravel ? businesstravel.anno : '',
            paese: businesstravel ? businesstravel.paese : '',
            regione_geografica: businesstravel ? businesstravel.regione_geografica : '',
            travel_mode: businesstravel ? businesstravel.travel_mode : '',
            travel_type: businesstravel ? businesstravel.travel_type : '',
            dimensione_veicolo: businesstravel ? businesstravel.dimensione_veicolo : '',
            unita_misura: businesstravel ? businesstravel.unita_misura : '',
            kg_co2e: businesstravel ? businesstravel.kg_co2e : '',
        }
    });

    const [listaAnni, setListaAnni] = useState<any[]>([])
    const [regioni_geografiche, setRegioniGeografiche] = useState<[]>([])
    const [paesi, setPaesi] = useState<[]>([])
    const [listaUnitaMisura, setListaUnitaMisura] = useState<any[]>([])
    const [listaTravelType, setListaTravelType,] = useState<any[]>([])
    const [dimensioni, setDimensioni] = useState<[]>([])

    useEffect(() => {
        get('paesi').then((res) => {
            setPaesi(res.data.data)
        })

        get('business_unit_helper/regione_geografica_media').then((res) => {
            setRegioniGeografiche(res.data.data)
        })

        get('carbon_footprint/dimensioni').then((res) => {
            setDimensioni(res.data.data)
        })

        get('carbon_footprint/travel_type_business_travel').then((res) => {
            setListaTravelType(res.data.data)
        })

        getUnitaMisuraLista().then((res) => {
            setListaUnitaMisura([...res.data.data, {
                label: t('common.nessuna'),
                value: null
            }])
        })

        listaAnno()

    }, [])

    //Ristruttura dati per label MultiSelect
    paesi.map((paese: any) => {
        paese.value = paese.nome
        paese.label = paese.nome
    })

    //Ristruttura dati per label MultiSelect
    listaTravelType.map((travel: any) => {
        travel.value = travel.travel_mode + ': ' + travel.nome // ':' è il separatore che viene letto da onSubmit per separare 'travel_mode' da 'travel_type'
        travel.label = travel.travel_mode + ': ' + travel.nome // ':' è il separatore che viene letto da onSubmit per separare 'travel_mode' da 'travel_type'
    })

    //Ristruttura dati per label MultiSelect
    dimensioni.map((dimensione: any) => {
        dimensione.value = dimensione.dimensione
        dimensione.label = dimensione.dimensione
    })

    //Ottieni lista gas
    const listaAnno = () => get('carbon_footprint/anni').then((res) => {
        setListaAnni(res.data.data)
    })

    //Ristruttura dati per label MultiSelect
    listaUnitaMisura.filter((um) => !um.label).map((unitaMisura: any) => {
        unitaMisura.value = unitaMisura.codice
        unitaMisura.label = unitaMisura.codice
    })

    //Ristruttura dati per label MultiSelect
    listaAnni.filter((um) => !um.label).map((listaAnno: any) => {
        listaAnno.value = listaAnno.anno
        listaAnno.label = listaAnno.anno
    })

    const onSubmit = (data: any) => {
        setAttesa(true)

        // Se in modifica, i campi chiave sono disabilitati e non si possono modificare, quindi 'travel_mode' e 'travel_type' che arrivano dalla resource sono corretti
        if (!businesstravel && data.travel_type) {
            const parts = data.travel_type.split(': ');
            data.travel_mode = parts[0];
            data.travel_type = parts[1];
        }

        businesstravel
            ?
            modifica(`carbon_footprint/business_travel/${data.anno}/${data.regione_geografica}/${data.paese}/${data.travel_mode}/${data.travel_type}/${data.dimensione_veicolo}/${data.unita_misura}`, {
                anno: data.anno,
                regione_geografica: data.regione_geografica,
                paese: data.paese,
                travel_mode: data.travel_mode,
                travel_type: data.travel_type,
                dimensione_veicolo: data.dimensione_veicolo,
                unita_misura: data.unita_misura,
                kg_co2e: data.kg_co2e,
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Fattore di business travel modificato con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
            :
            crea(`carbon_footprint/business_travel`, {
                anno: data.anno,
                regione_geografica: data.regione_geografica,
                paese: data.paese,
                travel_mode: data.travel_mode,
                travel_type: data.travel_type,
                dimensione_veicolo: data.dimensione_veicolo,
                unita_misura: data.unita_misura,
                kg_co2e: data.kg_co2e,
            })
                .then((res) => {
                    setErrore('')
                    setSuccesso(res.data.message ? res.data.message : 'Fattore di business travel creato con successo')
                    ui.triggerAggiornamentoGenerico()
                })
                .catch((err) => {
                    setErrore(err.response.data.message ? err.response.data.message : 'Errore generico')
                    setSuccesso('')
                })
                .finally(() => setAttesa(false))
    }

    return (
        <div>
            <div>
                {businesstravel
                    ? <h2 className="text-2xl mb-2">Modifica il fattore Travel Business {businesstravel.travel_type}, dell'anno {businesstravel.anno}</h2>
                    : <h2 className="text-2xl mb-2">Crea un nuovo fattore Travel Business</h2>
                }
            </div>

            <form className="form" onSubmit={handleSubmit(onSubmit)}>

                <div className="form w-full">
                    <label htmlFor="">{t('common.anno')}</label>
                    <Controller
                        name="anno"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={listaAnni}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={businesstravel?.anno && {
                                    value: businesstravel.anno,
                                    label: businesstravel.anno,
                                }}
                                isDisabled={!!businesstravel}
                            />
                        )}
                    />
                </div>

                <div className="form">
                    <label>{t('common.regione_geografica')}</label>
                    <Controller
                        name="regione_geografica"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={regioni_geografiche}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={businesstravel?.regione_geografica && {
                                    value: businesstravel.regione_geografica,
                                    label: businesstravel.regione_geografica,
                                }}
                                isDisabled={!!businesstravel}
                            />
                        )}
                    />
                </div>

                <div className="form">
                    <label>{t('common.paese')}</label>
                    <Controller
                        name="paese"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={paesi}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={businesstravel?.paese && {
                                    value: businesstravel.paese,
                                    label: businesstravel.paese,
                                }}
                                isDisabled={!!businesstravel}
                            />
                        )}
                    />
                </div>

                <div className="form">
                    <label htmlFor="">{t('common.tipoViaggio')}</label>
                    <Controller
                        name="travel_type"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={listaTravelType}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={businesstravel?.travel_type && {
                                    value: businesstravel.travel_mode + ': ' + businesstravel.travel_type,
                                    label: businesstravel.travel_mode + ': ' + businesstravel.travel_type,
                                }}
                                isDisabled={!!businesstravel}
                            />
                        )}
                    />
                </div>

                <div className="form">
                    <label htmlFor="">{t('common.dimensione')}</label>
                    <Controller
                        name="dimensione_veicolo"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={dimensioni}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={businesstravel?.dimensione_veicolo && {
                                    value: businesstravel.dimensione_veicolo,
                                    label: businesstravel.dimensione_veicolo,
                                }}
                                isDisabled={!!businesstravel}
                            />
                        )}
                    />
                </div>

                <div className="form">
                    <label>{t('common.unitaMisura')}</label>
                    <Controller
                        name="unita_misura"
                        control={control}
                        render={({field: {onChange}}) => (
                            <Select
                                options={listaUnitaMisura}
                                onChange={(e: any) => onChange(e.value)}
                                defaultValue={businesstravel?.unita_misura && {
                                    value: businesstravel.unita_misura,
                                    label: businesstravel.unita_misura,
                                }}
                                isDisabled={!!businesstravel}
                            />
                        )}
                    />
                </div>

                <InputField
                    label="Kg co2e"
                    type="number"
                    error={errors.kg_co2e}
                    form={register("kg_co2e", {required: "Il fattore di emissione è richiesto"})}
                    placeholder="CO2 Equivalente"
                />

                {
                    !attesa
                        ? <input type="submit" value={businesstravel ? 'Salva modifiche' : 'Crea Emissione'}/>
                        : <SpinnerButton/>
                }

                {(errore || successo) &&
                    <div className="mt-2">
                        {errore && <ErrorBox errore={errore}/>}
                        {successo && <InfoBox messaggio={successo}/>}
                    </div>
                }

            </form>
        </div>
    )
}

export default BusinessTravelForm;