import React, { useEffect, useState } from 'react'

//Data
import { CgAdd } from 'react-icons/cg'
import { FaPen, FaTrash } from 'react-icons/fa'
import { useStores } from '../../../../../hooks/useStores'
import { elimina, get } from '../../../../../rest/crud_generica'
import { IFattoreEmissioneElettrico } from '../../../types'

//Components
import Dialogue from '../../../../../components/common/Dialogue'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'
import EmissioniElettricitaForm from './components/EmissioniElettricitaForm';
import { observer } from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../hooks/useOttieniPrivilegioModulo'
import { ICallToActionTabella } from '../../../../../components/tabellaInterattiva/types'
import { useTabellaInterattivaContext } from '../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'

const ConfigurazioneElettricita = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()

    const { ui } = useStores()

    const [emissioniElettricitta, setEmissioniElettiricta] = useState<IFattoreEmissioneElettrico[]>([])

    useEffect(() => {
        listaEmissioniEletricitta()
    }, [ui.aggiornamentoGenerico])

    //Ottieni lista gas
    const listaEmissioniEletricitta = () => get('carbon_footprint/elettricita_emissioni').then((res) => {
        setEmissioniElettiricta(res.data.data)
    })

    //Crea gas
    const creaEmissioniElettiricta = () => ui.mountAsideModal(<EmissioniElettricitaForm />)

    //Modifica gas
    const modificaEmissioniElettiricta = (elettricita: IFattoreEmissioneElettrico) => {
        ui.mountAsideModal(<EmissioniElettricitaForm elettricita={elettricita} />)
    }

    //Elimina gas
    const eliminaEmissioniElettiricta = (elettricita: IFattoreEmissioneElettrico) => {
        elimina(`carbon_footprint/elettricita_emissioni/${elettricita.nome}/${elettricita.paese}/${elettricita.anno}`)
            .then(() => {
                ui.mountDialogue(<Dialogue titolo="Elettricita eliminato con successo" />)
                listaEmissioniEletricitta()
            }
            )
            .catch(() => {
                ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione del gas" />)
            })
    }


    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const { tabella } = useTabellaInterattivaContext()
    useEffect(() => { }, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    testo: 'Crea fattore',
                    azione: creaEmissioniElettiricta,
                    icona: <CgAdd size={18} />
                }])

            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaEmissioniElettiricta,
                    testo: 'Modifica',
                    icona: <FaPen size={12} />,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaEmissioniElettiricta,
                    testo: 'Elimina',
                    icona: <FaTrash size={12} />,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={emissioniElettricitta}
                titolo="Fattori di emissione elettrici"
                paragrafo="Inserisci i fattori di emissione elettrici."
                callToActions={ctaTabella}
                colonne={
                    [
                        { id: 'nome', label: 'Nome' },
                        { id: 'paese', label: 'Paese' },
                        { id: 'anno', label: 'Anno' },
                        { id: 'kg_co2e', label: 'Kg co2e' },
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

 

export default ConfigurazioneElettricita;