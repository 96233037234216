import React, {useEffect, useState} from 'react'
import {get} from '../../../../../rest/crud_generica'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'
import MenuGenerico from '../../../../../components/common/MenuGenerico'
import {useTranslation} from 'react-i18next';
// import GraficoScope3Bar from "../../grafici/GraficoScope3Bar";
import GraficoScope3Radar from '../../grafici/GraficoScope3Radar';
import {IAnalisiScope3, IAnalisiScope3_tipologia} from '../../../types';

export default function AnalisiUseOfSoldProducts() {

    const {t} = useTranslation()
    const [tabellaAttiva, setTabellaAttiva] = useState<'emissioni_bu_totali_flat' | 'emissioni_bu_flat' | 'emissioni_flat'>('emissioni_bu_totali_flat')
    const [emissioniBusinessUnitTotaliFlat, setEmissioniBusinessUnitTotaliFlat] = useState(new Array<IAnalisiScope3>())
    const [emissioniBusinessUnitFlat, setEmissioniBusinessUnitFlat] = useState(new Array<IAnalisiScope3_tipologia>())
    const [emissioniFlat, setEmissioniFlat] = useState(new Array<IAnalisiScope3>())
    const [caricamentoFinito, setCaricamentoFinito] = useState<boolean>(false)

    useEffect(() => {

        setEmissioniBusinessUnitTotaliFlat(new Array<IAnalisiScope3>())
        setEmissioniBusinessUnitFlat(new Array<IAnalisiScope3_tipologia>())
        setEmissioniFlat(new Array<IAnalisiScope3_tipologia>())
        setCaricamentoFinito(false)

        switch (tabellaAttiva) {

            case ('emissioni_bu_totali_flat'):
                get('carbon_footprint/analisi/scope3/use_of_sold_products/emissioni_business_unit_totali_flat')
                    .then((res) => {
                        setEmissioniBusinessUnitTotaliFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

            case ('emissioni_bu_flat'):
                get('carbon_footprint/analisi/scope3/use_of_sold_products/emissioni_business_unit_flat')
                    .then((res) => {
                        setEmissioniBusinessUnitFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

            case ('emissioni_flat'):
                get('carbon_footprint/analisi/scope3/use_of_sold_products/emissioni_flat')
                    .then((res) => {
                        setEmissioniFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

        }

    }, [tabellaAttiva])

    return (
        <div>
            <MenuGenerico bottoni={[
                {
                    label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.total'),
                    action: () => setTabellaAttiva('emissioni_bu_totali_flat'),
                    attivo: tabellaAttiva === 'emissioni_bu_totali_flat'
                },
                {
                    label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.aggregated'),
                    action: () => setTabellaAttiva('emissioni_bu_flat'),
                    attivo: tabellaAttiva === 'emissioni_bu_flat'
                },
                {
                    label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.flat'),
                    action: () => setTabellaAttiva('emissioni_flat'),
                    attivo: tabellaAttiva === 'emissioni_flat'
                },
            ]}/>

            {
                tabellaAttiva === 'emissioni_bu_totali_flat' &&
                <div className='bg-white dark:bg-darkgray-dd'>

                    <div className="py-6 text-center">
                        <h1 className='text-3xl font-bold'>{t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.total')}</h1>
                        <p className="text-lg">{t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.totalDesc')}</p>
                    </div>

                    {emissioniBusinessUnitTotaliFlat.length > 0 &&
                        <GraficoScope3Radar title={t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.total')} emissioniFlat={emissioniBusinessUnitTotaliFlat} anno={0}/>}

                    <TabellaInterattiva
                        synergical={true}
                        titolo={t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.total')}
                        dati={emissioniBusinessUnitTotaliFlat}
                        caricamentoFinito={caricamentoFinito}
                        colonne={
                            [
                                {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.business_unit')},
                                {id: 'anno', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.anno')},
                                {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.emissione_t_co2e')},
                            ]
                        }
                    />
                </div>
            }

            {
                tabellaAttiva === 'emissioni_bu_flat' &&
                <div className='bg-white dark:bg-darkgray-dd'>

                    <div className="py-6 text-center">
                        <h1 className='text-3xl font-bold'>{t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.aggregated')}</h1>
                        <p className="text-lg">{t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.aggregatedDesc')}</p>
                    </div>

                    {/* NOTE Il grafico lagga perché ci sono troppo tipologie diverse, tanto che la leggenda ricopre lo stesso grafico. */}
                    {/*{emissioniBusinessUnitFlat.length > 0 && <GraficoScope3Bar emissioniFlat={emissioniBusinessUnitFlat} anno={0} />}*/}

                    <TabellaInterattiva
                        synergical={true}
                        titolo={t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.aggregated')}
                        dati={emissioniBusinessUnitFlat}
                        caricamentoFinito={caricamentoFinito}
                        colonne={
                            [
                                {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.business_unit')},
                                {id: 'anno', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.anno')},
                                {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.emissione_t_co2e')},
                                {id: 'tipologia', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.famiglia_primaria')},
                            ]
                        }
                    />
                </div>
            }

            {
                tabellaAttiva === 'emissioni_flat' &&
                <TabellaInterattiva
                    synergical={true}
                    titolo={t('ModuloCarbonFootprint.analisi.use_of_sold_products.tabs.flat')}
                    dati={emissioniFlat}
                        caricamentoFinito={caricamentoFinito}
                    colonne={
                        [
                            {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.business_unit')},
                            {id: 'anno', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.anno')},
                            {id: 'kg_co2e', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.kg_co2e')},
                            {id: 'unita_misura', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.unita_misura')},
                            {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.emissione_t_co2e')},
                            {id: 'prodotto', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.prodotto')},
                            {id: 'famiglia_primaria', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.famiglia_primaria')},
                            {id: 'famiglia_secondaria', label: t('ModuloCarbonFootprint.analisi.use_of_sold_products.columns.famiglia_secondaria')},
                        ]
                    }
                />
            }

        </div>
    )
}
