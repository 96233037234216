import React, {useEffect, useState} from 'react'
import {get} from '../../../../../rest/crud_generica'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'
import MenuGenerico from '../../../../../components/common/MenuGenerico'
import {useTranslation} from 'react-i18next';
import GraficoScope3Bar from '../../grafici/GraficoScope3Bar';
import GraficoScope3Radar from '../../grafici/GraficoScope3Radar';
import {IAnalisiScope3, IAnalisiScope3_tipologia} from '../../../types';

export default function AnalisiBusinessTravel() {

    const {t} = useTranslation()
    const [tabellaAttiva, setTabellaAttiva] = useState<'emissioni_bu_totali_flat' | 'emissioni_bu_flat' | 'emissioni_hotel_flat' | 'emissioni_mode_flat'>('emissioni_bu_totali_flat')
    const [emissioniBusinessUnitTotaliFlat, setEmissioniBusinessUnitTotaliFlat] = useState(new Array<IAnalisiScope3>())
    const [emissioniBusinessUnitFlat, setEmissioniBusinessUnitFlat] = useState(new Array<IAnalisiScope3_tipologia>())
    const [emissioniHotelFlat, setEmissioniHotelFlat] = useState(new Array<IAnalisiScope3>())
    const [emissioniModeFlat, setEmissioniModeFlat] = useState(new Array<IAnalisiScope3>())
    const [caricamentoFinito, setCaricamentoFinito] = useState<boolean>(false)

    useEffect(() => {

        setEmissioniBusinessUnitTotaliFlat(new Array<IAnalisiScope3>())
        setEmissioniBusinessUnitFlat(new Array<IAnalisiScope3_tipologia>())
        setEmissioniHotelFlat(new Array<IAnalisiScope3>())
        setEmissioniModeFlat(new Array<IAnalisiScope3>())
        setCaricamentoFinito(false)

        switch (tabellaAttiva) {

            case ('emissioni_bu_totali_flat'):
                get('carbon_footprint/analisi/scope3/business_travel/emissioni_business_unit_totali_flat')
                    .then((res) => {
                        setEmissioniBusinessUnitTotaliFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

            case ('emissioni_bu_flat'):
                get('carbon_footprint/analisi/scope3/business_travel/emissioni_business_unit_flat')
                    .then((res) => {
                        setEmissioniBusinessUnitFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

            case ('emissioni_hotel_flat'):
                get('carbon_footprint/analisi/scope3/business_travel/emissioni_hotel_flat')
                    .then((res) => {
                        setEmissioniHotelFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

            case ('emissioni_mode_flat'):
                get('carbon_footprint/analisi/scope3/business_travel/emissioni_mode_flat')
                    .then((res) => {
                        setEmissioniModeFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

        }

    }, [tabellaAttiva])

    return (
        <div>
            <MenuGenerico bottoni={[
                {
                    label: t('ModuloCarbonFootprint.analisi.business_travel.tabs.total'),
                    action: () => setTabellaAttiva('emissioni_bu_totali_flat'),
                    attivo: tabellaAttiva === 'emissioni_bu_totali_flat'
                },
                {
                    label: t('ModuloCarbonFootprint.analisi.business_travel.tabs.aggregated'),
                    action: () => setTabellaAttiva('emissioni_bu_flat'),
                    attivo: tabellaAttiva === 'emissioni_bu_flat'
                },
                {
                    label: t('ModuloCarbonFootprint.analisi.business_travel.tabs.hotel'),
                    action: () => setTabellaAttiva('emissioni_hotel_flat'),
                    attivo: tabellaAttiva === 'emissioni_hotel_flat'
                },
                {
                    label: t('ModuloCarbonFootprint.analisi.business_travel.tabs.mode'),
                    action: () => setTabellaAttiva('emissioni_mode_flat'),
                    attivo: tabellaAttiva === 'emissioni_mode_flat'
                },
            ]}/>

            {
                tabellaAttiva === 'emissioni_bu_totali_flat' &&
                <div className='bg-white dark:bg-darkgray-dd'>

                    <div className="py-6 text-center">
                        <h1 className='text-3xl font-bold'>{t('ModuloCarbonFootprint.analisi.business_travel.tabs.total')}</h1>
                        <p className="text-lg">{t('ModuloCarbonFootprint.analisi.business_travel.tabs.totalDesc')}</p>
                    </div>

                    {emissioniBusinessUnitTotaliFlat.length > 0 &&
                        <GraficoScope3Radar title={t('ModuloCarbonFootprint.analisi.business_travel.tabs.total')} emissioniFlat={emissioniBusinessUnitTotaliFlat} anno={0}/>}

                    <TabellaInterattiva
                        synergical={true}
                        titolo={t('ModuloCarbonFootprint.analisi.business_travel.tabs.total')}
                        dati={emissioniBusinessUnitTotaliFlat}
                        caricamentoFinito={caricamentoFinito}
                        colonne={
                            [
                                {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.business_unit')},
                                {id: 'anno', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.anno')},
                                {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.emissione_t_co2e')},
                            ]
                        }
                    />
                </div>
            }

            {
                tabellaAttiva === 'emissioni_bu_flat' &&
                <div className='bg-white dark:bg-darkgray-dd'>

                    <div className="py-6 text-center">
                        <h1 className='text-3xl font-bold'>{t('ModuloCarbonFootprint.analisi.business_travel.tabs.aggregated')}</h1>
                        <p className="text-lg">{t('ModuloCarbonFootprint.analisi.business_travel.tabs.aggregatedDesc')}</p>
                    </div>

                    {emissioniBusinessUnitFlat.length > 0 && <GraficoScope3Bar emissioniFlat={emissioniBusinessUnitFlat} anno={0} />}

                    {emissioniBusinessUnitFlat.length > 0 &&
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <GraficoScope3Radar
                                title={t('ModuloCarbonFootprint.analisi.business_travel.tabs.aggregated') + " 'mode'"}
                                emissioniFlat={emissioniBusinessUnitFlat.filter(item => item.tipologia === 'mode')}
                                anno={0}
                                width={50}
                            />
                            <GraficoScope3Radar
                                title={t('ModuloCarbonFootprint.analisi.business_travel.tabs.aggregated') + " 'hotel'"}
                                emissioniFlat={emissioniBusinessUnitFlat.filter(item => item.tipologia === 'hotel')}
                                anno={0}
                                width={50}
                            />
                        </div>}

                    <TabellaInterattiva
                        synergical={true}
                        titolo={t('ModuloCarbonFootprint.analisi.business_travel.tabs.aggregated')}
                        dati={emissioniBusinessUnitFlat}
                        caricamentoFinito={caricamentoFinito}
                        colonne={
                            [
                                {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.business_unit')},
                                {id: 'anno', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.anno')},
                                {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.emissione_t_co2e')},
                                {id: 'tipologia', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.tipologia')},
                            ]
                        }
                    />
                </div>
            }

            {
                tabellaAttiva === 'emissioni_hotel_flat' &&
                <div className='bg-white dark:bg-darkgray-dd'>

                    <div className="py-6 text-center">
                        <h1 className='text-3xl font-bold'>{t('ModuloCarbonFootprint.analisi.business_travel.tabs.hotel')}</h1>
                        <p className="text-lg">{t('ModuloCarbonFootprint.analisi.business_travel.tabs.hotelDesc')}</p>
                    </div>

                    {emissioniHotelFlat.length > 0 &&
                        <GraficoScope3Radar title={t('ModuloCarbonFootprint.analisi.business_travel.tabs.hotel')} emissioniFlat={emissioniHotelFlat} anno={0}/>}

                    <TabellaInterattiva
                        synergical={true}
                        titolo={t('ModuloCarbonFootprint.analisi.business_travel.tabs.hotel')}
                        dati={emissioniHotelFlat}
                        caricamentoFinito={caricamentoFinito}
                        colonne={
                            [
                                {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.business_unit')},
                                {id: 'anno', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.anno')},
                                {id: 'paese', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.paese')},
                                {id: 'continente', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.continente')},
                                {id: 'numero_soggiorni', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.numero_soggiorni')},
                                {id: 'note', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.note')},
                                {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.emissione_t_co2e')},
                            ]
                        }
                    />
                </div>
            }

            {
                tabellaAttiva === 'emissioni_mode_flat' &&
                <div className='bg-white dark:bg-darkgray-dd'>

                    <div className="py-6 text-center">
                        <h1 className='text-3xl font-bold'>{t('ModuloCarbonFootprint.analisi.business_travel.tabs.mode')}</h1>
                        <p className="text-lg">{t('ModuloCarbonFootprint.analisi.business_travel.tabs.modeDesc')}</p>
                    </div>

                    {emissioniModeFlat.length > 0 &&
                        <GraficoScope3Radar title={t('ModuloCarbonFootprint.analisi.business_travel.tabs.mode')} emissioniFlat={emissioniModeFlat} anno={0}/>}

                    <TabellaInterattiva
                        synergical={true}
                        titolo={t('ModuloCarbonFootprint.analisi.business_travel.tabs.mode')}
                        dati={emissioniModeFlat}
                        caricamentoFinito={caricamentoFinito}
                        colonne={
                            [
                                {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.business_unit')},
                                {id: 'anno', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.anno')},
                                {id: 'travel_mode', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.travel_mode')},
                                {id: 'travel_type', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.travel_type')},
                                {id: 'note', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.note')},
                                {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.business_travel.columns.emissione_t_co2e')},
                            ]
                        }
                    />
                </div>
            }

        </div>
    )
}
