import React, {useEffect} from 'react'

//Data
import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {useStores} from '../../hooks/useStores'

//Components
import {Navbar} from '../../components/common/Navbar'
import {Header} from '../../components/common/Header'
import {AsideModal} from '../../components/common/AsideModal'
import {BigScreenModal} from '../../components/common/BigScreenModal'
import {LogScreenModal} from '../../components/common/LogScreenModal'

export const Logged = observer(() => {

    const {ui, unitaMisura} = useStores();

    //Setta lista unità di misura
    useEffect(() => {
        unitaMisura.settaListaUnitaMisura();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {pathname} = useLocation();

    if (pathname === '/app') {
        return <Navigate to="modulo/dashboard"/>
    }

    return (
        <>
            {/* Redirect al login quando l'utente non è loggato */}
            {!localStorage.getItem('utente') && <Navigate to="/"/>}

            <div className="h-screen flex">

                <Navbar/>

                <div className="grow flex flex-col">
                    <Header/>
                    <div id="pagescroll" className="grow overflow-y-auto scroll-smooth">
                        <Outlet/>
                    </div>
                </div>

                {ui.asideModal && <AsideModal/>}
                {ui.bigScreenModal && <BigScreenModal/>}
                {ui.logScreenModal && <LogScreenModal/>}
            </div>
        </>
    )
})