export const en_modulo_carbon_footprint = {
    common: {
        fattoreDiEmissione: 'Emission factor',
        electricitySupplier: 'Electricity Supplier',
        elettricita: 'Electricity',
        emissioneElettrica: 'Electricity emission',

        veicolo: 'Vehicle',
        gruppoVeicoli: 'Group of vehicles',
        gruppiVeicoli: 'Groups of vehicles',
        veicoli: 'Vehicles',
        dimensione: 'Size',
        tipologia: 'Type',
        numeroVeicoli: 'Number of vehicles',
        chilometraggioTotale: 'Total mileage',
        quantitaCarburante: 'Fuel quantity',
        dimensioneVeicolo: 'Vehicle size',
        dimensioniVeicoli: 'Vehicle sizes',
        tipologiaVeicoli: 'Vehicle type',
        tipologieVeicoli: 'Vehicle types',

        gasRefrigerante: 'Refrigerant Gas',
        gasRefrigeranti: 'Refrigerant gases',
        emissioneGasRefrigerante: 'Refrigerant Gas emission',
        emissioniGasRefrigeranti: 'Refrigerant gases emissions',
        quantitaContenutaGas: 'Contained quantity',
        quantitaRicaricataGas: 'Recharged quantity',

        carburante: 'Fuel',
        carburanti: 'Fuels',
        nomeImpianto: 'Facility / plant name',

        nonHaiAccesso: 'You have no access to any business unit that allows you to report on this section.',
        filename: 'File Name',
        validazione: 'File Control',
        anno: 'Year',
        tipoViaggio: 'Travel Type',
        unitaMisura: 'unit of measure',
        erroregenerico: 'General Error',

        yes: 'Yes',
        no: 'No'
    },

    rendCarburanti: {
        titolo: 'Fuel emissions for the business unit: ',
        aggiungi: 'Add emission',

        successoEliminazione: 'Fuel emission successfully deleted',
        erroreEliminazione: 'Error deleting the fuel emission',

        successoValidazione: 'Fuel emission successfully validated',
        erroreValidazione: 'Error validating the fuel emission',

        successoRimValidazione: 'Validation successfully removed',
        erroreRimValidazione: 'Error during validation removing',

        form: {
            titoloModifica: 'Edit the fuel emission for the business unit:',
            titoloCreazione: 'Add a fuel emission for the business unit:',

            successoCreazione: 'Fuel emission successfully added',
            erroreCreazione: 'Error adding the fuel emission',

            successoModifica: 'Fuel emission successfully edited',
            erroreModifica: 'Error editing the fuel emission',
        }
    },

    rendGas: {
        titolo: 'Refrigerant gas emissions for the business unit: ',
        aggiungi: 'Add emission',

        successoEliminazione: 'Refrigerant gas emission successfully deleted',
        erroreEliminazione: 'Error deleting the refrigerant gas emission',

        successoValidazione: 'Refrigerant gas emission successfully validated',
        erroreValidazione: 'Error validating the refrigerant gas emission',

        successoRimValidazione: 'Validation successfully removed',
        erroreRimValidazione: 'Error during validation removing',

        form: {
            titoloModifica: 'Edit the refrigerant gas emission for the business unit:',
            titoloCreazione: 'Add a refrigerant gas emission for the business unit:',

            successoCreazione: 'Refrigerant gas emission successfully added',
            erroreCreazione: 'Error adding the refrigerant gas emission',

            successoModifica: 'Refrigerant gas emission successfully edited',
            erroreModifica: 'Error editing the refrigerant gas emission',
        }
    },

    rendVeicoli: {
        titolo: 'Group of vehicles for the business unit: ',
        aggiungi: 'Add group of vehicles',

        successoEliminazione: 'Group of vehicles successfully deleted',
        erroreEliminazione: 'Error deleting the Group of vehicles',

        successoValidazione: 'Group of vehicles emission successfully validated',
        erroreValidazione: 'Error validating the group of vehicles emission',

        successoRimValidazione: 'Validation successfully removed',
        erroreRimValidazione: 'Error during validation removing',

        form: {
            titoloModifica: 'Edit the Group of vehicles for the business unit:',
            titoloCreazione: 'Add a Group of vehicles for the business unit:',

            successoCreazione: 'Group of vehicles successfully added',
            erroreCreazione: 'Error adding the Group of vehicles',

            successoModifica: 'Group of vehicles successfully edited',
            erroreModifica: 'Error editing the Group of vehicles',
        }
    },

    rendElettricita: {
        titolo: 'Electricity emissions for the business unit: ',
        aggiungi: 'Add emission',

        successoEliminazione: 'Electricity emission successfully deleted',
        erroreEliminazione: 'Error deleting the electricity emission',

        nonEsistonFattoriDiEmissionePaese: 'There are no emission factors for the country',
        questoValoreUtilizzeraFattore: 'This emission will use the emission factors for the country',
        perAnno: 'for the year',

        successoValidazione: 'Electricity emission successfully validated',
        erroreValidazione: 'Error validating the electricity emission',

        successoRimValidazione: 'Validation successfully removed',
        erroreRimValidazione: 'Error during validation removing',

        form: {
            titoloModifica: 'Edit the electricity emission for the business unit:',
            titoloCreazione: 'Add a electricity emission for the business unit:',

            successoCreazione: 'Electricity emission successfully added',
            erroreCreazione: 'Error adding the electricity emission',

            successoModifica: 'Electricity emission successfully edited',
            erroreModifica: 'Error editing the electricity emission',
        }
    },

    rendCaricamento: {

        titolo: 'Upload file',

        successoEliminazione: 'File deleted successfully',
        erroreEliminazione: 'Error while deleting file',
        successoAvvio: 'File processed successfully',
        erroreAvvio: 'Error while processing the file',
        aggiungi: 'Add new file',
        download: 'Download File',
        erroreDownload: 'Error while downloading file',

        form: {
            titoloModificaValidizone: 'Edit control data',
            fileinesistente: '',
            successoModifica: 'Edit file successfully',
            successoCreazione: 'Add file successfully',
            titoloModifica: 'Edit existing file',
            titoloCreazione: 'Add file',

            metricaAmericana: 'In which measurement system were the quantities expressed in tonnes entered?',
            tMetrics: 'Metric Tons',
            tAmericans: 'American Tons',

            spedizioniAggregate: 'Are the quantities entered in the \'Weight\' and \'Distance\' columns of sheet 3.4 to be considered aggregated, and therefore to be divided by the \'Number of shipments made\', or per single shipment?',
            aggregated: 'Aggregate',
            notAggregated: 'Not Aggregate',
        }
    },

    analisi: {
        grafico: {
            emissione_t_co2e: 'Emissions (t/co2e)',
        },

        business_travel: {
            tabs: {
                total: 'Total emissions by business unit',
                totalDesc: 'Distribution of indirect emissions by business unit (t/co2e)',
                aggregated: 'Details of emissions by business unit',
                hotel: 'List of Single Issues - Hotel',
                mode: 'List of Single Issues - Movements',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Year',
                emissione_t_co2e: 'Emissions (t/co2e)',
                tipologia: 'Typology',
                paese: 'Village',
                continente: 'Continent',
                travel_mode: 'Travel mode',
                travel_type: 'Travel type',
                numero_soggiorni: 'Number of Stays',
                note: 'Note',
            }
        },

        employee_commuting: {
            tabs: {
                total: 'Total emissions by business unit',
                totalDesc: 'Distribution of indirect emissions by business unit (t/co2e)',
                aggregated: 'Details of emissions by business unit',
                flat: 'List of Single Issues',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Year',
                tipologia: 'Typology',
                emissione_t_co2e: 'Emissions (t/co2e)',
                kg_co2e: 'Emissive factor (kg/co2e)',
                unita_misura: 'Unit of measure',
                travel_mode: 'Travel mode',
                travel_type: 'Travel type',
            }
        },

        upstream_transport: {
            tabs: {
                total: 'Total emissions by business unit',
                totalDesc: 'Distribution of indirect emissions by business unit (t/co2e)',
                aggregated: 'Details of emissions by business unit',
                flat: 'List of Single Issues',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Year',
                emissione_t_co2e: 'Emissions (t/co2e)',
                kg_co2e: 'Emissive factor (kg/co2e)',
                unita_misura: 'Unit of measure',
                metodo_trasporto: 'Shipping method',
                tipo_trasporto: 'Carrier type',
                prodotto: 'Product',
            }
        },

        use_of_sold_products: {
            tabs: {
                total: 'Total emissions by business unit',
                totalDesc: 'Distribution of indirect emissions by business unit (t/co2e)',
                aggregated: 'Details of emissions by business unit',
                flat: 'List of Single Issues',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Year',
                emissione_t_co2e: 'Emissions (t/co2e)',
                kg_co2e: 'Emissive factor (kg/co2e)',
                unita_misura: 'Unit of measure',
                famiglia_primaria: 'Primary product family',
                famiglia_secondaria: 'Secondary product family',
                prodotto: 'Product',
            }
        },

        waste: {
            tabs: {
                total: 'Total emissions by business unit',
                totalDesc: 'Distribution of indirect emissions by business unit (t/co2e)',
                aggregated: 'Details of emissions by business unit',
                waste: 'List of Single Issues - Waste',
                wastewater: 'List of Single Issues - Wastewater',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Year',
                emissione_t_co2e: 'Emissions (t/co2e)',
                kg_co2e: 'Emissive factor (kg/co2e)',
                kg_co2e_w1: 'Fattore emissivo (kg/co2e)\nIncineration with Energy Recovery',
                kg_co2e_w2: 'Fattore emissivo (kg/co2e)\nIncineration without Energy Recovery',
                kg_co2e_w3: 'Fattore emissivo (kg/co2e)\nDelivery to Landfill',
                kg_co2e_w4: 'Fattore emissivo (kg/co2e)\nOther Disposal Operations',
                unita_misura: 'Unit of measure',
                tipologia: 'Tipology',
                codice_waste: 'Waste code',
                waste_category: 'Waste category',
                materiale: 'Material',
                disposal: 'Disposal',
            }
        },
    }
}
