import React from 'react'
import ReactEcharts from 'echarts-for-react'
import {useTranslation} from 'react-i18next';
import {IAnalisiScope3} from '../../types';

const GraficoScope3Radar: React.FC<{
    title: string,
    emissioniFlat: IAnalisiScope3[],
    anno: number,
    width?: number
}> = ({title, emissioniFlat, anno, width}) => {

    const {t} = useTranslation()

    const lineStyle = {
        width: 7,
        opacity: 0.5
    };

    // Lista emissioni valide
    emissioniFlat.filter(item => item.emissione_t_co2e > 0) // do not reassign to 'emissioniFlat'

    let setBu: Set<string> = new Set()
    emissioniFlat.forEach((item) => setBu.add(item.business_unit))
    const listaBu = Array.from(setBu);

    let listaAnni: Set<number> = new Set()
    emissioniFlat.forEach((item) => listaAnni.add(item.anno))

    // Lista emissioni per anno
    const dictEmissioni: { [anno: number]: number[] } = {};
    emissioniFlat.forEach((item: IAnalisiScope3) => {
        const { anno, business_unit } = item;
        if (!dictEmissioni[anno]) {
            dictEmissioni[anno] = new Array<number>(listaBu.length).fill(0)
        }
        dictEmissioni[anno][listaBu.indexOf(business_unit)] = item.emissione_t_co2e
    });

    // Serie emissioni per anno
    type SerieDictionary = {
        name: string
        type: string
        colorBy: string
        data: { value: number[]; name: string }[]
    }
    const serie:SerieDictionary[] = []

    for (const anno in dictEmissioni) {
        serie.push({
            type: 'radar',
            name: anno,
            colorBy: 'series',
            data: [
                {
                    value: dictEmissioni[anno],
                    name: t('ModuloCarbonFootprint.analisi.grafico.emissione_t_co2e') + ' ' + anno
                }
            ]
        })
    }

    let emissione_max = Math.max(...emissioniFlat.map((item) => item.emissione_t_co2e))

    // Indicatori ruota
    let indicatori: object[] = []
    listaBu.forEach((bu: string) => {
        indicatori.push({
            name: bu,
            max: emissione_max
        })
    })

    // See also https://echarts.apache.org/en/option.html#series-radar
    let option = {
        title: {
            show: true,
            text: title,
        },
        legend: {
            data: Object.keys(dictEmissioni),
            top: 'bottom' // spostata in basso per evitare la sovrapposizione con il titolo
        },
        tooltip: {
            trigger: 'item'
        },
        toolbox: {
            show: true,
            feature: {
                mark: {show: true},
                dataView: {show: true, readOnly: false},
                restore: {show: true},
                saveAsImage: {show: true}
            }
        },
        radar: {
            indicator: indicatori,
            splitNumber: 5,
            axisName: {
                color: 'rgb(0, 46, 90)'
            },
            splitLine: {
                lineStyle: {
                    color: 'rgba(0,0,0,0.5)'
                }
            },
            lineStyle: lineStyle,
            itemStyle: {
                color: 'rgb(0,46,90)'
            },
            label: {
                show: true,
                formatter: function (params: any) {
                    return params.value;
                }
            }
        },
        series: serie
    };

    let widthGraph = width??100
    return (
        <div className="h-[600px] p-4" style={{width: widthGraph + '%'}}>
            <ReactEcharts style={{height: '100%'}} option={option}/>
        </div>
    )
}

export default GraficoScope3Radar;
