import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import {
    getListaBusinessUnits,
    creaBusinessUnit,
    modificaBusinessUnit,
    eliminaBusinessUnit,
    attribuisciBusinessUnitRuolo
} from '../rest/business_units'
import { IBusinessUnit, IBusinessUnitRuolo } from '../types'

export class BusinessUnitsStore {

    listaBusinessUnits: IBusinessUnit[] = []
    gettingListaBusinessUnits: boolean = false

    creatingBusinessUnit: boolean = false
    creaBusinessUnitError: string = ''
    businessUnitCreata: boolean = false

    editingBusinessUnit: boolean = false
    modificaBusinessUnitError: string = ''
    businessUnitModificata: boolean = false

    deletingBusinessUnit: boolean = false
    eliminaBusinessUnitError: string = ''
    businessUnitEliminata: boolean = false

    doingAttribuisciRuolo: boolean = false
    attribuisciRuoloError: string = ''
    ruoloAttribuito: boolean = false

    constructor() {
        makeAutoObservable(this, {
            listaBusinessUnits: observable,
            gettingListaBusinessUnits: observable,

            getListaBusinessUnits: action,

            //Crea business unit
            creaBusinessUnit: action,
            creatingBusinessUnit: observable,
            creaBusinessUnitError: observable,
            businessUnitCreata: observable,

            //Modifica business unit
            modificaBusinessUnit: action,
            editingBusinessUnit: observable,
            modificaBusinessUnitError: observable,
            businessUnitModificata: observable,

            //Elimina business unit
            eliminaBusinessUnit: action,
            deletingBusinessUnit: observable,
            eliminaBusinessUnitError: observable,
            businessUnitEliminata: observable,

            //Attribuisci ruolo business unit
            attribuisciBusinessUnitRuolo: action,
            doingAttribuisciRuolo: observable,
            attribuisciRuoloError: observable,
            ruoloAttribuito: observable
        })
    }

    getListaBusinessUnits = async () => {
        this.gettingListaBusinessUnits = true

        getListaBusinessUnits().then((res) => {

            this.listaBusinessUnits = res.data.data

        }).catch((err) => {

            console.log(err)

        }).finally(() => {

            this.gettingListaBusinessUnits = false

        })
    }

    creaBusinessUnit = async (businessUnit: IBusinessUnit) => {
        this.creatingBusinessUnit = true
        await creaBusinessUnit(businessUnit).then(res => {

            runInAction(() => this.creaBusinessUnitError = '')

        }).catch(err => {

            runInAction(() => this.creaBusinessUnitError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {

            runInAction(() => this.creatingBusinessUnit = false)
            runInAction(() => this.businessUnitCreata = !this.businessUnitCreata)

        })
    }

    modificaBusinessUnit = async (nomeOriginale: string, businessUnit: IBusinessUnit) => {
        this.editingBusinessUnit = true

        await modificaBusinessUnit(nomeOriginale, businessUnit).then(res => {

            runInAction(() => this.modificaBusinessUnitError = '')

        }).catch(err => {

            runInAction(() => this.modificaBusinessUnitError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {

            runInAction(() => this.editingBusinessUnit = false)
            runInAction(() => this.businessUnitModificata = !this.businessUnitModificata)

        })
    }

    eliminaBusinessUnit = async (nome: string) => {
        this.deletingBusinessUnit = true

        await eliminaBusinessUnit(nome).then(res => {

            runInAction(() => this.eliminaBusinessUnitError = '')

        }).catch(err => {

            runInAction(() => this.eliminaBusinessUnitError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {

            runInAction(() => this.deletingBusinessUnit = false)
            runInAction(() => this.businessUnitEliminata = !this.businessUnitEliminata)

        })
    }

    attribuisciBusinessUnitRuolo = async (nome: string, ruoli: IBusinessUnitRuolo[]) => {
        this.doingAttribuisciRuolo = true

        await attribuisciBusinessUnitRuolo(nome, ruoli).then(res => {

            runInAction(() => this.attribuisciRuoloError = '')

        }).catch(err => {

            runInAction(() => this.attribuisciRuoloError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {

            runInAction(() => this.doingAttribuisciRuolo = false)

            runInAction(() => this.ruoloAttribuito = !this.ruoloAttribuito)

        })
    }
}