import React, {useEffect, useState} from 'react'
import ReactEcharts from 'echarts-for-react'
import MenuGenerico from '../../../../components/common/MenuGenerico'
import {IAnalisiCarbonFootprintAggregato} from "../../types";

const GraficoAggregatoEmissioniAnno: React.FC<{ aggregatoEmissioniAnno: IAnalisiCarbonFootprintAggregato[] }> = ({aggregatoEmissioniAnno}) => {

    const nodeTotaleName: string = "Totale"

    //Genera nodi e link -> combustibile
    let nodesCombustibile: {name: string}[] = [{name: nodeTotaleName}]
    let linksCombustibile: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_combustibile > 0) {
            nodesCombustibile.push({name: emissione.business_unit})
            linksCombustibile.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_combustibile
            })
        }
    })

    //Genera nodi e link -> gas
    let nodesGas: {name: string}[] = [{name: nodeTotaleName}]
    let linksGas: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_gas_refrigerante > 0) {
            nodesGas.push({name: emissione.business_unit})
            linksGas.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_gas_refrigerante
            })
        }
    })

    //Genera nodi e link -> veicoli
    let nodesVeicoli: {name: string}[] = [{name: nodeTotaleName}]
    let linksVeicoli: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_veicoli > 0) {
            nodesVeicoli.push({name: emissione.business_unit})
            linksVeicoli.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_veicoli
            })
        }
    })

    //Genera nodi e link -> elettricità
    let nodesElettricita: {name: string}[] = [{name: nodeTotaleName}]
    let linksElettricita: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_elettricita > 0) {
            nodesElettricita.push({name: emissione.business_unit})
            linksElettricita.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_elettricita
            })
        }
    })

    //Genera nodi e link -> upstream transport
    let nodesUpstream: {name: string}[] = [{name: nodeTotaleName}]
    let linksUpstream: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_upstream_transport > 0) {
            nodesUpstream.push({name: emissione.business_unit})
            linksUpstream.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_upstream_transport
            })
        }
    })

    //Genera nodi e link -> waste
    let nodesWaste: {name: string}[] = [{name: nodeTotaleName}]
    let linksWaste: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_waste > 0) {
            nodesWaste.push({name: emissione.business_unit})
            linksWaste.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_waste
            })
        }
    })

    //Genera nodi e link -> wastewater
    let nodesWastewater: {name: string}[] = [{name: nodeTotaleName}]
    let linksWastewater: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_wastewater > 0) {
            nodesWastewater.push({name: emissione.business_unit})
            linksWastewater.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_wastewater
            })
        }
    })

    //Genera nodi e link -> waste: other disposal
    let nodesOther: {name: string}[] = [{name: nodeTotaleName}]
    let linksOther: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_other_disposal > 0) {
            nodesOther.push({name: emissione.business_unit})
            linksOther.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_other_disposal
            })
        }
    })

    //Genera nodi e link -> business travel: hotel
    let nodesBusinessTravelHotel: {name: string}[] = [{name: nodeTotaleName}]
    let linksBusinessTravelHotel: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_business_travel_hotel > 0) {
            nodesBusinessTravelHotel.push({name: emissione.business_unit})
            linksBusinessTravelHotel.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_business_travel_hotel
            })
        }
    })

    //Genera nodi e link -> business travel: mode
    let nodesBusinessTravelMode: {name: string}[] = [{name: nodeTotaleName}]
    let linksBusinessTravelMode: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_business_travel_mode > 0) {
            nodesBusinessTravelMode.push({name: emissione.business_unit})
            linksBusinessTravelMode.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_business_travel_mode
            })
        }
    })

    //Genera nodi e link -> employee commuting
    let nodesEmployeeCommuting: {name: string}[] = [{name: nodeTotaleName}]
    let linksEmployeeCommuting: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_employee_commuting > 0) {
            nodesEmployeeCommuting.push({name: emissione.business_unit})
            linksEmployeeCommuting.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_employee_commuting
            })
        }
    })

    //Genera nodi e link -> Use of sold products
    let nodesUseProducts: {name: string}[] = [{name: nodeTotaleName}]
    let linksUseProducts: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_use_of_sold_products > 0) {
            nodesUseProducts.push({name: emissione.business_unit})
            linksUseProducts.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_use_of_sold_products
            })
        }
    })

    //Genera nodi e link -> totali
    let nodesTotali: {name: string}[] = [{name: nodeTotaleName}]
    let linksTotali: any[] = []
    aggregatoEmissioniAnno.forEach((emissione) => {
        if (emissione.emissione_cumulata_t_co2e_totale > 0) {
            nodesTotali.push({name: emissione.business_unit})
            linksTotali.push({
                "source": emissione.business_unit,
                "target": emissione.business_unit_padre ? emissione.business_unit_padre : nodeTotaleName,
                "value": emissione.emissione_cumulata_t_co2e_totale
            })
        }
    })

    const [emissioniAttive, setEmissioniAttive] = useState<'totale' | 'combustibile' | 'gas_refrigeranti' | 'veicoli' | 'elettricita' | 'upstream' | 'waste' | 'wastewater' | 'other_disposal' | 'bt_hotel' | 'bt_mode' | 'employee' | 'use_products'>('totale')
    const [nodiEmissioni, setNodiEmissioni] = useState<any[]>(nodesTotali)
    const [datiEmissioni, setDatiEmissioni] = useState<any[]>(linksTotali)

    useEffect(() => {
        switch (emissioniAttive) {

            case ('totale'):
                setNodiEmissioni(nodesTotali)
                setDatiEmissioni(linksTotali)
                break;

            case ('combustibile'):
                setNodiEmissioni(nodesCombustibile)
                setDatiEmissioni(linksCombustibile)
                break;

            case ('gas_refrigeranti'):
                setNodiEmissioni(nodesGas)
                setDatiEmissioni(linksGas)
                break;

            case ('veicoli'):
                setNodiEmissioni(nodesVeicoli)
                setDatiEmissioni(linksVeicoli)
                break;

            case ('elettricita'):
                setNodiEmissioni(nodesElettricita)
                setDatiEmissioni(linksElettricita)
                break;

            case ('upstream'):
                setNodiEmissioni(nodesUpstream)
                setDatiEmissioni(linksUpstream)
                break;

            case ('waste'):
                setNodiEmissioni(nodesWaste)
                setDatiEmissioni(linksWaste)
                break;

            case ('wastewater'):
                setNodiEmissioni(nodesWastewater)
                setDatiEmissioni(linksWastewater)
                break;

            case ('other_disposal'):
                setNodiEmissioni(nodesOther)
                setDatiEmissioni(linksOther)
                break;

            case ('bt_hotel'):
                setNodiEmissioni(nodesBusinessTravelHotel)
                setDatiEmissioni(linksBusinessTravelHotel)
                break;

            case ('bt_mode'):
                setNodiEmissioni(nodesBusinessTravelMode)
                setDatiEmissioni(linksBusinessTravelMode)
                break;

            case ('employee'):
                setNodiEmissioni(nodesEmployeeCommuting)
                setDatiEmissioni(linksEmployeeCommuting)
                break;

            case ('use_products'):
                setNodiEmissioni(nodesUseProducts)
                setDatiEmissioni(linksUseProducts)
                break;

        }
    }, [emissioniAttive])

    let option = {
        toolbox: {
            show: true,
            feature: {
                mark: {show: true},
                dataView: {show: true, readOnly: false},
                restore: {show: true},
                saveAsImage: {show: true}
            }
        },
        tooltip: {
            trigger: 'item',
            triggerOn: 'mousemove'
        },
        series: [
            {
                type: 'sankey',
                name: 'Combustibile',
                data: nodiEmissioni,
                nodeAlign: 'right',
                links: datiEmissioni,
                emphasis: {
                    focus: 'adjacency'
                },
                lineStyle: {
                    color: 'gradient',
                    curveness: 0.5
                }
            }
        ]
    }

    return (
        <div className="my-4">
            <MenuGenerico bottoni={[
                {
                    label: 'Totali',
                    action: () => setEmissioniAttive('totale'),
                    attivo: emissioniAttive === 'totale',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Combustibile',
                    action: () => setEmissioniAttive('combustibile'),
                    attivo: emissioniAttive === 'combustibile',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Gas Refrigeranti',
                    action: () => setEmissioniAttive('gas_refrigeranti'),
                    attivo: emissioniAttive === 'gas_refrigeranti',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Veicoli',
                    action: () => setEmissioniAttive('veicoli'),
                    attivo: emissioniAttive === 'veicoli',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Elettricità',
                    action: () => setEmissioniAttive('elettricita'),
                    attivo: emissioniAttive === 'elettricita',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Upstream Transport',
                    action: () => setEmissioniAttive('upstream'),
                    attivo: emissioniAttive === 'upstream',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Waste',
                    action: () => setEmissioniAttive('waste'),
                    attivo: emissioniAttive === 'waste',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Wastewater',
                    action: () => setEmissioniAttive('wastewater'),
                    attivo: emissioniAttive === 'wastewater',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Other Disposal',
                    action: () => setEmissioniAttive('other_disposal'),
                    attivo: emissioniAttive === 'other_disposal',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Business Travel by Hotel',
                    action: () => setEmissioniAttive('bt_hotel'),
                    attivo: emissioniAttive === 'bt_hotel',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Business Travel by Mode',
                    action: () => setEmissioniAttive('bt_mode'),
                    attivo: emissioniAttive === 'bt_mode',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Employee Commuting',
                    action: () => setEmissioniAttive('employee'),
                    attivo: emissioniAttive === 'employee',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
                {
                    label: 'Use of Sold Products',
                    action: () => setEmissioniAttive('use_products'),
                    attivo: emissioniAttive === 'use_products',
                    classi: 'bg-[#eee] dark:bg-[#111]'
                },
            ]}/>
            <hr className='border-2 border-[#eee] dark:border-[#111]'/>
            <ReactEcharts style={{minHeight: 700}} option={option}/>
        </div>
    )
}

export default GraficoAggregatoEmissioniAnno;
