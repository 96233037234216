import React from 'react'
import { useTranslation } from 'react-i18next';
import { useStores } from '../../hooks/useStores'

export default function ConfermaChiusuraLogScreenModal() {

    const { t } = useTranslation()

    const { ui } = useStores();

    const styles = {
        logScreenSureButton: "capitalize w-full text-center p-2 text-xs cursor-pointer rounded-sm bg-red dark:bg-dark-red text-white",
        logScreenSureSecondaryButton: "capitalize w-full text-center p-2 text-xs cursor-pointer rounded-sm text-white"
    }

    return (
        <div className="bg-[rgba(0,0,0,0.1)] z-[600] fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <div className="bg-white dark:bg-darkgray-dd shadow-xl p-4">
                <p className="text-sm">{t('common.disclaimerModificheNonSalvate')}</p>

                <div className="gap-2 flex mt-2 flex-col lg:flex-row">

                    <button
                        autoFocus
                        className={styles.logScreenSureButton}
                        onClick={() => ui.unmountLogScreenModal()}>
                        {t('common.chiudi')}
                    </button>

                    <button
                        className={styles.logScreenSureSecondaryButton}
                        onClick={() => ui.unmountConfermaChiusuraLogScreenModal()}>
                        {t('common.rimani')}
                    </button>

                </div>

            </div>
        </div>
    )
}
