export const it_modulo_carbon_footprint = {
    common: {
        fattoreDiEmissione: 'Fattore di emissione',
        electricitySupplier: 'Fornitore di energia elettrica',
        elettricita: 'Elettricità',
        emissioneElettrica: 'Emissione elettrica',

        veicolo: 'Veicolo',
        gruppoVeicoli: 'Gruppo di veicoli',
        gruppiVeicoli: 'Gruppi di veicoli',
        veicoli: 'Veicoli',
        dimensione: 'Dimensione',
        tipologia: 'Tipologia',
        numeroVeicoli: 'Numero di veicoli',
        chilometraggioTotale: 'Chilometraggio totale',
        quantitaCarburante: 'Quantità di carburante',
        dimensioneVeicolo: 'Dimensione veicolo',
        dimensioniVeicoli: 'Dimensioni veicoli',
        tipologiaVeicoli: 'Tipologia veicolo',
        tipologieVeicoli: 'Tipologie veicoli',

        gasRefrigerante: 'Gas refrigerante',
        gasRefrigeranti: 'Gas refrigeranti',
        emissioneGasRefrigerante: 'Emissione di gas refrigerante',
        emissioniGasRefrigeranti: 'Emissioni di gas refrigerante',
        quantitaContenutaGas: 'Quantità contenuta',
        quantitaRicaricataGas: 'Quantità ricaricata',

        carburante: 'Carburante',
        carburanti: 'Carburanti',
        nomeImpianto: 'Nome sede / impianto',

        nonHaiAccesso: 'Non hai accesso a nessuna unità che possieda il privilegio di rendicontare in questa sezione',
        filename: 'Nome File',
        validazione: 'Controllo file',
        anno: 'Anno',
        tipoViaggio: 'Tipo Viaggio',
        unitaMisura: 'Unità di Misura',
        erroregenerico: 'Errore Generico',

        yes: 'Sì',
        no: 'No'
    },

    rendCarburanti: {
        titolo: 'Emissioni di carburante per la business unit: ',
        aggiungi: 'Aggiungi emissione',

        successoEliminazione: 'Emissione di carburante eliminata con successo',
        erroreEliminazione: 'Errore durante l\'eliminazione dell\'emissione di carburante',

        successoValidazione: 'Emissione di carburante validata con successo',
        erroreValidazione: 'Errore durante la validazione dell\'emissione di carburante',

        successoRimValidazione: 'Validazione rimossa con successo',
        erroreRimValidazione: 'Errore durante la rimozione della validazione dall\'emissione di carburante',

        form: {
            titoloModifica: 'Modifica l\'emissione di carburante per la business unit:',
            titoloCreazione: 'Aggiungi un\'emissione di carburante per la business unit:',

            successoCreazione: 'Emissione di carburante inserita con successo',
            erroreCreazione: 'Errore durante l\'inserimento dell\'emissione di carburante',

            successoModifica: 'Emissione di carburante modificata con successo',
            erroreModifica: 'Errore durante la modifica dell\'emissione di carburante',
        }
    },

    rendGas: {
        titolo: 'Emissioni di gas refrigeranti per la business unit: ',
        aggiungi: 'Aggiungi emissione',

        successoEliminazione: 'Emissione di gas eliminata con successo',
        erroreEliminazione: 'Errore durante l\'eliminazione dell\'emissione di gas',

        successoValidazione: 'Emissione di gas validata con successo',
        erroreValidazione: 'Errore durante la validazione dell\'emissione di gas',

        successoRimValidazione: 'Validazione rimossa con successo',
        erroreRimValidazione: 'Errore durante la rimozione della validazione dall\'emissione di gas',

        form: {
            titoloModifica: 'Modifica l\'emissione di gas per la business unit:',
            titoloCreazione: 'Aggiungi un\'emissione di gas per la business unit:',

            successoCreazione: 'Emissione di gas inserita con successo',
            erroreCreazione: 'Errore durante l\'inserimento dell\'emissione di gas',

            successoModifica: 'Emissione di gas modificata con successo',
            erroreModifica: 'Errore durante la modifica dell\'emissione di gas',
        }
    },

    rendVeicoli: {
        titolo: 'Gruppi di veioli per la business unit: ',
        aggiungi: 'Aggiungi gruppo di veicoli',

        successoEliminazione: 'Gruppo di veicoli eliminato con successo',
        erroreEliminazione: 'Errore durante l\'eliminazione del gruppo di veicoli',

        successoValidazione: 'Emissione del gruppo di veicoli con successo',
        erroreValidazione: 'Errore durante la validazione del gruppo di veicoli',

        successoRimValidazione: 'Validazione rimossa con successo',
        erroreRimValidazione: 'Errore durante la rimozione della validazione dal gruppo di veicoli',

        form: {
            titoloModifica: 'Modifica il gruppo di veicoli per la business unit:',
            titoloCreazione: 'Aggiungi un gruppo di veicoli per la business unit:',

            successoCreazione: 'Gruppo di veicoli inserito con successo',
            erroreCreazione: 'Errore durante l\'inserimento gruppo di veicoli',

            successoModifica: 'Gruppo di veicoli modificato con successo',
            erroreModifica: 'Errore durante la modifica del gruppo di veicoli',
        }
    },

    rendElettricita: {
        titolo: 'Emissioni di elettricità per la business unit: ',
        aggiungi: 'Aggiungi emissione',

        successoEliminazione: 'Emissione elettrica eliminata con successo',
        erroreEliminazione: 'Errore durante l\'eliminazione dell\'emissione elettrica',

        nonEsistonFattoriDiEmissionePaese: 'Non esistono fattori di emissione per il paese',
        questoValoreUtilizzeraFattore: 'Questa emissione utilizzerà i fattori di emissione del paese',
        perAnno: 'per l\'anno',

        successoValidazione: 'Emissione di elettricità validata con successo',
        erroreValidazione: 'Errore durante la validazione dell\'emissione di elettricità',

        successoRimValidazione: 'Validazione rimossa con successo',
        erroreRimValidazione: 'Errore durante la rimozione della validazione dall\'emissione di elettricità',

        form: {
            titoloModifica: 'Modifica l\'emissione elettrica per la business unit:',
            titoloCreazione: 'Aggiungi un\'emissione elettrica per la business unit:',

            successoCreazione: 'Emissione elettrica inserita con successo',
            erroreCreazione: 'Errore durante l\'inserimento dell\'emissione elettrica',

            successoModifica: 'Emissione elettrica modificata con successo',
            erroreModifica: 'Errore durante la modifica dell\'emissione elettrica',
        }
    },

    rendCaricamento: {

        titolo: 'Caricamento massivo dei file',

        successoEliminazione: 'File eliminato con successo',
        erroreEliminazione: 'Errore durante l\'eliminazione del file',
        successoAvvio: 'File elaborato con successo',
        erroreAvvio: 'Errore durante l\'elaborazione del file',
        aggiungi: 'Aggiungi nuovo file',
        download: 'Scarica File',
        erroreDownload: 'Errore durante il download del file',

        form: {
            titoloModificaValidizone: 'Modifica controlla dati',
            fileinesistente: 'File Inesistente',
            successoModifica: 'File modificato con successo',
            successoCreazione: 'File caricato con successo',
            titoloModifica: 'Modifica file esistente',
            titoloCreazione: 'Caricamento file...',

            metricaAmericana: 'In quale sistema di misurazione sono state inserite le quantità espresse in tonnellate?',
            tMetrics: 'Tonnellate Metriche',
            tAmericans: 'Tonnellate Americane',

            spedizioniAggregate: 'Le quantità inserite nella colonne \'Peso\' e \'Distanza\' del foglio 3.4 sono da considerarsi aggregate, e quindi da dividere per il \'Numero di spedizioni fatte\', oppure per singola spedizione?',
            aggregated: 'Aggregate',
            notAggregated: 'Non Aggregate',
        }
    },

    analisi: {
        grafico: {
            emissione_t_co2e: 'Emissioni (t/co2e)',
        },

        business_travel: {
            tabs: {
                total: 'Emissioni totali per business unit',
                totalDesc: 'Distribuzione delle emissioni indirette per business unit (t/co2e)',
                aggregated: 'Dettagli emissioni per business unit',
                hotel: 'Lista Singole emissioni - Hotel',
                mode: 'Lista Singole emissioni - Spostamenti',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Anno',
                emissione_t_co2e: 'Emissioni (t/co2e)',
                tipologia: 'Tipologia',
                paese: 'Paese',
                continente: 'Continente',
                travel_mode: 'Modalità di viaggio',
                travel_type: 'Tipologia di viaggio',
                numero_soggiorni: 'Numero Soggiorni',
                note: 'Note',
            }
        },

        employee_commuting: {
            tabs: {
                total: 'Emissioni totali per business unit',
                totalDesc: 'Distribuzione delle emissioni indirette per business unit (t/co2e)',
                aggregated: 'Dettagli emissioni per business unit',
                flat: 'Lista Singole emissioni',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Anno',
                tipologia: 'Tipologia',
                emissione_t_co2e: 'Emissioni (t/co2e)',
                kg_co2e: 'Fattore emissivo (kg/co2e)',
                unita_misura: 'Unità di misura',
                travel_mode: 'Modalità di viaggio',
                travel_type: 'Tipologia di viaggio',
            }
        },

        upstream_transport: {
            tabs: {
                total: 'Emissioni totali per business unit',
                totalDesc: 'Distribuzione delle emissioni indirette per business unit (t/co2e)',
                aggregated: 'Dettagli emissioni per business unit',
                flat: 'Lista Singole emissioni',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Anno',
                emissione_t_co2e: 'Emissioni (t/co2e)',
                kg_co2e: 'Fattore emissivo (kg/co2e)',
                unita_misura: 'Unità di misura',
                metodo_trasporto: 'Metodo di spedizione',
                tipo_trasporto: 'Tipo di corriere',
                prodotto: 'Prodotto',
            }
        },

        use_of_sold_products: {
            tabs: {
                total: 'Emissioni totali per business unit',
                totalDesc: 'Distribuzione delle emissioni indirette per business unit (t/co2e)',
                aggregated: 'Dettagli emissioni per business unit',
                flat: 'Lista Singole emissioni',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Anno',
                emissione_t_co2e: 'Emissioni (t/co2e)',
                kg_co2e: 'Fattore emissivo (kg/co2e)',
                unita_misura: 'Unità di misura',
                famiglia_primaria: 'Famiglia primaria',
                famiglia_secondaria: 'Famiglia secondaria',
                prodotto: 'Prodotto',
            }
        },

        waste: {
            tabs: {
                total: 'Emissioni totali per business unit',
                totalDesc: 'Distribuzione delle emissioni indirette per business unit (t/co2e)',
                aggregated: 'Dettagli emissioni per business unit',
                waste: 'Lista Singole emissioni - Waste',
                wastewater: 'Lista Singole emissioni - Wastewater',
            },
            columns: {
                business_unit: 'Business Unit',
                anno: 'Anno',
                emissione_t_co2e: 'Emissioni (t/co2e)',
                kg_co2e: 'Fattore emissivo (kg/co2e)',
                kg_co2e_w1: 'Fattore emissivo (kg/co2e)\nIncenerimento con Recupero di Energia',
                kg_co2e_w2: 'Fattore emissivo (kg/co2e)\nIncenerimento senza Recupero di Energia',
                kg_co2e_w3: 'Fattore emissivo (kg/co2e)\nConferimento in Discarica',
                kg_co2e_w4: 'Fattore emissivo (kg/co2e)\nAltre Operazioni di Smaltimento',
                unita_misura: 'Unità di misura',
                tipologia: 'Tipologia',
                codice_waste: 'Codice waste',
                waste_category: 'Categoria waste',
                materiale: 'Materiale',
                disposal: 'Smaltimento',
            }
        },
    },
}
