import React, {useEffect, useState} from 'react'

//Data
import {CgAdd} from 'react-icons/cg'
import {FaPen, FaTrash} from 'react-icons/fa'
import {useStores} from '../../../../../../hooks/useStores'
import {elimina, get} from '../../../../../../rest/crud_generica'
import {IFattoreBusinessTravel} from '../../../../types'

//Components
import Dialogue from '../../../../../../components/common/Dialogue'
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'

import {observer} from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'
import {ICallToActionTabella} from '../../../../../../components/tabellaInterattiva/types'
import {
    useTabellaInterattivaContext
} from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'
import {t} from 'i18next'
import BusinessTravelForm from './BusinessTravelForm'


const BusinessTravel = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()

    const {ui} = useStores()

    const [businessTravel, setBusinessTravel] = useState<IFattoreBusinessTravel[]>([])

    useEffect(() => {
        listaBusinessTravel()
    }, [ui.aggiornamentoGenerico])

    const listaBusinessTravel = () => get('carbon_footprint/business_travel').then((res) => {
        setBusinessTravel(res.data.data)
    })

    //Crea fattore emissivo
    const creaBusinessTravel = () => ui.mountAsideModal(<BusinessTravelForm/>)

    //Modifica fattore emissivo
    const modificaBusinessTravel = (businesstravel: IFattoreBusinessTravel) => ui.mountAsideModal(<BusinessTravelForm businesstravel={businesstravel}/>)

    //Elimina fattore emissivo
    const eliminaBusinessTravel = (businesstravel: IFattoreBusinessTravel) => {
        elimina(`carbon_footprint/business_travel/${businesstravel.id}`)
            .then(() => {
                ui.mountDialogue(<Dialogue titolo="Business Travel eliminato con successo"/>)
                listaBusinessTravel()
            })
            .catch(() => {
                ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione del fattore emissivo"/>)
            })
    }

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const {tabella} = useTabellaInterattivaContext()
    useEffect(() => {
    }, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    azione: creaBusinessTravel,
                    testo: 'Crea fattore',
                    icona: <CgAdd size={18}/>
                }])
            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaBusinessTravel,
                    testo: 'Modifica',
                    icona: <FaPen size={12}/>,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaBusinessTravel,
                    testo: 'Elimina',
                    icona: <FaTrash size={12}/>,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }

    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={businessTravel}
                titolo="Fattori di Business Travel"
                paragrafo="Inserisci i fattori Business Travel."
                callToActions={ctaTabella}
                colonne={
                    [
                        {id: 'anno', label: 'Anno'},
                        {id: 'regione_geografica', label: 'Regione Geografica'},
                        {id: 'paese', label: 'Paese'},
                        {id: 'travel_mode', label: 'Modalità'},
                        {id: 'travel_type', label: 'Tipologia'},
                        {id: 'dimensione_veicolo', label: 'Dimensione'},
                        {id: 'unita_misura', label: t('common.unitaMisura')},
                        {id: 'kg_co2e', label: 'Kg co2e'},
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})


export default BusinessTravel;