import React from 'react'

//Data
import { IRigaTabellaInterattiva } from './types';
import { useTabellaInterattivaContext } from './context/useTabellaInterattivaContext';
import { observer } from 'mobx-react-lite';

//Components
import CallToActionRiga from './CallToActionRiga';
import CellaTabella from './CellaTabella';

const RigaTabella: React.FC<IRigaTabellaInterattiva> = observer(({ dati, rowId }) => {

  const { tabella } = useTabellaInterattivaContext()

  return (
    <tr className="rigaTabellaInterattiva">
      {Object.values(dati).map((valore: any, i: number) => {
        return (
            <CellaTabella
                dati={dati}
                colonna={Object.keys(dati)[i]}
                key={rowId as string + '_' + i.toString()}
                valoreDefault={valore as string}/>
        )
      })}

      {tabella.callToActionsRiga && tabella.callToActionsRiga.length > 0 &&
        <td className="cellaAzioniRiga">
          {
            tabella.callToActionsRiga?.map((cta) => (

               Object.keys(dati).includes('call_to_action_allowed')?
                dati['call_to_action_allowed'].includes(cta.id)?
                  <CallToActionRiga
                    icona={cta.icona}
                    testo={cta.testo}
                    id={cta.id}
                    azione={cta.azione}
                    dati={dati}
                    coloreSfondo={cta.coloreSfondo}
                    coloreTesto={cta.coloreTesto}
                  />
                  : null
              :

              <CallToActionRiga
                icona={cta.icona}
                testo={cta.testo}
                id={cta.id}
                azione={cta.azione}
                dati={dati}
                coloreSfondo={cta.coloreSfondo}
                coloreTesto={cta.coloreTesto}
              />



            ))
          }
        </td>
      }

    </tr>
  )
})

export default RigaTabella;
