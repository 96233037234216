import React from 'react'
import ReactEcharts from 'echarts-for-react'
import {IAnalisiCarbonFootprintAggregato} from '../../../types';

const GraficoBusinessUnit: React.FC<{ emissioniBusinessUnit: IAnalisiCarbonFootprintAggregato[] }> = ({emissioniBusinessUnit}) => {

    let listaAnni = emissioniBusinessUnit.map(e => e.anno)
    let listaEmissioniCombustibile = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_combustibile)
    let listaEmissioniGas = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_gas_refrigerante)
    let listaEmissioniVeicoli = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_veicoli)
    let listaEmissioniElettricita = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_elettricita)
    let listaEmissioniUpstream = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_upstream_transport)
    let listaEmissioniWaste = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_waste)
    let listaEmissioniWastewater = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_wastewater)
    let listaEmissioniOther = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_other_disposal)
    let listaEmissioniBusinessTravelHotel = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_business_travel_hotel)
    let listaEmissioniBusinessTravelMode = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_business_travel_mode)
    let listaEmissioniEmployeeCommuting = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_employee_commuting)
    let listaEmissioniUseProducts = emissioniBusinessUnit.map(e => e.emissione_cumulata_t_co2e_use_of_sold_products)

    let option = {
        title: {
            show: false,
            text: 'Title',
        },
        toolbox: {
            show: true,
            feature: {
                mark: {show: true},
                dataView: {show: true, readOnly: false},
                restore: {show: true},
                saveAsImage: {show: true}
            }
        },
        legend: {
            data: [
                'Combustibile',
                'Gas Refrigeranti',
                'Veicoli',
                'Elettricità',
                'Upstream Transport',
                'Waste',
                'Wastewater',
                'Other Disposal',
                'Business Travel by Hotel',
                'Business Travel by Mode',
                'Employee Commuting',
                'Use of Sold Products',
            ]
        },
        xAxis: {
            type: 'category',
            data: listaAnni
        },
        yAxis: {
            type: 'value',
            name: 't / co2e',
            nameTextStyle: {
                fontWeight: 'bold'
            }
        },
        series: [
            {
                type: 'bar',
                name: 'Combustibile',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniCombustibile,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Gas Refrigeranti',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniGas,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Veicoli',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniVeicoli,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Elettricità',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniElettricita,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Upstream Transport',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniUpstream,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Waste',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniWaste,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Wastewater',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniWastewater,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Other Disposal',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniOther,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Business Travel by Hotel',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniBusinessTravelHotel,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Business Travel by Mode',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniBusinessTravelMode,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Employee Commuting',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniEmployeeCommuting,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
            {
                type: 'bar',
                name: 'Use of Sold Products',
                colorBy: 'series',
                label: {
                    formatter: (param: any) => {
                        return param.value??''
                    },
                    show: true
                },
                data: listaEmissioniUseProducts,
                stack: 'total',
                emphasis: {
                    focus: 'series'
                },
            },
        ]
    };

    return (
        <div>
            <ReactEcharts style={{minHeight: 500}} option={option}/>
        </div>
    )
}


export default GraficoBusinessUnit;