import React, {useEffect, useState} from 'react'

//Data
import {useStores} from '../../../../../../hooks/useStores'
import {ITravelType} from '../../../../types'
import {elimina, get} from '../../../../../../rest/crud_generica'
import {observer} from 'mobx-react-lite'
import useOttieniPrivilegioModulo from '../../../../../../hooks/useOttieniPrivilegioModulo'

//Components
import TabellaInterattiva from '../../../../../../components/tabellaInterattiva/TabellaInterattiva'
import Dialogue from '../../../../../../components/common/Dialogue'
import {CgAdd} from 'react-icons/cg'
import {FaPen, FaTrash} from 'react-icons/fa'
import TravelTypeForm from './TravelTypeForm'
import {ICallToActionTabella} from '../../../../../../components/tabellaInterattiva/types'
import {useTabellaInterattivaContext} from '../../../../../../components/tabellaInterattiva/context/useTabellaInterattivaContext'

const TravelType = observer(() => {

    const privilegio = useOttieniPrivilegioModulo()
    const {ui} = useStores()

    const [travelType, setTravelType] = useState<ITravelType[]>([])

    useEffect(() => {
        listaTravelType()
    }, [ui.aggiornamentoGenerico])

    //Ottieni lista gas
    const listaTravelType = () => get('carbon_footprint/travel_type_business_travel').then((res) => {
        setTravelType(res.data.data)
    })

    //Crea gas
    const creaTravelType = () => ui.mountAsideModal(<TravelTypeForm/>)

    //Modifica gas
    const modificaTravelType = (traveltype: ITravelType) => ui.mountAsideModal(<TravelTypeForm traveltype={traveltype}/>)

    //Elimina gas
    const eliminaTravelType = (traveltype: ITravelType) => elimina(`carbon_footprint/travel_type_business_travel/${traveltype.nome}/${traveltype.travel_mode}`)
        .then(() => {
            ui.mountDialogue(<Dialogue titolo="Tipo di viaggio eliminato con successo"/>)
            listaTravelType()
        })
        .catch(() => {
            ui.mountDialogue(<Dialogue titolo="Errore durante l'eliminazione del tipo di viaggio"/>)
        })

    const [ctaRiga, setCtaRiga] = useState<ICallToActionTabella[]>([])
    const [ctaTabella, setCtaTabella] = useState<ICallToActionTabella[]>([])

    const {tabella} = useTabellaInterattivaContext()
    useEffect(() => {
    }, [tabella.callToActions, tabella.callToActionsRiga])

    //Setta CTA basate su ruoli
    useEffect(() => {

        if (privilegio) {
            setCtaRiga([])
            setCtaTabella([])

            if (privilegio >= 2) {
                setCtaTabella(cta => [...cta, {
                    azione: creaTravelType,
                    testo: 'Crea tipologia',
                    icona: <CgAdd size={18}/>
                }])
            }

            if (privilegio >= 3) {
                setCtaRiga(cta => [...cta, {
                    azione: modificaTravelType,
                    testo: 'Modifica',
                    icona: <FaPen size={12}/>,
                }])
            }

            if (privilegio >= 4) {
                setCtaRiga(cta => [...cta, {
                    azione: eliminaTravelType,
                    testo: 'Elimina',
                    icona: <FaTrash size={12}/>,
                    coloreSfondo: "bg-red dark:bg-dark-red"
                }])
            }
        }
    }, [privilegio])

    return (
        <div>
            <TabellaInterattiva
                dati={travelType}
                titolo="Tipologia modalità di viaggio"
                paragrafo="Inserisci le modalità di viaggio"
                endpoint="carbon_footprint/travel_type"
                pk={['nome']}
                callToActions={ctaTabella}
                colonne={
                    [
                        {id: 'nome', label: 'Nome'},
                        {id: 'descrizione', label: 'Descrizione'},
                        {id: 'travel_mode', label: 'Modo di Viaggio'},
                    ]
                }
                callToActionsRiga={ctaRiga}
            />
        </div>
    )
})

export default TravelType;