import React, {useEffect, useState} from 'react'
import {get} from '../../../../../rest/crud_generica'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'
import MenuGenerico from '../../../../../components/common/MenuGenerico'
import {useTranslation} from 'react-i18next';
import GraficoScope3Bar from "../../grafici/GraficoScope3Bar";
import GraficoScope3Radar from '../../grafici/GraficoScope3Radar';
import {IAnalisiScope3, IAnalisiScope3_tipologia} from '../../../types';

export default function AnalisiWasteWastewater() {

    const {t} = useTranslation()
    const [tabellaAttiva, setTabellaAttiva] = useState<'emissioni_bu_totali_flat' | 'emissioni_bu_flat' | 'emissioni_waste_flat' | 'emissioni_wastewater_flat'>('emissioni_bu_totali_flat')
    const [emissioniBusinessUnitTotaliFlat, setEmissioniBusinessUnitTotaliFlat] = useState(new Array<IAnalisiScope3>())
    const [emissioniBusinessUnitFlat, setEmissioniBusinessUnitFlat] = useState(new Array<IAnalisiScope3_tipologia>())
    const [emissioniWasteFlat, setEmissioniWasteFlat] = useState(new Array<IAnalisiScope3>())
    const [emissioniWastewaterFlat, setEmissioniWastewaterFlat] = useState(new Array<IAnalisiScope3>())
    const [caricamentoFinito, setCaricamentoFinito] = useState<boolean>(false)

    useEffect(() => {

        setEmissioniBusinessUnitTotaliFlat(new Array<IAnalisiScope3>())
        setEmissioniBusinessUnitFlat(new Array<IAnalisiScope3_tipologia>())
        setEmissioniWasteFlat(new Array<IAnalisiScope3>())
        setEmissioniWastewaterFlat(new Array<IAnalisiScope3>())
        setCaricamentoFinito(false)

        switch (tabellaAttiva) {

            case ('emissioni_bu_totali_flat'):
                get('carbon_footprint/analisi/scope3/waste/emissioni_business_unit_totali_flat')
                    .then((res) => {
                        setEmissioniBusinessUnitTotaliFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

            case ('emissioni_bu_flat'):
                get('carbon_footprint/analisi/scope3/waste/emissioni_business_unit_flat')
                    .then((res) => {
                        setEmissioniBusinessUnitFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

            case ('emissioni_waste_flat'):
                get('carbon_footprint/analisi/scope3/waste/emissioni_waste_flat')
                    .then((res) => {
                        setEmissioniWasteFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

            case ('emissioni_wastewater_flat'):
                get('carbon_footprint/analisi/scope3/waste/emissioni_wastewater_flat')
                    .then((res) => {
                        setEmissioniWastewaterFlat(res.data.data)
                        setCaricamentoFinito(true)
                    })
                break;

        }

    }, [tabellaAttiva])

    return (
        <div>
            <MenuGenerico bottoni={[
                {
                    label: t('ModuloCarbonFootprint.analisi.waste.tabs.total'),
                    action: () => setTabellaAttiva('emissioni_bu_totali_flat'),
                    attivo: tabellaAttiva === 'emissioni_bu_totali_flat'
                },
                {
                    label: t('ModuloCarbonFootprint.analisi.waste.tabs.aggregated'),
                    action: () => setTabellaAttiva('emissioni_bu_flat'),
                    attivo: tabellaAttiva === 'emissioni_bu_flat'
                },
                {
                    label: t('ModuloCarbonFootprint.analisi.waste.tabs.waste'),
                    action: () => setTabellaAttiva('emissioni_waste_flat'),
                    attivo: tabellaAttiva === 'emissioni_waste_flat'
                },
                {
                    label: t('ModuloCarbonFootprint.analisi.waste.tabs.wastewater'),
                    action: () => setTabellaAttiva('emissioni_wastewater_flat'),
                    attivo: tabellaAttiva === 'emissioni_wastewater_flat'
                },
            ]}/>

            {
                tabellaAttiva === 'emissioni_bu_totali_flat' &&
                <div className='bg-white dark:bg-darkgray-dd'>

                    <div className="py-6 text-center">
                        <h1 className='text-3xl font-bold'>{t('ModuloCarbonFootprint.analisi.waste.tabs.total')}</h1>
                        <p className="text-lg">{t('ModuloCarbonFootprint.analisi.waste.tabs.totalDesc')}</p>
                    </div>

                    {emissioniBusinessUnitTotaliFlat.length > 0 &&
                        <GraficoScope3Radar title={t('ModuloCarbonFootprint.analisi.waste.tabs.total')} emissioniFlat={emissioniBusinessUnitTotaliFlat} anno={0}/>}

                    <TabellaInterattiva
                        synergical={true}
                        titolo={t('ModuloCarbonFootprint.analisi.waste.tabs.total')}
                        dati={emissioniBusinessUnitTotaliFlat}
                        caricamentoFinito={caricamentoFinito}
                        colonne={
                            [
                                {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.waste.columns.business_unit')},
                                {id: 'anno', label: t('ModuloCarbonFootprint.analisi.waste.columns.anno')},
                                {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.waste.columns.emissione_t_co2e')},
                            ]
                        }
                    />
                </div>
            }

            {
                tabellaAttiva === 'emissioni_bu_flat' &&
                <div className='bg-white dark:bg-darkgray-dd'>

                    <div className="py-6 text-center">
                        <h1 className='text-3xl font-bold'>{t('ModuloCarbonFootprint.analisi.waste.tabs.aggregated')}</h1>
                        <p className="text-lg">{t('ModuloCarbonFootprint.analisi.waste.tabs.aggregatedDesc')}</p>
                    </div>

                    {emissioniBusinessUnitFlat.length > 0 && <GraficoScope3Bar emissioniFlat={emissioniBusinessUnitFlat} anno={0} />}

                    {emissioniBusinessUnitFlat.length > 0 &&
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <GraficoScope3Radar
                                title={t('ModuloCarbonFootprint.analisi.waste.tabs.aggregated') + " 'Waste'"}
                                emissioniFlat={emissioniBusinessUnitFlat.filter(item => item.tipologia === 'Waste')}
                                anno={0}
                                width={50}
                            />
                            <GraficoScope3Radar
                                title={t('ModuloCarbonFootprint.analisi.waste.tabs.aggregated') + " 'Wastewater'"}
                                emissioniFlat={emissioniBusinessUnitFlat.filter(item => item.tipologia === 'Wastewater')}
                                anno={0}
                                width={50}
                            />
                        </div>}

                    <TabellaInterattiva
                        synergical={true}
                        titolo={t('ModuloCarbonFootprint.analisi.waste.tabs.aggregated')}
                        dati={emissioniBusinessUnitFlat}
                        caricamentoFinito={caricamentoFinito}
                        colonne={
                            [
                                {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.waste.columns.business_unit')},
                                {id: 'anno', label: t('ModuloCarbonFootprint.analisi.waste.columns.anno')},
                                {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.waste.columns.emissione_t_co2e')},
                                {id: 'tipologia', label: t('ModuloCarbonFootprint.analisi.waste.columns.tipologia')},
                            ]
                        }
                    />
                </div>
            }

            {
                tabellaAttiva === 'emissioni_waste_flat' &&
                <TabellaInterattiva
                    synergical={true}
                    titolo={t('ModuloCarbonFootprint.analisi.waste.tabs.waste')}
                    dati={emissioniWasteFlat}
                    caricamentoFinito={caricamentoFinito}
                    colonne={
                        [
                            {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.waste.columns.business_unit')},
                            {id: 'anno', label: t('ModuloCarbonFootprint.analisi.waste.columns.anno')},
                            {id: 'incineration_with_energy', label: t('ModuloCarbonFootprint.analisi.waste.columns.kg_co2e_w1')},
                            {id: 'incineration_without_energy', label: t('ModuloCarbonFootprint.analisi.waste.columns.kg_co2e_w2')},
                            {id: 'delivery_to_landfill', label: t('ModuloCarbonFootprint.analisi.waste.columns.kg_co2e_w3')},
                            {id: 'other_disposal', label: t('ModuloCarbonFootprint.analisi.waste.columns.kg_co2e_w4')},
                            {id: 'unita_misura', label: t('ModuloCarbonFootprint.analisi.waste.columns.unita_misura')},
                            {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.waste.columns.emissione_t_co2e')},
                            {id: 'codice_waste', label: t('ModuloCarbonFootprint.analisi.waste.columns.codice_waste')},
                            {id: 'materiale', label: t('ModuloCarbonFootprint.analisi.waste.columns.materiale')},
                            {id: 'disposal', label: t('ModuloCarbonFootprint.analisi.waste.columns.disposal')},
                            {id: 'waste_category', label: t('ModuloCarbonFootprint.analisi.waste.columns.waste_category')},
                        ]
                    }
                />
            }

            {
                tabellaAttiva === 'emissioni_wastewater_flat' &&
                <TabellaInterattiva
                    synergical={true}
                    titolo={t('ModuloCarbonFootprint.analisi.waste.tabs.wastewater')}
                    dati={emissioniWastewaterFlat}
                    caricamentoFinito={caricamentoFinito}
                    colonne={
                        [
                            {id: 'business_unit', label: t('ModuloCarbonFootprint.analisi.waste.columns.business_unit')},
                            {id: 'anno', label: t('ModuloCarbonFootprint.analisi.waste.columns.anno')},
                            {id: 'kg_co2e', label: t('ModuloCarbonFootprint.analisi.waste.columns.kg_co2e')},
                            {id: 'emissione_t_co2e', label: t('ModuloCarbonFootprint.analisi.waste.columns.emissione_t_co2e')},
                            {id: 'waste_category', label: t('ModuloCarbonFootprint.analisi.waste.columns.waste_category')},
                        ]
                    }
                />
            }

        </div>
    )
}
