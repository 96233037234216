import React from 'react'

const GraficoBusinessUnitsRendicontabili: React.FC<{
    businessUnitsRendicontabili: any[]
}> = ({businessUnitsRendicontabili}) => {
    return (
        <div className="max-w-[calc(100vw-20px)] md:max-w-[calc(100vw-340px)]" style={{overflowX: 'auto', width: '96%', margin: '0 auto', backgroundColor: 'white'}}>
            <table className="table min-w-full max-w-full">
                <thead className="border-b border-grey font-bold">
                <tr>
                    <th key={1}>Business Unit</th>
                    <th key={2}>Impianti stazionari</th>
                    <th key={3}>Gas refrigeranti</th>
                    <th key={4}>Veicoli</th>
                    <th key={5}>Elettricità</th>
                    <th key={6}>Upstream Transport</th>
                    <th key={7}>Waste</th>
                    <th key={8}>Wastewater</th>
                    <th key={9}>Other Disposal</th>
                    <th key={10}>Business Travel Hotel</th>
                    <th key={11}>Business Travel Mode</th>
                    <th key={12}>Employee Commuting</th>
                    <th key={13}>Use of Sold Products</th>
                </tr>
                </thead>
                <tbody>
                {businessUnitsRendicontabili.map((row) => <RowBusinessUnitsRendicontabile row={row}/>)}
                </tbody>
            </table>
        </div>
    )
}

const RowBusinessUnitsRendicontabile: React.FC<{
    row: any
}> = ({row}) => {
    return (
        <tr className="border-b border-grey">
            <td><div className='p-1'>{row.business_unit}</div></td>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_combustibile) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_gas_refrigerante) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_veicoli) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_elettricita) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_upstream_transport) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_waste) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_wastewater) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_other_disposal) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_business_travel_hotel) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_business_travel_mode) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_employee_commuting) === 'number'}/>
            <TileBusinessUnitsRendicontabile attivo={typeof (row.emissione_totale_t_co2e_use_of_sold_products) === 'number'}/>
        </tr>
    )
}

const TileBusinessUnitsRendicontabile: React.FC<{ attivo?: boolean }> = ({attivo = false}) => {
    return (
        <td className={`${attivo ? 'bg-green' : 'bg-red'}`}>
            <div className={`border-r border-grey h-full ratio-square items-center justify-center flex`}>
                <p className="text-white mx-1">
                    {attivo ? 'Rendicontato' : 'Non rendicontato'}
                </p>
            </div>
        </td>
    )
}
export default GraficoBusinessUnitsRendicontabili;