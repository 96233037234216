import React, { useEffect, useState } from 'react'

//Data
import { observer } from 'mobx-react-lite'
import { useStores } from '../../hooks/useStores'
import { modifica } from '../../rest/crud_generica'
import { useTabellaInterattivaContext } from './context/useTabellaInterattivaContext'
import { IColonnaTabellaInterattiva } from './types'
import { t } from 'i18next'

//Components
import CellaTabellaLinkWrapper from './CellaTabellaLinkWrapper'

const CellaTabella: React.FC<{
  valoreDefault: string | number | Array<object>,
  colonna: string,
  dati: object
}> = observer(({
  valoreDefault,
  colonna,
  dati
}) => {

  const { tabella } = useTabellaInterattivaContext()
  const { ui } = useStores()
  const [stato, setStato] = useState<'visualizzazione' | 'modifica'>('visualizzazione')
  const [valore, setValore] = useState<string | number | Array<object>>(valoreDefault)
  const [tipologiaValore, setTipologiaValore] = useState<string>(typeof valoreDefault)
  const [tipologiaForm, setTipologiaForm] = useState<string>('text')
  const [datiColonna, setDatiColonna] = useState<IColonnaTabellaInterattiva | undefined>()

  useEffect(() => {

    tabella.colonne && colonna &&
      setDatiColonna(tabella.colonne.find(col => col.id === colonna))
  }, [])

  const styles = {
    input: 'bg-[rgba(0,0,0,0)] outline-none p-[10px] w-auto min-w-full'
  }

  //Setta la tipologia di form
  useEffect(() => {
 

    switch (tipologiaValore) {



      case 'string':
        if (typeof valoreDefault === 'string' && valoreDefault.length < 100) {
          setTipologiaForm('text')
        } else {
          setTipologiaForm('textarea')
        }
        break;

      case 'number':
        setTipologiaForm('number')
        break;

      case 'boolean':
        setTipologiaForm('boolean')
        break;
       
      case 'object':
        if (Array.isArray(valore)){
          setTipologiaForm('list')
        }
        
       
        break;  
    }
  }, [])

  const componiUri = () => {
    let uri = ''

    tabella.pk.forEach((pk) => {
      //@ts-ignore
      uri = uri + dati[pk] + '/'
    })

    return uri;
  }

  const modificaCampo = () => {

    modifica(`${tabella.endpoint}/modifica_campo/${componiUri()}${datiColonna?.id}/${valore}`, {}).then(() => {

      ui.mountToast({
        messaggio: 'Valore salvato con successo',
        colore: 'green'
      })

      ui.triggerAggiornamentoGenerico()


    }).catch((err) => {

      setValore(valoreDefault)

      ui.mountToast({
        messaggio: err.response.data.message ? err.response.data.message : 'Errore generico',
        colore: 'red'
      })

    }).finally(() => {
      setStato('visualizzazione')
    })
  }

  const handleInvio = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      modificaCampo()
    }
  }

  return (
    <th
      className={`${stato === 'modifica' ? 'formModificaCella' : 'p-[10px]'} ${datiColonna?.classi && datiColonna?.classi}`}
      onClick={() => tabella.modalitaModificaRapida && setStato('modifica')}
    >

      <CellaTabellaLinkWrapper link={datiColonna?.link} valore={valore}>
        {
          stato === 'visualizzazione' &&
          <>
            {tabella.stringaFiltro != "" && valore ?
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    valore.toString().replace(tabella.stringaFiltro, `<span class="bg-accent text-white">${tabella.stringaFiltro}</span>`)
                }}
              ></span>
              : valore}

            {tipologiaForm === 'boolean' &&
              <>
                {valore ? t('common.si') : t('common.no')}
              </>
            }

            {tipologiaForm === 'list' &&
            
              <>
                <span>CIAO CIAO</span>
              </>
            }
          </>
        }

        {
          stato === 'modifica' &&
          <>
            {
              tipologiaForm === 'textarea' ?
                <textarea
                  className={styles.input}
                  defaultValue={valore as string}
                  onChange={(e) => setValore(e.target.value)}
                  onBlur={() => modificaCampo()}
                  onKeyDown={(e) => handleInvio(e)}
                />
                :
                <input
                  className={styles.input}
                  type={tipologiaForm}
                  defaultValue={valore as string}
                  onChange={(e) => setValore(e.target.value)}
                  onBlur={() => modificaCampo()}
                  onKeyDown={(e) => handleInvio(e)}
                />
            }
          </>
        }
      </CellaTabellaLinkWrapper>

    </th>

  )
})

export default CellaTabella;
