import React from 'react'
import { useTranslation } from 'react-i18next'

//Data
import { ITemaMaterialitaAnno } from '../../types'

//Components
import GraficoMaterialitaBilancio from './GraficoMaterialitaBilancio'
import TemaMaterialitaBilancioForm from './TemaMaterialitaBilancioForm'
import { useStores } from '../../../../hooks/useStores'
import ListaTemiMaterialitaBilancio from './ListaTemiMaterialitaBilancio'
import { useModuloESGStores } from '../../context/useModuloESGStores'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

const BoxMaterialitaBilancio: React.FC<{ temiMaterialita: ITemaMaterialitaAnno[] }> = observer(({ temiMaterialita }) => {
    
    const { t } = useTranslation();
    const { bilanci } = useModuloESGStores()
    const { moduli, ui } = useStores()

    return (
        <div>
            {temiMaterialita && temiMaterialita.length > 0 ?
                <div className="bg-white dark:bg-darkgray-dd p-4 shadow-xl">
                    <div className="mb-4">
                        <h2 className="font-bold text-lg">{t('ModuloFattoriEsg.BoxMaterialitaBilanco.MatriceDiMaterialita')} </h2>
                        <p>{t('ModuloFattoriEsg.BoxMaterialitaBilanco.mattriceDellaMaterialita')}</p>
                    </div>


                    <GraficoMaterialitaBilancio temiMaterialita={temiMaterialita} />

                    <h2 className="my-4 text-xl font-bold">Lista dei temi di materialità applicati al bilancio:</h2>
                    <ListaTemiMaterialitaBilancio temiMaterialita={temiMaterialita} />

                </div>
                :
                <div className="border border-accent dark:border-dark-accent w-full p-4 flex flex-col items-cetner justify-center gap-1 text-center rounded-sm">
                    <p>{t('ModuloFattoriEsg.BoxMaterialitaBilanco.nessunTemiApplicato')}</p>

                    {
                        moduli.moduloAttivo?.codice === 'bilanci_materialita' ?
                            <span
                                className="cursor-pointer bg-accent dark:bg-dark-accent p-2 text-white rounded-sm"
                                onClick={() => ui.mountAsideModal(<TemaMaterialitaBilancioForm />)}>
                                {t('ModuloFattoriEsg.BoxMaterialitaBilanco.applicailPrimaTemo')}
                            </span>
                            :
                            <Link
                                className="cursor-pointer bg-accent dark:bg-dark-accent p-2 text-white rounded-sm"
                                to={`/app/modulo/bilanci_materialita/${bilanci.bilancioAttivo?.anno}/temi_materialita`}>
                                {t('ModuloFattoriEsg.BoxMaterialitaBilanco.vaiAlModulo')}
                            </Link>
                    }

                </div>
            }
        </div>
    )
})

export default BoxMaterialitaBilancio;
