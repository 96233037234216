import React from 'react'

//Data
import './stile/tabellaInterattiva.css'
import {observer} from 'mobx-react-lite';
import {useTabellaInterattivaContext} from './context/useTabellaInterattivaContext';

//Components
import RigaHeadTabella from './RigaHeadTabella';
import RigaHeadTabellaSynergical from './RigaHeadTabellaSynergical';
import RigaTabella from './RigaTabella';
import RigaTabellaSynergical from './RigaTabellaSynergical';
import {useTranslation} from 'react-i18next';

const CorpoTabella = observer(() => {

    const {tabella} = useTabellaInterattivaContext()
    const {t} = useTranslation()
    return (
        <div className="overflow-x-scroll mt-1">
            <table className="tabellaInterattiva min-w-full max-w-full">

                {tabella.dati && tabella.dati.length > 0 &&
                    <>
                        <thead className="bg-accent dark:bg-dark-accent text-white">
                        {
                            tabella.synergical
                                ? <RigaHeadTabellaSynergical riga={tabella.dati[0]} colonne={tabella.colonne}/>
                                : <RigaHeadTabella riga={tabella.dati[0]} colonne={tabella.colonne}/>
                        }
                        </thead>
                        <tbody>
                        {
                            tabella.datiVisibili.map((riga: any, i: number) => (
                                tabella.synergical
                                    ?
                                    <RigaTabellaSynergical
                                        dati={riga}
                                        rowId={Object.values(riga)[0] as string + '_' + (tabella.paginaAttiva * tabella.elementiPerPagina).toString() + '_' + i.toString()}
                                        key={Object.values(riga)[0] as string + '_' + (tabella.paginaAttiva * tabella.elementiPerPagina).toString() + '_' + i.toString()}
                                    />
                                    :
                                    <RigaTabella
                                        dati={riga}
                                        rowId={Object.values(riga)[0] as string + '_' + i.toString()}
                                        key={Object.values(riga)[0] as string + '_' + i.toString()}
                                    />
                            ))
                        }
                        </tbody>
                    </>
                }

            </table>

            {tabella.dati && tabella.dati.length === 0 &&
                <div className="opacity-70 text-lg text-center my-2">
                    {t('tabellaInterattiva.nessunDatoPresente')}
                </div>
            }
        </div>
    )
})

export default CorpoTabella;
