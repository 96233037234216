import React, { useState } from 'react'
import UpstreamTransport from './components/UpstreamTransport'
import DimensioniVeicoli from './components/DimensioniVeicoli'


const ConfigurazioneUpstreamTransport = () => {

    const [vistaAttiva, setVistaAttiva] = useState<'dimensioni' | 'tipologie' | 'costanti' | 'costanti_tipologie_carburanti'>('costanti')

    const styles = {
        bottone: 'bg-lightgray p-2 cursor-pointer',
        bottoneAttivo: 'bg-white border-t-2 border-accent'
    }
    return (
        <div>
            <div className="flex items-center justify-center rounded-md">

                <div
                    className={`${styles.bottone} ${vistaAttiva === 'costanti' && styles.bottoneAttivo}`}
                    onClick={() => setVistaAttiva('costanti')}>
                    Costanti Upstream Transport
                </div>

                <div
                    className={`${styles.bottone} ${vistaAttiva === 'dimensioni' && styles.bottoneAttivo}`}
                    onClick={() => setVistaAttiva('dimensioni')}>
                    Dimensioni veicoli
                </div>

            </div>

            {vistaAttiva === 'costanti' && <UpstreamTransport />}
            {vistaAttiva === 'dimensioni' && <DimensioniVeicoli />}

        </div>
    )
}

export default ConfigurazioneUpstreamTransport;
