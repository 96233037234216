import React, {useState} from 'react'

import BusinessTravel from './components/BusinessTravel'
import TravelType from './components/TravelType'
import TravelMode from './components/TravelMode'

const ConfigurazioneUpstreamTransport = () => {

    const [vistaAttiva, setVistaAttiva] = useState<'modalita_viaggio' | 'tipo_viaggio' | 'costanti'>('costanti')

    const styles = {
        bottone: 'bg-lightgray p-2 cursor-pointer',
        bottoneAttivo: 'bg-white border-t-2 border-accent'
    }

    return (
        <div>
            <div className="flex items-center justify-center rounded-md">

                <div
                    className={`${styles.bottone} ${vistaAttiva === 'costanti' && styles.bottoneAttivo}`}
                    onClick={() => setVistaAttiva('costanti')}>
                    Costanti Business Travel
                </div>

                <div
                    className={`${styles.bottone} ${vistaAttiva === 'modalita_viaggio' && styles.bottoneAttivo}`}
                    onClick={() => setVistaAttiva('modalita_viaggio')}>
                    Modalità di Viaggio
                </div>

                <div
                    className={`${styles.bottone} ${vistaAttiva === 'tipo_viaggio' && styles.bottoneAttivo}`}
                    onClick={() => setVistaAttiva('tipo_viaggio')}>
                    Tipologia di Viaggio
                </div>

            </div>

            {vistaAttiva === 'costanti' && <BusinessTravel/>}
            {vistaAttiva === 'modalita_viaggio' && <TravelMode/>}
            {vistaAttiva === 'tipo_viaggio' && <TravelType/>}

        </div>
    )
}

export default ConfigurazioneUpstreamTransport;