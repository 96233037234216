import React, {useEffect, useState} from 'react'
import {get} from '../../../../../rest/crud_generica'
import TabellaInterattiva from '../../../../../components/tabellaInterattiva/TabellaInterattiva'
import MenuGenerico from '../../../../../components/common/MenuGenerico'
import GraficoRadarElettricitaTotale from '../../grafici/graficiElettricita/GraficoRadarElettricitaTotale'

const AnalisiElettricita = () => {

    const [tabellaAttiva, setTabellaAttiva] = useState<'emissioni_bu_totali_flat' | 'emissioni_bu_flat' | 'emissioni_flat'>('emissioni_bu_totali_flat')
    const [emissioniBusinessUnitTotaliFlat, setEmissioniBusinessUnitTotaliFlat] = useState([])
    const [emissioniBusinessUnitFlat, setEmissioniBusinessUnitFlat] = useState([])
    const [emissioniFlat, setEmissioniFlat] = useState([])

    useEffect(() => {

        switch (tabellaAttiva) {

            case ('emissioni_bu_totali_flat'):
                get('carbon_footprint/analisi/scope2/elettricita/emissioni_business_unit_totali_flat').then((res) => setEmissioniBusinessUnitTotaliFlat(res.data.data))
                break;

            case ('emissioni_bu_flat'):
                get('carbon_footprint/analisi/scope2/elettricita/emissioni_business_unit_flat').then((res) => setEmissioniBusinessUnitFlat(res.data.data))
                break;

            case ('emissioni_flat'):
                get('carbon_footprint/analisi/scope2/elettricita/emissioni_flat').then((res) => setEmissioniFlat(res.data.data))
                break;

        }

    }, [tabellaAttiva])

    return (
        <div>

            <MenuGenerico bottoni={[
                {
                    label: 'Emissioni totali per business unit',
                    action: () => setTabellaAttiva('emissioni_bu_totali_flat'),
                    attivo: tabellaAttiva === 'emissioni_bu_totali_flat'
                },
                {
                    label: 'Dettagli emissioni per business unit',
                    action: () => setTabellaAttiva('emissioni_bu_flat'),
                    attivo: tabellaAttiva === 'emissioni_bu_flat'
                },
                {
                    label: 'Lista Singole emissioni',
                    action: () => setTabellaAttiva('emissioni_flat'),
                    attivo: tabellaAttiva === 'emissioni_flat'
                },
            ]}/>

            {
                tabellaAttiva === 'emissioni_bu_totali_flat' &&
                <div className='bg-white dark:bg-darkgray-dd'>

                    <div className="py-6 text-center">
                        <h1 className='text-3xl font-bold'>Emissioni totali per business unit</h1>
                        <p className="text-lg">Distribuzione delle emissioni dirette di elettricità per business unit
                            (in t / co2e)</p>
                    </div>

                    {emissioniBusinessUnitTotaliFlat.length > 0 &&
                        <GraficoRadarElettricitaTotale emissioniBusinessUnitTotaliFlat={emissioniBusinessUnitTotaliFlat} anno={2022}/>}

                    <TabellaInterattiva
                        titolo="Emissioni totali per business unit"
                        dati={emissioniBusinessUnitTotaliFlat}
                        colonne={
                            [
                                {id: 'business_unit', label: 'Business Unit'},
                                {id: 'anno', label: 'Anno'},
                                {id: 'emissione_totale_t_co2e_elettricita', label: 'Emissioni (t / co2e)'},
                            ]
                        }
                    />
                </div>
            }

            {
                tabellaAttiva === 'emissioni_bu_flat' &&
                <TabellaInterattiva
                    titolo="Dettagli emissioni per business unit"
                    dati={emissioniBusinessUnitFlat}
                    colonne={
                        [
                            {id: 'business_unit', label: 'Business Unit'},
                            {id: 'anno', label: 'Anno'},
                            {id: 'paese', label: 'Paese'},
                            {id: 'fattore_emissione', label: 'Fattore di emissione'},
                            {id: 'emissione_totale_t_co2e_elettricita', label: 'Emissioni (t / co2e)'},
                        ]
                    }
                />
            }

            {
                tabellaAttiva === 'emissioni_flat' &&
                <TabellaInterattiva
                    titolo="Lista Singole emissioni"
                    dati={emissioniFlat}
                    colonne={
                        [
                            {id: 'business_unit', label: 'Business Unit'},
                            {id: 'anno', label: 'Anno'},
                            {id: 'nome', label: 'Nome'},
                            {id: 'fattore_emissione', label: 'Fattore di emissione'},
                            {id: 'paese', label: 'Paese'},
                            {id: 'elettricita', label: 'Elettricità'},
                            {id: 't_co2e', label: 'Emissioni (t / co2e)'},
                        ]
                    }
                />
            }

        </div>
    )
}

export default AnalisiElettricita;