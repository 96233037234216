import React, {useEffect, useState} from 'react'
import {get} from '../../../../rest/crud_generica'
import GraficoStoricoRendicontazione from '../grafici/GraficoStoricoRendicontazione'

const AnalisiCarbonFootprintStoricoRendicontazione = () => {
    const [storicoRendicontazione, setStoricoRendicontazione] = useState([])

    useEffect(() => {
        get('carbon_footprint/analisi/storico_rendicontazione').then((res) => setStoricoRendicontazione(res.data.data))
    }, [])

    return (
        <div className='bg-white dark:bg-darkgray-dd'>
            <div className="py-6 text-center">
                <h1 className='text-3xl font-bold'>Andamento della rendicontazione</h1>
                <p className="text-lg">Storico dell'andamento della rendicontazione negli ultimi 3 mesi</p>
            </div>

            {storicoRendicontazione && storicoRendicontazione.length > 0 && <GraficoStoricoRendicontazione storicoRendicontazione={storicoRendicontazione}/>}
        </div>
    )
}

export default AnalisiCarbonFootprintStoricoRendicontazione;