import { observable, action, makeAutoObservable, runInAction } from 'mobx'
import { IFattoreESG } from '../types'
import {
    getStandardESG,
    creaFattoreESG,
    modificaFattoreESG,
    eliminaFattoreESG,
    getFattoreESG,
    collegaFattoreESGObiettivi2030,
    scollegaFattoreESGObiettivi2030
} from '../rest/fattori_esg'

export class FattoriESGStore {

    standardESG: IFattoreESG[] = []

    gettingFattoreESGAttivo: boolean = false
    fattoreESGAttivo: IFattoreESG | null = null

    creatingFattoreESG = false
    creatingFattoreESGError: string = ''
    fattoreESGCreato = false

    editingFattoreESG = false
    modificaFattoreESGError: string = ''
    fattoreESGModificato = false

    deletingFattoreESG: boolean = false
    eliminaFattoreESGError: string = ''
    fattoreESGEliminato: boolean = false

    collegandoFattoreESGObiettivi2030: boolean = false
    collegaFattoreESGObiettivi2030Error: string = ''
    fattoreESGObiettivi2030Collegati: boolean = false

    scollegandoFattoreESGObiettivi2030: boolean = false
    scollegaFattoreESGObiettivi2030Error: string = ''
    fattoreESGObiettivi2030Scollegati: boolean = false

    constructor() {
        makeAutoObservable(this, {
            standardESG: observable,

            gettingFattoreESGAttivo: observable,
            fattoreESGAttivo: observable,

            settaFattoreESGAttivo: action,

            creatingFattoreESG: observable,
            creatingFattoreESGError: observable,
            fattoreESGCreato: observable,

            creaFattoreESG: action,
            editingFattoreESG: observable,
            modificaFattoreESGError: observable,
            fattoreESGModificato: observable,
            modificaFattoreESG: action,

            deletingFattoreESG: observable,
            eliminaFattoreESGError: observable,
            fattoreESGEliminato: observable,
            eliminaFattoreESG: action,

            collegandoFattoreESGObiettivi2030: observable,
            collegaFattoreESGObiettivi2030Error: observable,
            fattoreESGObiettivi2030Collegati: observable,
            collegaFattoreESGObiettivi2030: action,

            scollegandoFattoreESGObiettivi2030: observable,
            scollegaFattoreESGObiettivi2030Error: observable,
            fattoreESGObiettivi2030Scollegati: observable,
            scollegaFattoreESGObiettivi2030: action,
        })
    }

    //setta un fattoreESG attivo

    settaFattoreESGAttivo = (fattoreESG: IFattoreESG | null) => {
        this.fattoreESGAttivo = fattoreESG
    }

    //lista fattorESG
    getStandardESG = () => {
        getStandardESG().then((res) => {
            runInAction(() => { this.standardESG = res.data.data })
        }).catch((err) => {
            console.log(err.data.data)
        })
    }

    flattenFattoreESG = (fattore: IFattoreESG) => {
        let sotto_fattori_flat: IFattoreESG[] = [fattore]

        fattore.sotto_fattori.forEach((sotto_fattore: IFattoreESG) => {
            if (sotto_fattore.sotto_fattori.length > 0) {
                sotto_fattori_flat.concat(this.flattenFattoreESG(sotto_fattore))
            } else {
                sotto_fattori_flat.push(sotto_fattore)
            }
        })

        //@ts-ignore
        runInAction(() => this.fattoreESGAttivo?.sotto_fattori_flat.push(...sotto_fattori_flat))

        return sotto_fattori_flat;
    }

    //singolo bilancio
    getFattoreESG = (codice: string) => {

        this.gettingFattoreESGAttivo = true

        getFattoreESG(codice).then((res) => {

            runInAction(() => {

                this.fattoreESGAttivo = res.data.data

                if (this.fattoreESGAttivo) {

                    this.fattoreESGAttivo.sotto_fattori_flat = []

                    this.fattoreESGAttivo.sotto_fattori.forEach((fattore: IFattoreESG) => {
                        this.flattenFattoreESG(fattore)
                    })
                }

            })

        }).catch((err) => {

            console.log(err)

        }).finally(() => {

            runInAction(() => this.gettingFattoreESGAttivo = false)
        })
    }

    //crea fattoreESG
    creaFattoreESG = async (fattoreESG: IFattoreESG) => {

        runInAction(() => this.creatingFattoreESG = true)

        await creaFattoreESG(fattoreESG).then(() => {

            runInAction(() => this.creatingFattoreESGError = '')

            this.getFattoreESG(this.fattoreESGAttivo?.codice as string)

        }).catch((err) => {

            runInAction(() => this.creatingFattoreESGError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.creatingFattoreESG = false)
            runInAction(() => this.fattoreESGCreato = !this.fattoreESGCreato)
        })
    }

    //modifica fattoreESG
    modificaFattoreESG = async (fattoreESG: IFattoreESG) => {

        runInAction(() => this.editingFattoreESG = true)

        await modificaFattoreESG(fattoreESG).then(() => {

            runInAction(() => this.modificaFattoreESGError = '')
            this.getFattoreESG(this.fattoreESGAttivo?.codice as string)

        }).catch((err) => {
            runInAction(() => this.modificaFattoreESGError = err.response.data.message ? err.response.data.message : 'Errore generico')

        }).finally(() => {
            runInAction(() => this.editingFattoreESG = false)
            runInAction(() => this.fattoreESGModificato = !this.fattoreESGModificato)
        })
    }

    //elimina fattoreESG
    eliminaFattoreESG = async (codice: string) => {

        runInAction(() => this.deletingFattoreESG = true)

        await eliminaFattoreESG(codice).then(() => {

            runInAction(() => this.eliminaFattoreESGError = '')

        }).catch((err) => {

            runInAction(() => this.eliminaFattoreESGError = err.response.data.message ? err.response.data.message : "Errore generico")

        }).finally(() => {
            runInAction(() => this.deletingFattoreESG = false)
            runInAction(() => this.fattoreESGEliminato = !this.fattoreESGEliminato)
        })
    }

    collegaFattoreESGObiettivi2030 = async (fattori: string[], obiettivi: number[]) => {
        runInAction(() => this.collegandoFattoreESGObiettivi2030 = true)

        await collegaFattoreESGObiettivi2030(fattori, obiettivi).then(() => {

            runInAction(() => this.collegaFattoreESGObiettivi2030Error = '')

        }).catch((err) => {

            runInAction(() => this.collegaFattoreESGObiettivi2030Error = err.response.data.message ? err.response.data.message : "Errore generico")

        }).finally(() => {
            runInAction(() => this.collegandoFattoreESGObiettivi2030 = false)
            runInAction(() => this.fattoreESGObiettivi2030Collegati = !this.fattoreESGObiettivi2030Collegati)
        })
    }

    scollegaFattoreESGObiettivi2030 = async (fattori: string[], obiettivi: number[]) => {

        runInAction(() => this.scollegandoFattoreESGObiettivi2030 = true)

        await scollegaFattoreESGObiettivi2030(fattori, obiettivi).then(() => {

            runInAction(() => this.scollegaFattoreESGObiettivi2030Error = '')

        }).catch((err) => {

            runInAction(() => this.scollegaFattoreESGObiettivi2030Error = err.response.data.message ? err.response.data.message : "Errore generico")

        }).finally(() => {
            runInAction(() => this.scollegandoFattoreESGObiettivi2030 = false)
            runInAction(() => this.fattoreESGObiettivi2030Scollegati = !this.fattoreESGObiettivi2030Scollegati)
        })
    }
}